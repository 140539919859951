import React, { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { ArtworkCategory, ArtworkSubject, ArtworkTechnique, ArtworkStyle } from '@urbaneez/shared';
import { GallerySelect } from '../../components/select/GallerySelect';
import { artworkCreate } from '../artwork.mutations';
import { artworkFetchAll } from '../artwork.queries';
import { ArtistSelect } from '../../components/select/ArtistSelect';
import { ArtworkCreate, ArtworkCreateVariables } from '../__generated__/ArtworkCreate';

export const CreateArtworkForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [seller, setSeller] = useState(false);
  const [mutate] = useMutation<ArtworkCreate, ArtworkCreateVariables>(artworkCreate);

  const [form] = Form.useForm();

  const pricePrefixSelector = (
    <Form.Item name="prefix" initialValue={'EUR'}>
      <Select style={{ width: 70 }}>
        <Select.Option value="EUR">EUR</Select.Option>
        <Select.Option value="CHF">CHF</Select.Option>
        <Select.Option value="USD">USD</Select.Option>
        <Select.Option value="GBP">GBP</Select.Option>
      </Select>
    </Form.Item>
  );

  const handleSubmit = (values: any) => {
    if (loading) {
      return;
    } else {
      setLoading(true);
    }

    form
      .validateFields()
      .then(async () => {
        const { prefix, height, width, depth, price, sellerId, ...data } = values;

        const input = {
          ...data,
          dimension: {
            height,
            width,
            depth,
          },
          price: {
            value: parseFloat(price),
            currency: prefix,
          },
        };

        if (seller) {
          input.sellerId = sellerId;
        }

        await mutate({
          variables: {
            input,
          },
          update: (cache, { data }) => {
            // @ts-ignore
            const { artworks } = cache.readQuery({ query: artworkFetchAll });

            if (!data) {
              return;
            }

            cache.writeQuery({
              query: artworkFetchAll,
              data: {
                artworks: artworks.concat(data.artworkCreate),
              },
            });
          },
        })
          .then(() => {
            navigate('/artworks');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Form.Item
        label="Artist"
        name="artistId"
        rules={[
          {
            required: true,
            message: 'Please choose an artist',
          },
        ]}>
        <ArtistSelect />
      </Form.Item>

      <Checkbox style={{ marginTop: '1.6rem' }} value={seller} onClick={() => setSeller(!seller)}>
        This artwork is sold by a gallery
      </Checkbox>

      {seller && (
        <Form.Item label="Gallery" name="sellerId">
          <GallerySelect />
        </Form.Item>
      )}

      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input a name',
          },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please input a description',
          },
        ]}>
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item
        label={'Year'}
        name="year"
        rules={[
          {
            required: true,
            message: 'Please input a year',
          },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Category"
        name="category"
        rules={[
          {
            required: true,
            message: 'Please select a category',
          },
        ]}>
        <Select>
          {Object.values(ArtworkCategory).map((value) => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Style"
        name="style"
        rules={[
          {
            required: true,
            message: 'Please select a style',
          },
        ]}>
        <Select>
          {Object.values(ArtworkStyle).map((value) => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Subject"
        name={'subject'}
        rules={[
          {
            required: true,
            message: 'Please select a subject',
          },
        ]}>
        <Select>
          {Object.values(ArtworkSubject).map((value) => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Technique"
        name={'technique'}
        rules={[
          {
            required: true,
            message: 'Please select a technique',
          },
        ]}>
        <Select mode="multiple">
          {Object.values(ArtworkTechnique).map((value) => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Orientation"
        name="orientation"
        rules={[
          {
            required: true,
            message: 'Please select an orientation',
          },
        ]}>
        <Select>
          <Select.Option value={'portrait'}>Portrait</Select.Option>
          <Select.Option value={'landscape'}>Landscape</Select.Option>
          <Select.Option value={'square'}>Square</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Price"
        name="price"
        rules={[
          {
            required: true,
            message: 'Please select define a price',
          },
        ]}>
        <Input addonBefore={pricePrefixSelector} />
      </Form.Item>

      <h3>Dimensions</h3>
      <Row gutter={32}>
        <Col span={6}>
          <Form.Item
            label="Height (cm)"
            name="height"
            rules={[
              {
                required: true,
                message: 'Please define an height',
              },
            ]}>
            <InputNumber min={0} precision={2} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="Width (cm)"
            name="width"
            rules={[
              {
                required: true,
                message: 'Please define an height',
              },
            ]}>
            <InputNumber min={0} precision={2} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="Depth (cm)"
            name="depth"
            rules={[
              {
                required: true,
                message: 'Please define an height',
              },
            ]}>
            <InputNumber min={0} precision={2} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="Weight (g)"
            name="weight"
            rules={[
              {
                required: true,
                message: 'Please define a weight',
              },
            ]}>
            <InputNumber min={0} precision={2} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Framed" name="framed" valuePropName="checked">
        <Checkbox>Is the artwork framed ?</Checkbox>
      </Form.Item>

      <Row justify={'end'}>
        <Button type={'primary'} loading={loading} htmlType="submit">
          Save
        </Button>
      </Row>
    </Form>
  );
};
