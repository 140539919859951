import gql from 'graphql-tag';

export const productFetchAll = gql`
  query ProductFetchAll {
    artworks {
        artworks {
            _id
            name
            status
        }
    }
  }
`;
