import { Button, Card, Divider, message, Modal, Table } from 'antd';
import {ColumnProps} from 'antd/lib/table';
import React, {useState} from 'react';
import {useMutation} from 'react-apollo';
import {useModal} from '../../components/hooks/useModal';
import {ArtistSelect} from '../../components/select/ArtistSelect';
import {SellerCreate, SellerCreateVariables} from '../../seller/__generated__/SellerCreate';
import {sellerCreate} from '../../seller/seller.mutations';
import {GalleryFetch, GalleryFetchVariables} from '../__generated__/GalleryFetch';
import {galleryFetch} from '../gallery.queries';
import { ArtworkFetchAll_artworks_artworks } from '../../artwork/__generated__/ArtworkFetchAll';
import { Link } from 'react-router-dom';
import { api } from '../../api/api';

interface Artist {
  _id: string;
  nickName: string;
}

interface Seller {
  artist?: Artist | null;
}

interface Props {
  galleryId: string;
  artists: Seller[];
}

export const GalleryArtists: React.FunctionComponent<Props> = ({ galleryId, artists }) => {
  const [mutate] = useMutation<SellerCreate, SellerCreateVariables>(sellerCreate);
  const {visible, closeModal, openModal} = useModal();
  const [loading, setLoading] = useState(false);
  const [artistId, setArtistId] = useState('');

  const handleDelete = async (artistId: string) => {
    try {
      await api.delete(`/galleries/${galleryId}/artists/${artistId}`);
      message.success('Artist removed from gallery');
    } catch (err: any) {
      message.error(err.message);
    }
  }

  const columns: ColumnProps<Seller>[] = [{
    key: 'nickName',
    dataIndex: 'artist',
    render: (artist: Artist) => (
      <span>{artist.nickName}</span>
    )
  }, {
    title: 'Action',
    key: 'action',
    render: (text: any, record: Seller) => (
      <span>
      <Link to={`/artists/detail/${record.artist?._id}`}>
        <a>Detail</a>
      </Link>

      <Divider type='vertical'/>

      <a onClick={() => handleDelete(record.artist?._id!)} color="magenta">Delete</a>
    </span>
    ),
  }];

  const handleSubmit = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    mutate({
      variables: {
        input: {
          galleryId,
          artistId
        },
      },
      update: (cache, { data }) => {
        const options = {
          query: galleryFetch,
          variables: {
            id: galleryId
          }
        };

        const cachedData = cache.readQuery<GalleryFetch, GalleryFetchVariables>(options);

        if (!cachedData || !data) {
          return;
        }

        const { gallery } = cachedData;

        cache.writeQuery<GalleryFetch, GalleryFetchVariables>({
          ...options,
          data: {
            gallery: {
              ...gallery,
              artists: gallery.artists.concat(data.sellerCreate)
            }
          }
        })
      }
    }).then(() => {
      closeModal();
      setArtistId('');
      message.success('Artist added to galleries');
    }).catch(() => {
      message.success('Could not add artist to gallery');
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Card title={'Artists'} style={{margin: '1.6rem'}} extra={<Button onClick={openModal}>Add artist</Button>}>
      <Table
        dataSource={artists}
        columns={columns}
      />

      <Modal visible={visible} onCancel={closeModal} onOk={handleSubmit} confirmLoading={loading}>
        <ArtistSelect
          value={artistId}
          onChange={setArtistId}
        />
      </Modal>
    </Card>
  );
};
