import React from 'react';
import { Layout, PageHeader } from 'antd';
import { ExchangeRatesCard } from './components/ExchangeRatesCard';

export const SettingsPage = () => {
  return (
    <Layout.Content>
      <PageHeader title={'Settings'} />

      <ExchangeRatesCard />
    </Layout.Content>
  );
};
