import antdEn from 'antd/lib/locale-provider/en_US';
import messages from '../locales/en_US.json';

export const enLang = {
  messages: {
    ...messages
  },
  antd: antdEn,
  locale: 'en',
};
