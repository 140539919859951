import React from 'react';
import { useParams } from 'react-router-dom';

import { ArticleDetail } from '../components/ArticleDetail';
import { useArticle } from '../hooks/useArticle';

export const ArticleDetailPage = () => {
  const params = useParams() as { id: string };
  const { article, loading } = useArticle(params.id);

  if (loading || !article) {
    return <div>Loading</div>;
  }

  return <ArticleDetail article={article} />;
};
