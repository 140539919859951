import {ColumnProps} from 'antd/lib/table';
import React from 'react';
import {Avatar, Badge, Button, Card, Table} from 'antd';
import {FormattedNumber} from 'react-intl';
import {useModal} from '../../components/hooks/useModal';
import {
  OrderFetchOneById_order,
  OrderFetchOneById_order_lineItems,
} from '../__generated__/OrderFetchOneById';
import {useOrder} from '../hooks/useOrder';
import {UploadCertificateModal} from './UploadCertificateModal';

interface ItemListCardProps {
  order: OrderFetchOneById_order
}

export const ItemListCard: React.FC<ItemListCardProps> = ({
  order,
}) => {
  const {visible, openModal, closeModal} = useModal();
  const { downloadCertificate } = useOrder();

  const handleDownload = async () => {
    try {
      await downloadCertificate(order._id);
    } catch (e) {
      // TODO handle sentry + indicator
    }
  };

  const renderButtons = () => {
    if(order.hasCertificates) {
      return <Button onClick={handleDownload}>Download certificates</Button>;
    } else {
      return <Button onClick={openModal}>Upload certificates</Button>;
    }
  };

  const columns: ColumnProps<OrderFetchOneById_order_lineItems>[] = [{
    key: 'avatar',
    dataIndex: 'product',
    render: ({miniature}) => (
      <Avatar src={miniature}/>
    )
  }, {
    title: 'Name',
    key: 'name',
    dataIndex: 'product',
    render: (product) => (
      <span>{product.name}</span>
    )
  }, {
    title: 'Quantity',
    key: 'quantity',
    dataIndex: 'quantity'
  }, {
    title: 'Price',
    key: 'price',
    dataIndex: 'product',
    render: (product) => (
      <FormattedNumber value={product.price.value} currency={product.price.currency} style={'currency'}/>
    )
  }, {
    title: 'Total',
    key: 'total',
    dataIndex: 'product',
    render: (product, record) => (
      <FormattedNumber
        value={product.price.value * record.quantity}
        currency={product.price.currency}
        style={'currency'}
      />
    )
  }];

  return (
    <Card
      title={<span><Badge color={'red'}/> Unfulfilled</span>}
      extra={[
        renderButtons(),
      ]}
    >
      <Table
        dataSource={order.lineItems}
        columns={columns}
        pagination={false}
      />

      {visible && (
        <UploadCertificateModal
          orderId={order._id}
          onClose={closeModal}
          onSuccess={closeModal}
        />
      )}
    </Card>
  )
};
