import React, {useState} from 'react';
import {Button, Card, Modal, Tabs} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {CardTitle} from '../../components/Card';
import { useArtwork } from '../hooks/use-artwork';

interface ArtistBioCardProps {
  artworkId: string;
  description: string;
  translations: {
    language: string,
    description: string,
  }[]
}

export const ArtworkDescription: React.FC<ArtistBioCardProps> = ({ artworkId, description, translations}) => {
  const [updateModal, setUpdateModal] = useState(false);
  const [currentBio, setCurrentBio] = useState('');
  const [currentLocale, setCurrentLocale] = useState('');

  const { updateTranslation } = useArtwork();

  const openModal = (lg: string) => {
    setUpdateModal(true);

    const translation = translations.find(el => el.language === lg);
    setCurrentBio(translation?.description || '');
    setCurrentLocale(lg);
  };

  const closeModal = () => {
    setUpdateModal(false);
    setCurrentBio('');
    setCurrentLocale('');
  };

  const handleUpdate = () => {
    updateTranslation(
      artworkId,
      currentLocale,
      currentBio
    )
      .then(() => {
        closeModal();
      });
  };

  return (
    <Card>
      <CardTitle>Description</CardTitle>
      <Tabs>
        <Tabs.TabPane tab={'English'} key={'en'}>
          <p dangerouslySetInnerHTML={{__html: description}}/>
        </Tabs.TabPane>

        <Tabs.TabPane tab={'French'} key={'fr'}>
          <p dangerouslySetInnerHTML={{__html: translations.find(el => el.language === 'fr')?.description || ''}}/>

          <Button onClick={() => openModal('fr')}>Update</Button>
        </Tabs.TabPane>
      </Tabs>

      <Modal visible={updateModal} onCancel={closeModal} title={"Update description"} onOk={handleUpdate}>
        <TextArea
          value={currentBio}
          onChange={({target}) => setCurrentBio(target.value)}
        />
      </Modal>
    </Card>
  );
};
