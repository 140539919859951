import antdFr from 'antd/lib/locale-provider/fr_FR';
import messages from '../locales/fr_FR.json';

export const frLang = {
  messages: {
    ...messages
  },
  antd: antdFr,
  locale: 'fr',
};
