import TextArea from 'antd/lib/input/TextArea';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Card, Col, Layout, Row, Select } from 'antd';
import { api } from '../../api/api';
import { CoverPicture } from '../../artist/components/CoverPicture';
import { CardTitle } from '../../components/cards/Card';
import { FormGroup, TextInput } from '../../components/form/inputs/TextInput';
import EditorJS from '@editorjs/editorjs';
import { PageFooter } from '../../components/layouts/page/PageFooter';
import { PageTitle } from '../../components/layouts/page/PageTitle';
import { Asset } from '../../models/asset';
import { Author } from '../../models/author';
import { ArticleAuthorCard } from '../components/ArticleAuthorCard';
import { ContentEditor } from '../components/ContentEditor';

export const ArticleCreatePage = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState<Author | null>(null);
  const [language, setLanguage] = useState<string>('en');
  const [cover, setCover] = useState<Asset | null>(null);
  const [summary, setSummary] = useState<string>('');
  const [meta, setMeta] = useState({ title: '', description: '' });
  const [tags, setTags] = useState<string[]>([]);
  const [editor, setEditor] = useState<EditorJS | null>(null);
  const editorRef = useRef(null);

  const handleAuthorChange = (author: Author) => {
    setAuthor(author);
  };

  const handleCoverUpdate = (asset: any) => {
    setCover(asset);
  };

  const handleLanguageChange = (value: string) => {
    setLanguage(value);
  };

  const handleTagChange = (value: string[]) => {
    setTags(value);
  };

  const handleSubmit = async () => {
    if (!editor || !author) {
      return;
    }

    const { time, version, ...content } = await editor.save();

    api
      .post('/articles', {
        title,
        content,
        summary,
        language,
        authorId: author._id,
        coverId: cover?._id || undefined,
        meta,
        tags,
      })
      .then(() => {
        navigate('/magazine/articles');
      });
  };

  return (
    <Layout.Content>
      <PageTitle title={'Create a new post'} />

      <CoverPicture src={cover?.src || ''} onSuccess={handleCoverUpdate} />

      <Row gutter={16}>
        <Col lg={16}>
          <Card style={{ marginBottom: '1.6rem' }}>
            <TextInput
              label="Title"
              value={title}
              onChange={({ target }) => setTitle(target.value)}
            />

            <FormGroup>
              <Label>Summary</Label>
              <TextArea
                value={summary}
                onChange={({ target }) => setSummary(target.value)}
                rows={5}
              />
            </FormGroup>

            <Label>Content</Label>
            <ContentEditor ref={editorRef} editor={editor} onLoad={setEditor} />
          </Card>

          <Card>
            <CardTitle>Meta</CardTitle>
            <p style={{ paddingTop: '1.6rem' }}>Override meta to optimize SEO.</p>

            <TextInput
              label="Title"
              value={meta.title}
              onChange={({ target }) => setMeta({ ...meta, title: target.value })}
            />

            <FormGroup>
              <Label>Description</Label>
              <TextArea
                value={meta.description}
                onChange={({ target }) => setMeta({ ...meta, description: target.value })}
                rows={5}
              />
            </FormGroup>
          </Card>
        </Col>

        <Col lg={8}>
          <ArticleAuthorCard onChange={handleAuthorChange} />

          <Card style={{ marginBottom: '1.6rem' }}>
            <CardTitle>Language</CardTitle>
            <p style={{ paddingTop: '1.6rem' }}>
              Language of this article. It wil be used when creating translated variants.
            </p>

            <Select style={{ width: '100%' }} onChange={handleLanguageChange} value={language}>
              <Select.Option value={'en'}>English</Select.Option>
              <Select.Option value={'fr'}>French</Select.Option>
            </Select>
          </Card>

          <Card style={{ marginBottom: '1.6rem' }}>
            <CardTitle>Tags</CardTitle>
            <p style={{ paddingTop: '1.6rem' }}>Optimize SEO with well defined tags.</p>

            <Select style={{ width: '100%' }} mode="tags" onChange={handleTagChange} value={tags}>
              {tags.map((tag) => (
                <Select.Option key={tag} value={tag}>
                  {tag}
                </Select.Option>
              ))}
            </Select>
          </Card>
        </Col>
      </Row>

      <PageFooter>
        <Action>
          <Button title={'Save'} type={'primary'} onClick={handleSubmit}>
            Save
          </Button>
        </Action>
      </PageFooter>
    </Layout.Content>
  );
};

const Label = styled.label`
  display: inline-block;
  font-weight: 500;
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
  color: #5b626b;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
