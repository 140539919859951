import {
  Button,
  Card,
  Col,
  Descriptions,
  Empty,
  Layout,
  message,
  PageHeader,
  Row,
  Spin,
} from 'antd';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';

import { ArtworkListCard } from '../../components/cards/ArtworkListCard';
import { VendorAddress } from '../../vendor/components/VendorAddress';
import { ArtistFetch, ArtistFetchVariables } from '../__generated__/ArtistFetch';
import { artistFetch } from '../artist.queries';
import { ArtistAvatar } from '../components/ArtistAvatar';
import { ArtistGalleries } from '../components/ArtistGalleries';
import { ArtistIndependentSwitch } from '../components/ArtistIndependentSwitch';
import { ArtistInformationTab } from '../components/ArtistInformationTab';
import { ArtistFeaturedSwitch } from '../components/ArtistFeaturedSwitch';
import { ArtistCover } from '../components/ArtistCover';
import { useModal } from '../../components/hooks/useModal';
import { ArtistPreferences } from '../components/ArtistPreferences';
import { ArtistScore } from '../components/ArtistScore';
import { ArtistSocialLinks } from '../components/ArtistSocialLinks';
import { CardTitle } from '../../components/Card';
import { CoverPicture } from '../components/CoverPicture';
import { useArtist } from '../hooks/use-artist';
import { ArtistUpdateForm } from '../components/ArtistUpdateForm';

export const ArtistDetailPage = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();

  const { visible, openModal, closeModal } = useModal();
  const { accept, reject, deactivate, activate } = useArtist();
  const { loading, error, data } = useQuery<ArtistFetch, ArtistFetchVariables>(artistFetch, {
    variables: {
      id,
    },
  });

  if (error || !data) return <Empty />;

  const { artist } = data;

  if (!artist) {
    return <Empty />;
  }

  const handleAccept = () => {
    accept(id)
      .then(() => {
        message.success('Artist has been accepted.');
      })
      .catch(() => {
        message.error('We encountered an error trying to accept this artist.');
      });
  };

  const handleReject = () => {
    reject(id)
      .then(() => {
        message.success('Artist has been rejected.');
      })
      .catch(() => {
        message.error('We encountered an error trying to reject this artist.');
      });
  };

  const handleActivate = () => {
    activate(id)
      .then(() => {
        return message.success('Artist has been activated.');
      })
      .catch(() => {
        message.error('We encountered an error trying to activate this artist.');
      });
  };

  const handleDeactivate = () => {
    deactivate(id)
      .then(() => {
        return message.success('Artist has been deactivated.');
      })
      .catch(() => {
        message.error('We encountered an error trying to deactivate this artist.');
      });
  };

  return loading ? (
    <Spin />
  ) : (
    <Layout.Content>
      <PageHeader
        onBack={() => navigate(-1)}
        title={artist.nickName}
        subTitle={`${artist.firstName} ${artist.lastName}`}
        extra={[
          artist.status === 'pending' ? (
            <Button type={'primary'} onClick={handleAccept}>
              accept
            </Button>
          ) : null,
          artist.status === 'pending' ? (
            <Button type={'default'} danger onClick={handleReject}>
              reject
            </Button>
          ) : null,
          artist.status === 'active' ? (
            <Button type={'default'} danger onClick={handleDeactivate}>
              DEACTIVATE
            </Button>
          ) : null,
          artist.status === 'inactive' ? (
            <Button
              type={'primary'}
              onClick={handleActivate}
              style={{
                backgroundColor: '#4BCA81',
                border: 'none',
                fontSize: '1.4rem',
              }}>
              ACTIVATE
            </Button>
          ) : null,
          <Button onClick={openModal}>update</Button>,
        ]}>
        <Descriptions>
          <Descriptions.Item>
            <ArtistAvatar artistId={artist._id} src={artist.profilePicture || ''} />
          </Descriptions.Item>

          <Descriptions.Item>
            <ArtistCover artistId={artist._id} src={artist.coverPicture || ''} />
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>

      <CoverPicture src={artist.coverPicture || ''} />

      <Row gutter={16}>
        <Col lg={16}>
          <ArtistInformationTab artist={artist} />
        </Col>

        <Col lg={8}>
          <Card style={{ marginBottom: '1.6rem' }}>
            <CardTitle>Featured</CardTitle>
            <p style={{ paddingTop: '1.6rem' }}>Set artist as featured on Urbaneez home page</p>

            <ToggleContainer>
              Featured
              <ArtistFeaturedSwitch featured={artist.featured} artistId={artist._id} />
            </ToggleContainer>
          </Card>

          <Card style={{ marginBottom: '1.6rem' }}>
            <CardTitle>Independent</CardTitle>
            <p style={{ paddingTop: '1.6rem' }}>Set artist as selling his own artworks</p>

            <ToggleContainer>
              Independent
              <ArtistIndependentSwitch isIndependent={artist.isIndependent} artistId={artist._id} />
            </ToggleContainer>
          </Card>

          <Card style={{ marginBottom: '1.6rem' }}>
            <CardTitle>Stripe</CardTitle>
            <p style={{ paddingTop: '1.6rem' }}>
              Vendor has to accept stripe conditions to start selling on the platform
            </p>

            <ToggleContainer>
              Conditions link
              <Button
                style={{ fontFamily: 'Quicksand' }}
                size={'small'}
                type={'primary'}
                onClick={() =>
                  navigator.clipboard.writeText(
                    `https://urbaneez.art/join/validation?vendor=${artist.vendorId}`,
                  )
                }>
                Copy Link
              </Button>
            </ToggleContainer>
          </Card>

          <ArtistScore artistId={artist._id} score={artist.score || 0} />

          <ArtistPreferences artist={artist} preferences={artist.preferences} />

          <Card title={'Contact'}>
            <Descriptions column={{ md: 1 }}>
              <Descriptions.Item label={'email'}>{artist.email}</Descriptions.Item>
              <Descriptions.Item label={'phone'}>
                {artist.phone ? `${artist.phone.prefix}/${artist.phone.number}` : '-'}
              </Descriptions.Item>
              <Descriptions.Item label={'uid'}>
                {artist.identifier
                  ? `${artist.identifier.type || '-'}/${artist.identifier.value || '-'}`
                  : '-'}
              </Descriptions.Item>
            </Descriptions>
          </Card>

          <VendorAddress
            vendorId={artist._id}
            address={
              artist.shippingAddress || {
                line1: '',
                city: '',
                country: '',
                postalCode: '',
                state: '',
              }
            }
          />

          <ArtistSocialLinks artist={artist} />

          <ArtistGalleries artistId={artist._id} galleries={artist.galleries} />
        </Col>
      </Row>

      <ArtworkListCard artworks={artist.artworks} />

      <ArtistUpdateForm artist={artist} onOk={closeModal} onClose={closeModal} visible={visible} />
    </Layout.Content>
  );
};

const ToggleContainer = styled.div`
  width: 100%;
  padding: 1.6rem;
  color: #1890ff;
  display: flex;
  flex-direction: row;
  font-family: 'Quicksand', 'sans-serif';
  font-weight: 700;
  font-size: 1.6rem;
  justify-content: space-between;
  background-color: rgba(24, 144, 255, 0.05);
  border-radius: 0.4rem;
`;
