import {Avatar, Button, Card, Form, List, message, Modal} from 'antd';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { useMutation } from 'react-apollo';
import {useModal} from '../../components/hooks/useModal';
import {GallerySelect} from '../../components/select/GallerySelect';
import {SellerCreate, SellerCreateVariables} from '../../seller/__generated__/SellerCreate';
import {sellerCreate} from '../../seller/seller.mutations';
import {ArtistFetch, ArtistFetchVariables} from '../__generated__/ArtistFetch';
import {artistFetch} from '../artist.queries';

interface Gallery {
  _id: string;
  name: string;
  logo: string | null;
  city: string | null;
  country: string | null;
}

interface Seller {
  _id: string;
  gallery: Gallery;
}

interface Props {
  artistId: string,
  galleries: Seller[];
}

export const ArtistGalleries: React.FunctionComponent<Props> = ({ artistId, galleries }) => {
  const {visible, openModal, closeModal} = useModal();
  const [galleryId, setGalleryId] = useState('');
  const [loading, setLoading] = useState(false);
  const [createMutation] = useMutation<SellerCreate, SellerCreateVariables>(sellerCreate);

  // const handleDelete = (sellerId: string) => {
  //   deleteMutation({
  //     variables: {
  //       input: {
  //         sellerId
  //       }
  //     },
  //     update: (cache, { data }) => {
  //       const options = {
  //         query: artistFetch,
  //         variables: {
  //           id: artistId,
  //         }
  //       };
  //
  //       const cachedData = cache.readQuery<ArtistFetch, ArtistFetchVariables>(options);
  //
  //       if (!cachedData) {
  //         return;
  //       }
  //
  //       cache.writeQuery<ArtistFetch, ArtistFetchVariables>({
  //         ...options,
  //         data: {
  //           artist: {
  //             ...cachedData.artist,
  //             galleries: cachedData.artist.galleries.filter(el => el._id !== sellerId)
  //           }
  //         }
  //       })
  //     }
  //   })
  //     .then(() => {
  //       message.success('Gallery removed from authorized sellers');
  //     })
  //     .catch(() => {
  //       message.error('Could not remove gallery from authorized sellers');
  //     })
  // };

  const handleSubmit = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    createMutation({
      variables: {
        input: {
          artistId,
          galleryId
        }
      },
      update: (cache, { data }) => {
        const options = {
          query: artistFetch,
          variables: {
            id: artistId,
          }
        };

        const cachedData = cache.readQuery<ArtistFetch, ArtistFetchVariables>(options);

        if (!cachedData || !data) {
          return;
        }

        cache.writeQuery<ArtistFetch, ArtistFetchVariables>({
          ...options,
          data: {
            artist: {
              ...cachedData.artist,
              galleries: cachedData.artist.galleries.concat(data.sellerCreate)
            }
          }
        })
      }
    })
      .then(() => {
        setGalleryId('');
        closeModal();
      })
      .catch(() => {
        message.error('Could not add gallery to artist authorized sellers');
      }).finally(() => {
        setLoading(false);
      })
  };

  return (
    <Card title={"Galleries"} style={{ marginTop: '1.6rem' }} extra={<Button onClick={openModal}>add</Button>}>
      <List
        dataSource={galleries}
        renderItem={({ _id, gallery}) => (
          <List.Item
            // actions={[<a key="list-loadmore-edit" onClick={() => handleDelete(_id)}>remove</a>]}
            title={gallery.name}
          >
            <List.Item.Meta
              avatar={<Avatar src={gallery.logo || ''}/>}
              title={<Link to={`/galleries/detail/${gallery._id}`}><a>{gallery.name}</a></Link>}
              description={`${gallery.city} - ${gallery.country}`}
            />
          </List.Item>
        )}
      />

      <Modal
        confirmLoading={loading}
        visible={visible}
        onCancel={closeModal}
        onOk={handleSubmit}
      >
        <Form>
          <Form.Item label={"Gallery"}>
            <GallerySelect onChange={setGalleryId} value={galleryId} />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};
