import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Layout, PageHeader } from 'antd';

import { GalleryCreateForm } from '../components/GalleryCreateForm';
import { useAppDispatch } from '../../app/hooks';
import { createGallery } from '../../features/gallery/store/gallery.slice';

export const GalleryCreatePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: any) => {
    const {
      line1,
      line2,
      postalCode,
      cityAddress,
      state,
      countryAddress,
      prefix,
      phone,
      identifier,
      identifierType,
      ...input
    } = values;

    const address = {
      line1,
      line2,
      postalCode,
      city: cityAddress,
      state,
      country: countryAddress,
    };

    try {
      const { payload: gallery } = await dispatch(
        createGallery({
          ...input,
          phone: {
            prefix,
            number: phone,
          },
          identifier: {
            type: identifierType,
            value: identifier,
          },
          address,
        }),
      );

      navigate(`/galleries/detail/${gallery._id}`);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Layout.Content>
      <PageHeader title={'Create gallery'} />

      <Card>
        <GalleryCreateForm handleSubmit={handleSubmit} />
      </Card>
    </Layout.Content>
  );
};
