import React, { FormEvent, useState } from 'react';
import { Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import { useMutation } from 'react-apollo';

import TextArea from 'antd/lib/input/TextArea';
import { ArtworkCategory, ArtworkStyle, ArtworkSubject, ArtworkTechnique } from '@urbaneez/shared';
import { artworkUpdate } from '../artwork.mutations';
import { ArtworkUpdate, ArtworkUpdateVariables } from '../__generated__/ArtworkUpdate';
import { Artwork } from '../models/artwork';

interface Props {
  artwork: Artwork;
  visible: boolean;
  onCancel: () => void;
  onOk: (artwork: any) => void;
}

export const ArtworkUpdateForm: React.FC<Props> = ({ artwork, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [mutate] = useMutation<ArtworkUpdate, ArtworkUpdateVariables>(artworkUpdate);

  const [form] = Form.useForm();

  const onOk = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    return form
      .validateFields()
      .then((values: any) => {
        const { height, width, depth, ...data } = values;

        return mutate({
          variables: {
            input: {
              artworkId: artwork._id,
              dimension: {
                height,
                width,
                depth,
              },
              ...data,
            },
          },
        });
      })
      .then((value) => {
        props.onOk(value.data?.artworkUpdate);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      confirmLoading={loading}
      title="Update artworks"
      visible={props.visible}
      onCancel={props.onCancel}
      onOk={onOk}>
      <Form form={form}>
        <Form.Item
          label={'Name'}
          name="name"
          initialValue={artwork.name}
          rules={[
            {
              required: true,
              message: 'Please input an artwork name',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'Description'}
          name="description"
          initialValue={artwork.description}
          rules={[
            {
              required: true,
              message: 'Please input a description',
            },
          ]}>
          <TextArea />
        </Form.Item>

        <Form.Item
          label={'Year'}
          name={'year'}
          initialValue={artwork.year}
          rules={[
            {
              required: true,
              message: 'Please input a year',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Category"
          name="category"
          initialValue={artwork.category}
          rules={[
            {
              required: true,
              message: 'Please select a category',
            },
          ]}>
          <Select>
            {Object.values(ArtworkCategory).map((value) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Style"
          name="style"
          initialValue={artwork.style}
          rules={[
            {
              required: true,
              message: 'Please select a style',
            },
          ]}>
          <Select>
            {Object.values(ArtworkStyle).map((value) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Subject"
          name={'subject'}
          initialValue={artwork.subject}
          rules={[
            {
              required: true,
              message: 'Please select a subject',
            },
          ]}>
          <Select>
            {Object.values(ArtworkSubject).map((value) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Technique"
          name="technique"
          initialValue={artwork.technique}
          rules={[
            {
              required: true,
              message: 'Please select a technique',
            },
          ]}>
          <Select mode="multiple">
            {Object.values(ArtworkTechnique).map((value) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Orientation"
          name="orientation"
          initialValue={artwork.orientation}
          rules={[
            {
              required: true,
              message: 'Please select an orientation',
            },
          ]}>
          <Select>
            <Select.Option value={'portrait'}>Portrait</Select.Option>
            <Select.Option value={'landscape'}>Landscape</Select.Option>
            <Select.Option value={'square'}>Square</Select.Option>
          </Select>
        </Form.Item>

        <h3>Dimensions</h3>
        <Row gutter={32}>
          <Col span={6}>
            <Form.Item
              label="Height (cm)"
              name="height"
              initialValue={artwork.dimension.height}
              rules={[
                {
                  required: true,
                  message: 'Please define an height',
                },
              ]}>
              <InputNumber min={0} precision={2} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Width (cm)"
              name="width"
              initialValue={artwork.dimension.width}
              rules={[
                {
                  required: true,
                  message: 'Please define an height',
                },
              ]}>
              <InputNumber min={0} precision={2} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Depth (cm)"
              name="depth"
              initialValue={artwork.dimension.depth}
              rules={[
                {
                  required: true,
                  message: 'Please define an height',
                },
              ]}>
              <InputNumber min={0} precision={2} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Weight (g)"
              name="weight"
              initialValue={artwork.weight}
              rules={[
                {
                  required: true,
                  message: 'Please define a weight',
                },
              ]}>
              <InputNumber min={0} precision={2} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Framed"
          name="framed"
          initialValue={artwork.framed}
          valuePropName="checked">
          <Checkbox>Is the artwork framed ?</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
