import { Card, Col, Row } from 'antd';
import React from 'react';
import { Page } from '../components/layouts/page/Page';
import { PageTitle } from '../components/layouts/page/PageTitle';

export const DashboardPage = () => (
  <Page>
    <PageTitle title="Welcome, Greg" />

    <Row gutter={16}>
      <Col sm={24} md={12} xl={6}>
        <Card title="Artworks"/>
      </Col>

      <Col sm={24} md={12} xl={6}>
        <Card title={"Galleries"}/>
      </Col>

      <Col sm={24} md={12} xl={6}>
        <Card title={"Orders"}/>
      </Col>

      <Col sm={24} md={12} xl={6}>
        <Card />
      </Col>
    </Row>
  </Page>
);
