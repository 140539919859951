import React, {ChangeEvent, useState} from 'react';
import {Button, Card, Form, Input, message} from 'antd';
import { useMutation } from 'react-apollo';
import {GalleryUpdate} from '../gallery/__generated__/GalleryUpdate';
import {galleryUpdate} from '../gallery/gallery.mutations';
import {GalleryFetch, GalleryFetch_gallery} from '../gallery/__generated__/GalleryFetch';
import {galleryFetch} from '../gallery/gallery.queries';

const INSTAGRAM = 'instagram';
const FACEBOOK = 'facebook';
const TWITTER = 'twitter';

interface Props {
  gallery: GalleryFetch_gallery;
}

export const SocialLinks: React.FunctionComponent<Props> = ({ gallery }) => {
  const [mutate] = useMutation<GalleryUpdate>(galleryUpdate);
  const [loading, setLoading] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [instagram, setInstagram] = useState(gallery.instagram || '');
  const [facebook, setFacebook] = useState(gallery.facebook || '');
  const [twitter, setTwitter] = useState(gallery.twitter || '');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setHasChange(true);

    switch (name) {
      case INSTAGRAM:
        return setInstagram(value);
      case FACEBOOK:
        return setFacebook(value);
      case TWITTER:
        return setTwitter(value);
    }
  };

  const handleSave = () => {
    setLoading(true);

    mutate({
      variables: {
        input: {
          galleryId: gallery._id,
          instagram,
          facebook,
          twitter,
        }
      },
      update: (cache) => {
        const data = cache.readQuery<GalleryFetch>({
          query: galleryFetch,
          variables: {
            id: gallery._id
          }
        });

        if (!data) {
          return;
        }

        const { gallery: cachedGallery } = data;

        cache.writeQuery({
          query: galleryFetch,
          variables: {
            id: gallery._id
          },
          data: {
            gallery: {
              ...cachedGallery,
              instagram,
              twitter,
              facebook
            }
          }
        });
      }
    }).then(() => {
      message.success('Gallery successfully updated!')
    }).catch(() => {
      message.success('Error while updating gallery!')
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Card title='Links' style={{ margin: '1.6rem' }} extra={hasChange && <Button onClick={handleSave} loading={loading}>save</Button>}>
      <Form.Item label="Instagram">
        <Input name={INSTAGRAM} value={instagram} onChange={onChange}/>
      </Form.Item>

      <Form.Item label="Facebook">
        <Input name={FACEBOOK} value={facebook} onChange={onChange}/>
      </Form.Item>

      <Form.Item label="Twitter">
        <Input name={TWITTER} value={twitter} onChange={onChange}/>
      </Form.Item>
    </Card>
  )
};