import React from 'react';
import { useMutation } from 'react-apollo';
import {GalleryFeaturedStatus, GalleryFeaturedStatusVariables} from '../__generated__/GalleryFeaturedStatus';
import {galleryFeaturedStatus} from '../gallery.mutations';
import {GalleryFetch} from '../__generated__/GalleryFetch';
import {galleryFetch} from '../gallery.queries';
import {FeaturedSwitch} from '../../components/FeaturedSwitch';

interface Props {
  galleryId: string;
  featured: boolean;
}

export const GalleryFeaturedSwitch: React.FunctionComponent<Props> = ({ galleryId, featured}) => {
  const [mutate] = useMutation<GalleryFeaturedStatus, GalleryFeaturedStatusVariables>(galleryFeaturedStatus);

  const handleChange = (status: boolean) => {
    return mutate({
      variables: {
        input: {
          galleryId: galleryId,
          status,
        },
      },
      update: (cache) => {
        const data = cache.readQuery<GalleryFetch>({
          query: galleryFetch,
          variables: {
            id: galleryId
          }
        });

        if (!data) {
          return;
        }

        const { gallery: cachedGallery } = data;

        cache.writeQuery({
          query: galleryFetch,
          variables: {
            id: galleryId
          },
          data: {
            gallery: {
              ...cachedGallery,
              featured: status,
            }
          }
        })
      },
    });
  };

  return (
    <FeaturedSwitch
      featured={featured}
      handleChange={handleChange}
    />
  )
};