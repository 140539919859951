import React from 'react';
import {Select} from 'antd';
import countries from '../../assets/data/countries.json';

interface Props {
  onChange?: (data: any) => void
  value?: string;
}

export const CountrySelect: React.FunctionComponent<Props> = (props) => (
  <Select
    showSearch
    optionFilterProp="children"
    {...props}
  >
    {countries.map(({ value, label}) => (
      <Select.Option
        key={value}
        value={value}
      >
        {label}
      </Select.Option>
    ))}
  </Select>
);
