import React from 'react';
import styled from 'styled-components';

interface Props {
  address: {
    line1: string | null;
    line2?: string | null;
    postalCode: string | null;
    city: string | null;
    state?: string | null;
    country: string | null;
  };
}

export const Address: React.FunctionComponent<Props> = ({ address }) => (
  <AddressLines>
    {address.line1}
    <br />
    {address.line2} {address.line2 && <br />}
    {address.postalCode} {address.city}
    <br />
    {address.state} {address.state && address.country && ', '} {address.country}
  </AddressLines>
);

const AddressLines = styled.div`
  font-family: Quicksand, 'sans-serif';
  color: #3e475e;
  font-size: 1.6rem;
  font-weight: 500;
`;
