import React, { useState } from 'react';
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { CitySelect } from '../../components/select/CitySelect';
import { CountrySelect } from '../../components/select/CountrySelect';
import { useNavigate } from 'react-router-dom';

interface Props {
  handleSubmit: (values: any) => void;
}

export const ArtistCreateForm: React.FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [countryOrigin, setCountry] = useState('');

  const [form] = Form.useForm();

  const prefixSelector = (
    <Form.Item name="prefix" initialValue={'41'}>
      <Select style={{ width: 70 }}>
        <Select.Option value="1">+1</Select.Option>
        <Select.Option value="31">+31</Select.Option>
        <Select.Option value="32">+32</Select.Option>
        <Select.Option value="33">+33</Select.Option>
        <Select.Option value="34">+34</Select.Option>
        <Select.Option value="39">+39</Select.Option>
        <Select.Option value="41">+41</Select.Option>
        <Select.Option value="43">+43</Select.Option>
        <Select.Option value="44">+44</Select.Option>
        <Select.Option value="49">+49</Select.Option>
      </Select>
    </Form.Item>
  );

  const identifierTypeSelector = (
    <Form.Item name="identifierType" initialValue={'ide'}>
      <Select style={{ minWidth: 70 }}>
        <Select.Option value="ide">IDE</Select.Option>
        <Select.Option value="nif">NIF</Select.Option>
        <Select.Option value="siret">SIRET</Select.Option>
      </Select>
    </Form.Item>
  );

  const handleSubmit = (values: any) => {
    if (loading) {
      return;
    }

    setLoading(true);

    form
      .validateFields()
      .then(async () => {
        const { origin, line1, line2, postalCode, city, state, country, ...data } = values;

        try {
          await props.handleSubmit({
            shippingAddress: {
              line1,
              line2,
              postalCode,
              city,
              state,
              country,
            },
            city: origin,
            country: countryOrigin,
            ...data,
          });
        } catch (e) {
          console.log(e);
        }

        setLoading(false);
      })
      .then(() => {
        navigate('/artists');
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Row gutter={32}>
        <Col span={12}>
          <h3>Information</h3>
          <Form.Item name="isIndependent">
            <Checkbox children={'Is an independent artist ?'} />
          </Form.Item>

          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Please input a first name',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Please input a last name',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Nick Name"
            name="nickName"
            rules={[
              {
                required: true,
                message: 'Please input a nick name',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Gender"
            name="gender"
            rules={[
              {
                required: true,
              },
            ]}>
            <Select>
              <Select.Option value="male">Man</Select.Option>
              <Select.Option value="female">Woman</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Bio"
            name="bio"
            rules={[
              {
                required: true,
                message: 'Please input a bio',
              },
            ]}>
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input an email',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              {
                required: true,
                message: 'Please input a phone number',
              },
            ]}>
            <Input addonBefore={prefixSelector} />
          </Form.Item>

          <Form.Item label="Unique identifier (TVA)" name="identifier">
            <Input addonBefore={identifierTypeSelector} />
          </Form.Item>

          <Form.Item
            label="Date of Birth"
            name="dateOfBirth"
            rules={[
              {
                required: true,
                message: 'Please input a date of birth',
              },
            ]}>
            <DatePicker />
          </Form.Item>

          <Form.Item label="Country">
            <CountrySelect onChange={setCountry} />
          </Form.Item>

          <Form.Item label="City" name="origin">
            <CitySelect country={countryOrigin} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <h3>Shipping Address</h3>

          <Form.Item
            label="Line 1"
            name="line1"
            rules={[
              {
                required: true,
                message: 'Please input an address',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item label="Line 2" name="line2">
            <Input />
          </Form.Item>

          <Form.Item
            label="Postal Code"
            name="postalCode"
            rules={[
              {
                required: true,
                message: 'Please input a postal code',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
                message: 'Please input a city',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item label="State" name="state">
            <Input />
          </Form.Item>

          <Form.Item
            label="Country"
            name="country"
            rules={[
              {
                required: true,
                message: 'Please input a country',
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row justify={'end'}>
        <Button type={'primary'} htmlType="submit" loading={loading}>
          Save
        </Button>
      </Row>
    </Form>
  );
};
