import {Modal, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import {api} from '../../api/api';
import {Article} from '../../models/article';

interface Option {
  value: string;
  label: string;
}

const parseOption = (article: Article): Option => ({
  value: article._id,
  label: article.title,
});

interface ArticleSearchDialogProps {
  onCancel: () => void;
  onSubmit: (article: Article) => Promise<void>;
}

export const ArticleSearchDialog: React.FC<ArticleSearchDialogProps> = ({
  onCancel,
  onSubmit,
}) => {
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState<Article | null>(null);
  const [options, setOptions] = useState([]);
  const [articles, setArticles] = useState<Article[]>([]);

  useEffect(() => {
    api
      .get('/articles')
      .then(({ data }) => {
        setOptions(data.map(parseOption));
        setArticles(data);
        setReady(true);
      });
  }, []);

  const handleChange = (option: string) => {
    setArticle(articles.find(el => el._id === option) || null);
  };

  const handleSubmit = async () => {
    if (loading || !article) {
      return;
    }

    setLoading(true);

    if (onSubmit) {
      await onSubmit(article);
      setLoading(false)
    }
  };

  return(
    <Modal title={'Search article'} visible onOk={handleSubmit} onCancel={onCancel} confirmLoading={loading}>
      <p style={{ paddingTop: '1.6rem' }}>Search an article to list it as a variant.</p>

      <Select style={{ width: '100%' }} loading={!ready} onChange={handleChange}>
        {options.map(({ value, label}) => (
          <Select.Option key={value} value={value}>{label}</Select.Option>
        ))}
      </Select>
    </Modal>
  )
};
