import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Row } from 'antd';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useAppDispatch } from '../../app/hooks';
import { createCustomer } from '../../features/customer/store/customer.slice';

export const CreateCustomerForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleSubmit, register } = useForm();
  const [loading, setLoading] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  const handleFormSubmit = async (values: any) => {
    if (loading) {
      return;
    }

    setLoading(true);

    await dispatch(createCustomer(values));

    navigate('/customers');
  };

  return (
    <form {...formItemLayout} onSubmit={handleSubmit(handleFormSubmit)}>
      <Form.Item label="First Name">
        <Input {...register('firstName', { required: true })} />
      </Form.Item>

      <Form.Item label="Last Name">
        <Input {...register('lastName', { required: true })} />
      </Form.Item>

      <Form.Item label="Email">
        <Input {...register('email', { required: true })} />
      </Form.Item>

      <Form.Item label="Phone">
        <Input {...register('phone')} />
      </Form.Item>

      <Row justify={'end'}>
        <Button type={'primary'} loading={loading} htmlType="submit">
          Save
        </Button>
      </Row>
    </form>
  );
};

const Input = styled.input`
  padding: 0.8rem;
  font-size: 1.6rem;
  height: 4rem;
`;
