import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 4rem;
`;

export const PageFooter: React.FunctionComponent = (props) => (
  <Container>{props.children}</Container>
);
