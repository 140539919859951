import React from 'react';
import { CreateArtworkForm } from '../components/CreateArtworkForm';
import { Card, Layout, PageHeader } from 'antd';

export const ArtworkCreatePage = () => (
  <Layout.Content>
    <PageHeader title={'New Artwork'} />

    <Card style={{ margin: '1.6rem' }}>
      <CreateArtworkForm />
    </Card>
  </Layout.Content>
);
