import {useMutation} from '@apollo/react-hooks';
import {Card, Select} from 'antd';
import React from 'react';
import styled from 'styled-components';
import {CardTitle} from '../../components/cards/Card';
import {
  GalleryPreferenceUpdate,
  GalleryPreferenceUpdateVariables,
} from '../../gallery/__generated__/GalleryPreferenceUpdate';
import {galleryPreferenceUpdate} from '../../gallery/gallery.mutations';

interface VendorPreferencesProp {
  galleryId: string;
  preferences?: {
    language: string | null;
    currency: string | null;
  } | null;
}

export const VendorPreferences: React.FC<VendorPreferencesProp> = ({
  galleryId,
  preferences = {
    language: 'en',
    currency: 'chf',
  },
}) => {
  const [mutate] = useMutation<GalleryPreferenceUpdate, GalleryPreferenceUpdateVariables>(galleryPreferenceUpdate);

  const onUpdateLanguage = async (value: string) => {
    await mutate({
      variables: {
        input: {
          galleryId,
          language: value,
        }
      }
    });
  };

  const onUpdateCurrency = async (value: string) => {
    await mutate({
      variables: {
        input: {
          galleryId,
          currency: value,
        }
      }
    });
  };

  return (
    <Card style={{marginTop: '1.6rem'}}>
      <CardTitle>Preferences</CardTitle>

      <p style={{ paddingTop: '1.6rem' }}>
        Which language should be used in communication with this vendor.
      </p>

      <ToggleContainer>
        Language
        <Select
          defaultValue={preferences?.language || 'en'}
          onChange={onUpdateLanguage}
        >
          <Select.Option value={'en'}>English</Select.Option>
          <Select.Option value={'fr'}>French</Select.Option>
        </Select>
      </ToggleContainer>

      <p style={{ paddingTop: '1.6rem' }}>
        Which currency should be used by default for this vendor.
      </p>

      <ToggleContainer>
        Currency
        <Select
          defaultValue={preferences?.currency || 'chf'}
          onChange={onUpdateCurrency}
        >
          <Select.Option value={'chf'}>CHF</Select.Option>
          <Select.Option value={'eur'}>EUR</Select.Option>
          <Select.Option value={'gbp'}>GBP</Select.Option>
          <Select.Option value={'usd'}>USD</Select.Option>
        </Select>
      </ToggleContainer>
    </Card>
  );
};

// TODO Refactor
const ToggleContainer = styled.div`
width: 100%;
padding: 1.6rem;
color: #1890ff;
display: flex;
flex-direction: row;
font-family: "Quicksand", 'sans-serif';
font-weight: 700;
font-size: 1.6rem;
justify-content: space-between;
background-color: rgba(24, 144, 255, .05);
border-radius: .4rem;
align-items: center;
`;
