import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ArticleCreatePage } from './pages/ArticleCreatePage';
import { ArticleDetailPage } from './pages/ArticleDetailPage';
import { ArticleListPage } from './pages/ArticleListPage';

export const MagazineModule = () => (
  <Routes>
    <Route path={`/articles/create`} element={<ArticleCreatePage />} />
    <Route path={`/articles/:id`} element={<ArticleDetailPage />} />
    <Route path={`/articles`} element={<ArticleListPage />} />
    <Route path={`/`} element={<ArticleListPage />} />
  </Routes>
);
