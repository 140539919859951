import {Avatar, message} from 'antd';
import React, { useRef } from 'react';
import { useMutation } from 'react-apollo';
import styled from 'styled-components';
import axios from 'axios';
import { galleryProfilePictureUpdate } from '../gallery.mutations';

const FileInput = styled.input`
display: none;
`;

interface Props {
  galleryId: string;
  src: string;
}

export const GalleryAvatar: React.FunctionComponent<Props> = props => {
  const [mutate] = useMutation(galleryProfilePictureUpdate);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (!(fileInputRef && fileInputRef.current)) {
      return;
    }

    fileInputRef.current.click();
  };

  const onFileSelected = async () => {
    if (!(fileInputRef && fileInputRef.current && fileInputRef.current.files)) {
      return;
    }

    const { galleryId } = props;
    const file = fileInputRef.current.files[0];

    const { data } = await mutate({
      variables: {
        input: {
          galleryId,
          type: file.type
        }
      }
    });

    const { url } = data.galleryProfilePictureUpdate;

    // now do a PUT request to the pre-signed URL
    axios
      .put(url, file)
      .then(() => {
        message.success('Gallery successfully deleted.');
      }).catch(() => {
      message.error('Unable to delete gallery.');
    });
  };

  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      <Avatar size={128} icon="user" src={props.src} shape="square"/>

      <FileInput
        ref={fileInputRef}
        type="file"
        onChange={onFileSelected}
      />
    </div>
  );
};
