import React, { useEffect } from 'react';
import { Button, Layout, PageHeader, Table } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Customer } from '../../features/customer/models/customer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchCustomers, selectAllCustomers } from '../../features/customer/store/customer.slice';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text: any, record: Customer) => (
      <span>
        {record.firstName} {record.lastName}
      </span>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
];

const rowSelection = {
  onChange: (selectedRowKeys: any, selectedRows: any) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record: any) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
};

export const CustomerListPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const customers = useAppSelector(selectAllCustomers);

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  return (
    <Layout.Content>
      <PageHeader
        title="Customers"
        extra={
          <Button
            onClick={() => navigate('/customers/create')}
            title={'new customer'}
            type={'primary'}>
            new customer
          </Button>
        }
      />

      <Table
        columns={columns}
        dataSource={customers}
        rowSelection={rowSelection}
        /* onRowClick={(record) => props.history.push(`/customers/detail/${record._id}`)} */
      />
    </Layout.Content>
  );
};
