import styled from 'styled-components';

export const PriceLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.8rem 0;
`;

export const PriceLineTotal = styled(PriceLine)`
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  padding: 0.8rem 0;
  font-weight: 600;
`;
