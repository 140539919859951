import { DataProxy } from 'apollo-cache';
import { useMutation} from 'react-apollo';
import {GalleryAccept, GalleryAcceptVariables} from '../__generated__/GalleryAccept';
import {GalleryFetch, GalleryFetchVariables} from '../__generated__/GalleryFetch';
import {GalleryReject, GalleryRejectVariables} from '../__generated__/GalleryReject';
import {GalleryUpdate, GalleryUpdateVariables} from '../__generated__/GalleryUpdate';
import {
  galleryAccept,
  galleryActivate,
  galleryDeactivate,
  galleryReject,
  galleryTranslationUpdate, galleryUpdate,
} from '../gallery.mutations';
import {galleryFetch} from '../gallery.queries';
import {GalleryDeactivate, GalleryDeactivateVariables} from '../__generated__/GalleryDeactivate';
import {GalleryActivate, GalleryActivateVariables} from '../__generated__/GalleryActivate';
import {GalleryTranslationUpdate, GalleryTranslationUpdateVariables} from '../__generated__/GalleryTranslationUpdate';

export const useGallery = () => {
  const [updateMutation] = useMutation<GalleryUpdate, GalleryUpdateVariables>(galleryUpdate);
  const [acceptMutation] = useMutation<GalleryAccept, GalleryAcceptVariables>(galleryAccept);
  const [rejectMutation] = useMutation<GalleryReject, GalleryRejectVariables>(galleryReject);
  const [deactivateMutation] = useMutation<GalleryDeactivate, GalleryDeactivateVariables>(galleryDeactivate);
  const [activateMutation] = useMutation<GalleryActivate, GalleryActivateVariables>(galleryActivate);
  const [translationMutation] = useMutation<GalleryTranslationUpdate, GalleryTranslationUpdateVariables>(galleryTranslationUpdate);

  const updateCache = (cache: DataProxy, galleryId: string, status: string ) => {
    const options = {
      query: galleryFetch,
      variables: {
        id: galleryId,
      }
    };

    const cacheData = cache.readQuery<GalleryFetch, GalleryFetchVariables>(options);

    if (!cacheData) return;

    cache.writeQuery<GalleryFetch, GalleryFetchVariables>({
      ...options,
      data: {
        gallery: {
          ...cacheData.gallery,
          status,
        }
      }
    });
  };

  const update = (galleryId: string, input: any) => {
    return updateMutation({
      variables: {
        input: {
          galleryId,
          score: input.score,
        }
      }
    })
  };

  const accept = (galleryId: string) => {
    return acceptMutation({
      variables: {
        input: {
          vendorId: galleryId,
        }
      },
      update: (cache: DataProxy, { data }) => {
        if (!data) return;

        updateCache(
          cache,
          galleryId,
          data.galleryAccept.status
        );
      }
    })
  };

  const reject = (galleryId: string) => {
    return rejectMutation({
      variables: {
        input: {
          vendorId: galleryId
        }
      },
      update: (cache: DataProxy, { data }) => {
        if (!data) return;

        updateCache(
          cache,
          galleryId,
          data.galleryReject.status
        );
      }
    })
  };

  const deactivate = (galleryId: string) => {
    return deactivateMutation({
      variables: {
        input: {
          galleryId,
        }
      },
      update: (cache: DataProxy, { data }) => {
        if (!data) return;

        updateCache(
          cache,
          galleryId,
          data.galleryDeactivate.status,
        );
      }});
  };

  const activate = (galleryId: string) => {
    return activateMutation({
      variables: {
        input: {
          galleryId,
        }
      },
      update: (cache: DataProxy, { data }) => {
        if (!data) return;

        updateCache(
          cache,
          galleryId,
          data.galleryActivate.status,
        );
      }});
  };

  const updateTranslation = (galleryId: string, language: string, description: string) => {
    return translationMutation({
      variables: {
        input: {
          galleryId,
          language,
          description,
        }
      }
    })
  };

  return {
    update,
    accept,
    reject,
    activate,
    deactivate,
    updateTranslation
  }
};
