import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { CustomerCreatePage } from './pages/customer-create.page';
import { CustomerListPage } from './pages/customer-list.page';
import { CustomerDetailPage } from './pages/CustomerDetailPages';

export const CustomerModule = () => (
  <Routes>
    <Route path={`/detail/:id`} element={<CustomerDetailPage />} />
    <Route path={`/create`} element={<CustomerCreatePage />} />
    <Route path={'/'} element={<CustomerListPage />} />
  </Routes>
);
