import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Artwork } from '../models/artwork';
import { api } from '../../../api/api';
import { RootState } from '../../../app/store';

export const listArtwork = createAsyncThunk('artworks/list', async () => {
  const { data } = await api.get('/artworks');
  return data;
});

export const deleteArtwork = createAsyncThunk('artworks/delete', async (artworkId: string) => {
  const { data } = await api.delete(`/artworks/${artworkId}`);
  return data;
});

const artworkAdapter = createEntityAdapter<Artwork>({
  selectId: entity => entity._id,
});

const initialState = artworkAdapter.getInitialState();

const artworkSlice = createSlice<any, any, 'artwork'>({
  name: 'artwork',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listArtwork.fulfilled, (state, action) => {
        artworkAdapter.upsertMany(state, action.payload);
      })
      .addCase(deleteArtwork.fulfilled, (state, action) => {
        artworkAdapter.removeOne(state, action.meta.arg)
      });
  }
});

export const {
  selectAll: selectAllArtworks,
} = artworkAdapter.getSelectors<RootState>(state => state.artwork);

export const artworkReducer = artworkSlice.reducer;

