import {Button, Card} from 'antd';
import React from 'react';
import {useModal} from '../../components/hooks/useModal';
import {VendorAddressModal} from './VendorAddressModal';

interface Props {
  vendorId: string;
  address: {
    line1: string | null;
    line2?: string | null;
    city: string | null;
    country: string | null;
    postalCode: string | null;
    state?: string | null;
  };
}


export const VendorAddress: React.FC<Props> = ({vendorId, address}) => {
  const {visible, openModal, closeModal} = useModal();

  return (
    <Card title='Address' style={{marginTop: '1.6rem'}} extra={<Button onClick={openModal}>update</Button>}>
      <p>{address.line1}</p>
      {address.line2 && <p>{address.line2}</p>}
      <p>{address.postalCode} {address.city}</p>
      <p>{address.state}, {address.country}</p>

      <VendorAddressModal
        vendorId={vendorId}
        visible={visible}
        onClose={closeModal}
        address={address}
      />
    </Card>
  );
};
