import React from 'react';
import useSWR from 'swr';
import styled from 'styled-components';
import { api } from '../../api/api';
import { Button, Col, Descriptions, Empty, Layout, message, PageHeader, Row, Tag, Image, Table } from 'antd';
import { Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Collection, Artwork, ArtworkImage } from '../models/collection';
import { useModal } from '../../components/hooks/useModal';
import { ArtworkAppendForm } from '../components/ArtworkAppendForm';

const fetcher = (url: string) => api.get(url).then((res) => res.data);

export const CollectionDetailPage = () => {

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const collectionName = searchParams.get('name');
    const { visible, openModal, closeModal } = useModal();
    //Fetching artworks belonging to the collection
    const { data: artworks, mutate, isLoading } = useSWR<Artwork[]>(`/collections/${id}/artworks`, fetcher);

    //Artwork append success handler
    const handleAppendSuccess = () => {
      // Reload the page
      window.location.reload();
    };

    const deleteArtwork = async (artworkId: string) => {
      try {
          // Optimistically update the artwork list by removing the deleted artwork locally
          await mutate(
              async (currentData) => {
                  // Perform the delete request
                  
                  await api.delete(`/collections/${id}/artworks/${artworkId}`);
                  
                  // Return the updated data after filtering out the deleted item
                  return currentData?.filter((artwork) => artwork._id !== artworkId);
              },
              {
                  // revalidate: false means SWR will not refetch immediately
                  revalidate: false,
              }
          );
      } catch (error) {
          console.error("Failed to delete artwork:", error);
          // Optionally, you could add error handling here (e.g., show a message to the user)
      }
    };

    const columns = [

      {
        title: 'Image',
        dataIndex: 'images',
        key: 'images',
        render: (images: ArtworkImage[]) => (
          <Image src={images[0].src} height={100}></Image>
        ),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name: string) => (<span>{name}</span>),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'name',
        render: (status: string) => (<span>{status}</span>),
      },
      {
        title: 'Link',
        dataIndex: 'slug',
        key: 'slug',
        render: (slug: string) => (<Link to={'https://urbaneez.art/artworks/'+slug} target='_blank'>link</Link>),
      },
  
      {
        title: 'Action',
        key: 'action',
        render: (artwork: Artwork) => (
          <span>
            <Link to="" onClick={(e) => {
              e.preventDefault(); // Prevent default link behavior
              deleteArtwork(artwork._id); // Call deleteArtwork with artwork ID
            }}>
              Delete
            </Link>
          </span>
        ),
      },
      
    ];

    return (
    <Layout>
        <Layout.Content>
          <PageHeader 
            onBack={() => navigate(-1)}
            title={'Collections'}
            subTitle={collectionName}
            extra={[
              <Button onClick={openModal}>Add Artwork</Button>
            ]}>
          </PageHeader>

          <Table
            rowKey='slug'
            columns={columns as any}
            loading={isLoading}
            dataSource={artworks}
          />

          <ArtworkAppendForm 
            collectionId={id} 
            visible={visible}
            onSuccess={handleAppendSuccess} 
            onClose={closeModal} 
            onOk={closeModal}></ArtworkAppendForm>
        
        </Layout.Content>
    </Layout>
    );
}

// TODO Create Custom page header for detail page
const Back = styled.div`
  margin-top: 3.2rem;
  font-family: Quicksand, 'sans-serif';
  font-weight: 700;
  color: #a7cde3;
  //color: #95a2b3;
  font-size: 1.4rem;
`;

const PageTitle = styled.h1`
  font-size: 2.4rem;
  font-family: Quicksand, 'sans-serif';
  color: #8198aa;
  font-weight: 700;

  strong {
    color: #3e475e;
  }
`;