import { Card} from 'antd';
import React from 'react';
import {FormattedNumber} from 'react-intl';
import {PriceLine, PriceLineTotal} from '../../components/price-line/PriceLine';

interface PriceCardProps {
  currency: string;
  shippingTotal: number;
  itemTotal: number;
  amountTotal: number;
}

export const PriceCard: React.FC<PriceCardProps> = ({
  currency,
  itemTotal,
  shippingTotal,
  amountTotal
}) => {

  return (
    <Card
      title="Price"
      style={{marginTop: '1.6rem'}}
    >
      <PriceLine>
        <div>Subtotal</div>
        <FormattedNumber value={itemTotal} currency={currency} style={'currency'}/>
      </PriceLine>

      <PriceLine>
        <div>Shipping</div>
        <FormattedNumber value={shippingTotal} currency={currency} style={'currency'}/>
      </PriceLine>

      <PriceLineTotal>
        <div>Total</div>
        <FormattedNumber value={amountTotal} currency={currency} style={'currency'}/>
      </PriceLineTotal>
    </Card>
  );
};
