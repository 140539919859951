import { Select } from 'antd';
import React from 'react';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const CurrencyAddOn = (props: Props) => (
  <Select style={{ width: 70 }} {...props}>
    <Select.Option value="CHF">CHF</Select.Option>
    <Select.Option value="EUR">EUR</Select.Option>
    <Select.Option value="USD">USD</Select.Option>
    <Select.Option value="GBP">GBP</Select.Option>
  </Select>
);
