import axios, {AxiosRequestConfig} from 'axios';
import {restBaseUri} from '../config';
import {CognitoUser} from '../lib/cognito/CognitoUser';

const api = axios.create({
  baseURL: restBaseUri,
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  let session;
  try {
    session = new CognitoUser().getSession();
  } catch (e) {}

  // @ts-ignore
  config.headers.Authorization = session ? `Bearer ${session.accessToken.getJwtToken()}` : '';

  return config;
});

export {
  api
};
