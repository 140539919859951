import { DataProxy } from 'apollo-cache';
import { useMutation} from 'react-apollo';
import {ArtistAccept, ArtistAcceptVariables} from '../__generated__/ArtistAccept';
import {ArtistFetch, ArtistFetchVariables} from '../__generated__/ArtistFetch';
import {ArtistReject, ArtistRejectVariables} from '../__generated__/ArtistReject';
import {ArtistUpdate, ArtistUpdateVariables} from '../__generated__/ArtistUpdate';
import {
  artistAccept,
  artistActivate,
  artistDeactivate,
  artistReject,
  artistTranslationUpdate, artistUpdate,
} from '../artist.mutations';
import {artistFetch} from '../artist.queries';
import {ArtistDeactivate, ArtistDeactivateVariables} from '../__generated__/ArtistDeactivate';
import {ArtistActivate, ArtistActivateVariables} from '../__generated__/ArtistActivate';
import {ArtistTranslationUpdate, ArtistTranslationUpdateVariables} from '../__generated__/ArtistTranslationUpdate';

export const useArtist = () => {
  const [updateMutation] = useMutation<ArtistUpdate, ArtistUpdateVariables>(artistUpdate);
  const [acceptMutation] = useMutation<ArtistAccept, ArtistAcceptVariables>(artistAccept);
  const [rejectMutation] = useMutation<ArtistReject, ArtistRejectVariables>(artistReject);
  const [deactivateMutation] = useMutation<ArtistDeactivate, ArtistDeactivateVariables>(artistDeactivate);
  const [activateMutation] = useMutation<ArtistActivate, ArtistActivateVariables>(artistActivate);
  const [translationMutation] = useMutation<ArtistTranslationUpdate, ArtistTranslationUpdateVariables>(artistTranslationUpdate);

  const updateCache = (cache: DataProxy, artistId: string, status: string ) => {
    const options = {
      query: artistFetch,
      variables: {
        id: artistId,
      }
    };

    const cacheData = cache.readQuery<ArtistFetch, ArtistFetchVariables>(options);

    if (!cacheData) return;

    cache.writeQuery<ArtistFetch, ArtistFetchVariables>({
      ...options,
      data: {
        artist: {
          ...cacheData.artist,
          status,
        }
      }
    });
  };

  const update = (artistId: string, input: any) => {
    return updateMutation({
      variables: {
        input: {
          artistId,
          score: input.score,
        }
      }
    })
  };

  const accept = (artistId: string) => {
    return acceptMutation({
      variables: {
        input: {
          vendorId: artistId
        }
      },
      update: (cache: DataProxy, { data }) => {
        if (!data) return;

        updateCache(
          cache,
          artistId,
          data.artistAccept.status,
        );
      }
    })
  };

  const reject = (artistId: string) => {
    return rejectMutation({
      variables: {
        input: {
          vendorId: artistId
        }
      },
      update: (cache: DataProxy, { data }) => {
        if (!data) return;

        updateCache(
          cache,
          artistId,
          data.artistReject.status,
        );
      }
    })
  };

  const deactivate = (artistId: string) => {
    return deactivateMutation({
      variables: {
        input: {
          artistId,
        }
      },
      update: (cache: DataProxy, { data }) => {
        if (!data) return;

        updateCache(
          cache,
          artistId,
          data.artistDeactivate.status,
        );
      }});
  };

  const activate = (artistId: string) => {
    return activateMutation({
      variables: {
        input: {
          artistId,
        }
      },
      update: (cache: DataProxy, { data }) => {
        if (!data) return;

        updateCache(
          cache,
          artistId,
          data.artistActivate.status,
        );
      }});
  };

  const updateTranslation = (artistId: string, language: string, bio: string) => {
    return translationMutation({
      variables: {
        input: {
          artistId,
          language,
          bio,
        }
      }
    })
  };

  return {
    update,
    accept,
    reject,
    deactivate,
    activate,
    updateTranslation,
  }
};
