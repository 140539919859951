import {Input, message, Modal} from 'antd';
import React, {useState} from 'react';
import { useMutation } from 'react-apollo';
import {GalleryFetch, GalleryFetchVariables} from '../../gallery/__generated__/GalleryFetch';
import {GalleryUpdate, GalleryUpdateVariables} from '../../gallery/__generated__/GalleryUpdate';
import {galleryUpdate} from '../../gallery/gallery.mutations';
import {galleryFetch} from '../../gallery/gallery.queries';

interface Props {
  visible: boolean;
  onClose: () => void;
  vendorId: string;
  description?: string | null;
}

export const VendorDescriptionModal: React.FC<Props> = ({ visible, onClose, vendorId, ...props }) => {
  const [description, setDescription] = useState(props.description || '');
  const [mutate, { loading }] = useMutation<GalleryUpdate, GalleryUpdateVariables>(galleryUpdate);

  const handleSubmit = () => {
    mutate({
      variables: {
        input: {
          galleryId: vendorId,
          description,
        }
      },
      update: (cache, { data }) => {
        const options = {
          query: galleryFetch,
          variables: {
            id: vendorId,
          }
        };

        const cachedData = cache.readQuery<GalleryFetch, GalleryFetchVariables>(options);

        if (!data || !cachedData) return;

        cache.writeQuery<GalleryFetch, GalleryFetchVariables>({
          ...options,
          data: {
            gallery: {
              ...cachedData.gallery,
              description,
            }
          }
        })
      }
    })
      .then(() => {
        message.success('Description updated.');
        onClose();
      })
      .catch(() => {
        message.error('Could not update gallery.');
      });
  };

  return (
    <Modal title={'Update description'} visible={visible} onOk={handleSubmit} onCancel={onClose} confirmLoading={loading}>
      <Input.TextArea
        value={description}
        onChange={({ target }) => setDescription(target.value)}
        rows={10}
      />
    </Modal>
  )
};
