import {Badge} from 'antd';
import React from 'react';

interface PaymentStatusProps {
  status: string;
}

export const PaymentStatus: React.FC<PaymentStatusProps> = ({ status }) => {
  const options = {
    open: {
      color: 'red',
      label: 'Payment Pending',
    },
    fullyPaid: {
      color: 'green',
      label: 'Fully Paid',
    }
  };

  // @ts-ignore
  let option = options[status];

  if (!option) {
    option = options.open;
  }

  return (
    <span>
      <Badge color={option.color}/> {option.label}
    </span>
  )
};
