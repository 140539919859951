let dataMemory = {};

class MemoryStorage {

    /**
     * This is used to set a specific item in storage
     * @param {string} key - the key for the item
     * @param {object} value - the value
     * @returns {string} value that was set
     */
    static setItem (key, value) {
        dataMemory[key] = value;
        return dataMemory[key];
    }

    /**
     * This is used to get a specific key from storage
     * @param {string} key - the key for the item
     * This is used to clear the storage
     * @returns {string, undefined} the data item
     */
    static getItem (key) {
        return Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : undefined;
    }

    /**
     * This is used to remove an item from storage
     * @param {string} key - the key being set
     * @returns {string, undefined} value - value that was deleted
     */
    static removeItem (key) {
        const value = Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : undefined;
        delete dataMemory[key];
        return value;
    }

    /**
     * This is used to clear the storage
     * @returns {object} nothing
     */
    static clear() {
        dataMemory = {};
        return dataMemory;
    }
}

export default class StorageHelper {

    /**
     * This is used to get a storage object
     * @returns {object} the storage
     */
    constructor() {
        try {
            const key = 'eagle.cognito.test';
            this.storageWindow = window.localStorage;
            this.storageWindow.setItem(key, 1);
            this.storageWindow.removeItem(key);
        } catch (e) {
            this.storageWindow = MemoryStorage;
        }
    }

    /**
     * This is used to return the storage
     * @returns {object} the storage
     */
    getStorage() {
        return this.storageWindow;
    }
}