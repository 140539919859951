import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { api } from '../../../api/api';
import { Order } from '../models/order';
import { RootState } from '../../../app/store';

export const fetchOrders = createAsyncThunk('orders/fetch-all', async () => {
  const { data } = await api.get('/orders');
  return data;
});

export const fetchOrder = createAsyncThunk('orders/fetch-one', async () => {

});

type CreateOrderInput = {
  customerId: string;
  shippingAddress: object;
  billingAddress: object;
}

export const createOrder = createAsyncThunk('orders/create', async (
  input: CreateOrderInput,
) => {
  const { data } = await api.post('/orders', input);
  return data;
});

type OrderState = EntityState<Order>;

const orderAdapter = createEntityAdapter<Order>({
  selectId: (entity) => entity._id,
});

const initialState = orderAdapter.getInitialState();

const orderSlice = createSlice<OrderState, any, 'order'>({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchOrders.fulfilled, (state, action) => {
        orderAdapter.upsertMany(state, action.payload);
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        orderAdapter.upsertOne(state, action.payload);
      });
  },
});

export const {
  selectAll: selectAllOrders,
} = orderAdapter.getSelectors<RootState>(state => state.order);

export const orderReducer = orderSlice.reducer;
