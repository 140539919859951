import gql from 'graphql-tag';

export const orderConfirm = gql`
    mutation OrderConfirm($orderId: String!) {
        orderConfirm(orderId: $orderId) {
            _id
            paymentStatus
        }
    }
`;

export const orderAddInvoice = gql`
    mutation OrderAddInvoice($input: OrderAddInvoiceInput!) {
        orderAddInvoice(input: $input) {
            signedUrl
            order {
                _id
                hasInvoice
                invoiceUploadedAt
                invoiceUrl
            }
        }
    }
`;

export const orderAddCertificate = gql`
    mutation OrderAddCertificate($input: OrderAddInvoiceInput!) {
        orderAddCertificate(input: $input) {
            signedUrl
            
            order {
                _id
                hasCertificates
                certificatesUploadedAt
                certificatesUrl
            }
        }
    }
`;

