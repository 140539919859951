import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const CustomerDetailPage = () => {
  const navigate = useNavigate();

  return (
    <Layout.Content>
      <Back>
        <ArrowLeftOutlined onClick={() => navigate(-1)} style={{ marginRight: '.8rem' }} />{' '}
        Customers
      </Back>
    </Layout.Content>
  );
};

const Back = styled.div`
  margin-top: 3.2rem;
  font-family: Quicksand, 'sans-serif';
  font-weight: 700;
  color: #a7cde3;
  //color: #95a2b3;
  font-size: 1.6rem;
`;
