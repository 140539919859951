import { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { Button, Layout, Table } from 'antd';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { PageTitle } from '../../components/layouts/page/PageTitle';
import { restBaseUri } from '../../config';
import { Article } from '../../models/article';

export const ArticleListPage = () => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${restBaseUri}/articles`).then(({ data }) => {
      setArticles(data);
      setLoading(false);
    });
  }, []);

  const columns: ColumnProps<Article>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <Link to={`/magazine/articles/${record._id}`}>{text}</Link>,
    },
  ];

  return (
    <Layout.Content>
      <PageTitle
        title="Articles"
        actions={
          <Button
            onClick={() => navigate('/magazine/articles/create')}
            title={'new article'}
            type={'primary'}>
            new article
          </Button>
        }
      />

      <Table loading={loading} columns={columns} dataSource={articles} />
    </Layout.Content>
  );
};
