import {useMutation} from 'react-apollo';
import {DataProxy} from 'apollo-cache';
import axios from 'axios';
import {apiBaseUri} from '../../config';
import {CognitoUser} from '../../lib/cognito/CognitoUser';
import {OrderAddCertificate, OrderAddCertificateVariables} from '../__generated__/OrderAddCertificate';
import {OrderAddInvoice, OrderAddInvoiceVariables} from '../__generated__/OrderAddInvoice';
import {OrderConfirm, OrderConfirmVariables} from '../__generated__/OrderConfirm';
import {OrderFetchOneById, OrderFetchOneByIdVariables} from '../__generated__/OrderFetchOneById';
import {orderAddCertificate, orderAddInvoice, orderConfirm} from '../order.mutations';
import {orderFetchOneById} from '../order.queries';

export const useOrder = () => {
  const [confirmMutation] = useMutation<OrderConfirm, OrderConfirmVariables>(orderConfirm);
  const [addInvoiceMutation] = useMutation<OrderAddInvoice, OrderAddInvoiceVariables>(orderAddInvoice);
  const [addCertificateMutation] = useMutation<OrderAddCertificate, OrderAddCertificateVariables>(orderAddCertificate);

  const confirmOrder = (orderId: string): Promise<any> => {
    return confirmMutation({
      variables: {
        orderId,
      },
      update: (cache: DataProxy, {data}) => {
        if (!data) return;

        const options = {
          query: orderFetchOneById,
          variables: {
            id: data.orderConfirm._id,
          },
        };

        const cacheData = cache.readQuery<OrderFetchOneById, OrderFetchOneByIdVariables>(options);

        if (!cacheData) return;

        cache.writeQuery<OrderFetchOneById, OrderFetchOneByIdVariables>({
          ...options,
          data: {
            order: {
              ...cacheData.order,
              paymentStatus: data.orderConfirm.paymentStatus,
            },
          },
        });
      },
    });
  };

  const addInvoice = (orderId: string): Promise<any> => {
    return addInvoiceMutation({
      variables: {
        input: {
          orderId,
        },
      },
      update: (cache: DataProxy, {data}) => {
        if (!data) return;

        const options = {
          query: orderFetchOneById,
          variables: {
            id: data.orderAddInvoice.order._id,
          },
        };

        const cacheData = cache.readQuery<OrderFetchOneById, OrderFetchOneByIdVariables>(options);

        if (!cacheData) return;

        cache.writeQuery<OrderFetchOneById, OrderFetchOneByIdVariables>({
          ...options,
          data: {
            order: {
              ...cacheData.order,
              ...data.orderAddInvoice.order,
            },
          },
        });
      },
    });
  };

  const addCertificates = (orderId: string): Promise<any> => {
    return addCertificateMutation({
      variables: {
        input: {
          orderId,
        },
      },
      update: (cache: DataProxy, {data}) => {
        if (!data) return;

        const options = {
          query: orderFetchOneById,
          variables: {
            id: data.orderAddCertificate.order._id,
          },
        };

        const cacheData = cache.readQuery<OrderFetchOneById, OrderFetchOneByIdVariables>(options);

        if (!cacheData) return;

        cache.writeQuery<OrderFetchOneById, OrderFetchOneByIdVariables>({
          ...options,
          data: {
            order: {
              ...cacheData.order,
              ...data.orderAddCertificate.order,
            },
          },
        });
      },
    });
  };

  const saveFile = (data: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([data], {
      type: data.type
    }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const downloadInvoice = (orderId: string): Promise<void> => {
    let session;

    try {
      session = new CognitoUser().getSession();
    } catch (e) {}

    return axios.post(apiBaseUri, {
      query: `
        query {
          orderDownloadInvoice(
            input: {
              orderId: "${orderId}"
            }
          ) {
            downloadUrl
          }
        }
      `,
    }, {
      headers: {
        Authorization: session ? `Bearer ${session.accessToken.getJwtToken()}` : ''
      }
    })
      .then(res => res.data.data.orderDownloadInvoice.downloadUrl)
      .then(url => axios.get(url, {responseType: 'blob'}))
      .then(res => saveFile(res.data, `${orderId}_invoice.pdf`));
  };

  const downloadCertificate = (orderId: string): Promise<void> => {
    let session;

    try {
      session = new CognitoUser().getSession();
    } catch (e) {}

    return axios.post(apiBaseUri, {
      query: `
        query {
          orderDownloadCertificate(
            input: {
              orderId: "${orderId}"
            }
          ) {
            downloadUrl
          }
        }
      `,
    }, {
      headers: {
        Authorization: session ? `Bearer ${session.accessToken.getJwtToken()}` : ''
      }
    })
      .then(res => res.data.data.orderDownloadCertificate.downloadUrl)
      .then(url => axios.get(url, {responseType: 'blob'}))
      .then(res => saveFile(res.data, `${orderId}_certificates.pdf`));
  };

  return {
    confirmOrder,
    addInvoice,
    addCertificates,
    downloadInvoice,
    downloadCertificate
  };
};
