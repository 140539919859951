import gql from 'graphql-tag';

export const eventCreate = gql`
    mutation EventCreate ($input: EventCreateInput!){
        eventCreate (
            input: $input
        ) {
            _id
            ownerId
            title
            location
            country
            city
            startAt
            endAt
            link
        }
    }
`;

export const eventUpdate = gql`
    mutation EventUpdate ($input: EventUpdateInput!){
        eventUpdate (
            input: $input
        ) {
            _id
            ownerId
            title
            location
            country
            city
            startAt
            endAt
            link
        }
    }
`;

export const eventDelete = gql`
    mutation EventDelete ($input: EventDeleteInput!){
        eventDelete (
            input: $input
        ) {
            _id
        }
    }
`;

export const eventCoverUpdate = gql`
    mutation EventCoverUpdate ($input: EventCoverUpdateInput!){
        eventCoverUpdate (
            input: $input
        ) {
           url
        }
    }
`;
