import React from 'react';
import { Query, useMutation } from 'react-apollo';
import { Button, Divider, PageHeader, Table, Modal, message, Layout } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { galleryFetchAll } from '../gallery.queries';
import { galleryDelete } from '../gallery.mutations';
import { GalleryFetch_gallery } from '../__generated__/GalleryFetch';
import { GalleryDelete } from '../__generated__/GalleryDelete';
import { GalleryFetchAll } from '../__generated__/GalleryFetchAll';

const rowSelection = {
  onChange: (selectedRowKeys: any, selectedRows: any) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record: any) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
};

export const GalleryListPage = () => {
  const navigate = useNavigate();
  const [mutate] = useMutation<GalleryDelete>(galleryDelete);

  const handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Are you sure delete this gallery?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        mutate({
          variables: {
            input: {
              galleryId: id,
            },
          },
          update: (cache) => {
            const data = cache.readQuery<GalleryFetchAll>({ query: galleryFetchAll });

            if (!data || !data.galleries) {
              return;
            }

            cache.writeQuery({
              query: galleryFetchAll,
              data: {
                galleries: data.galleries.filter((gallery) => gallery._id !== id),
              },
            });
          },
        })
          .then(() => {
            message.success('Gallery successfully deleted.');
          })
          .catch(() => {
            message.error('Unable to delete gallery.');
          });
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: GalleryFetch_gallery) => (
        <span>
          <Link to={`/galleries/detail/${record._id}`}>
            <a>Detail</a>
          </Link>

          <Divider type="vertical" />

          <a onClick={() => handleDelete(record._id)} color="magenta">
            Delete
          </a>
        </span>
      ),
    },
  ];

  return (
    <Layout.Content>
      <PageHeader
        title={'Galleries'}
        extra={[
          <Button
            onClick={() => navigate('/galleries/create')}
            title={'new gallery'}
            type={'primary'}>
            new gallery
          </Button>,
        ]}
      />

      <Query query={galleryFetchAll}>
        {({ loading, error, data }: any) => {
          if (loading) return null;
          if (error) return error;

          const { galleries } = data;

          return <Table columns={columns} dataSource={galleries} rowSelection={rowSelection} />;
        }}
      </Query>
    </Layout.Content>
  );
};
