import React from 'react';
import { Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

interface Props {
  toggleMenu: any;
  collapsed: boolean;
}

export const AppBar: React.FunctionComponent<Props> = (props) => (
  <Layout.Header style={{ background: '#fff', padding: '0 1.6rem' }}>
    {props.collapsed ? (
      <MenuUnfoldOutlined
        className="trigger"
        onClick={props.toggleMenu}
        style={{ fontSize: '1.6rem' }}
      />
    ) : (
      <MenuFoldOutlined
        className="trigger"
        onClick={props.toggleMenu}
        style={{ fontSize: '1.6rem' }}
      />
    )}
  </Layout.Header>
);
