import gql from 'graphql-tag';

export const exchangeRatesFetch = gql`
  query ExchangeRatesFetch {
    exchangeRates {
      _id
      value
      sourceCurrency
      targetCurrency
      validFrom
      validTo
    }
  }
`;
