import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { orderReducer } from '../features/order/store/order.slice';
import { customerReducer } from '../features/customer/store/customer.slice';
import { artworkReducer } from '../features/artworks/store/artwork.slice';

const combinedReducer = combineReducers({
  order: orderReducer,
  customer: customerReducer,
  artwork: artworkReducer,
});

const rootReducer = (state: any, action: any) => {
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
