import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { Button, Layout, PageHeader, Table } from 'antd';
import { FormattedDate, FormattedNumber } from 'react-intl';

import { fetchOrders, selectAllOrders } from '../../features/order/store/order.slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Order } from '../../features/order/models/order';

export const OrderListPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orders = useAppSelector(selectAllOrders);

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  // const [mutate] = useMutation<GalleryDelete>(galleryDelete);
  //
  // const handleDelete = (id: string) => {
  //   Modal.confirm({
  //     title: 'Are you sure delete this order?',
  //     okText: 'Yes',
  //     okType: 'danger',
  //     cancelText: 'No',
  //     onOk() {
  //       mutate({
  //         variables: {
  //           input: {
  //             orderId: id
  //           }
  //         },
  //         update: (cache) => {
  //           const data = cache.readQuery<GalleryFetchAll>({ query: galleryFetchAll });
  //
  //           if(!data || !data.galleries) {
  //             return;
  //           }
  //
  //           cache.writeQuery({
  //             query: galleryFetchAll,
  //             data: {
  //               galleries: data.galleries.filter(gallery => gallery._id !== id),
  //             },
  //           });
  //         }
  //       }).then(() => {
  //         message.success('Gallery successfully deleted.');
  //       }).catch(() => {
  //         message.error('Unable to delete gallery.');
  //       });
  //     },
  //     onCancel() {},
  //   })
  // };

  const columns: ColumnProps<Order>[] = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => (
        <FormattedDate value={text} day={'numeric'} month={'numeric'} year={'numeric'} />
      ),
    },
    {
      title: 'Price',
      key: 'price',
      render: (_, record) => (
        <FormattedNumber value={record.amountTotal} currency={record.currency} style={'currency'} />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record) => (
        <span>
          <Link to={`/orders/detail/${record._id}`}>
            <a>Detail</a>
          </Link>
        </span>
      ),
    },
  ];

  return (
    <Layout.Content>
      <PageHeader
        title={'Orders'}
        extra={[
          <Button onClick={() => navigate('/orders/create')} title={'new order'} type={'primary'}>
            new order
          </Button>,
        ]}
      />

      <Table loading={!orders} columns={columns} dataSource={orders} />
    </Layout.Content>
  );
};
