import React from 'react';
import { EventList } from '../../event/components/EventList';
import { StoryList } from '../../story/components/StoryList';
import { ArtistFetch_artist } from '../__generated__/ArtistFetch';
import { ArtistBioCard } from './ArtistBioCard';

interface Props {
  artist: ArtistFetch_artist;
}

export const ArtistInformationTab: React.FunctionComponent<Props> = ({ artist }) => (
  <div>
    <ArtistBioCard artistId={artist._id} bio={artist.bio} translations={artist.translation || []} />

    <EventList ownerId={artist._id} />

    <StoryList ownerId={artist._id} />
  </div>
);
