import React from 'react';
import { Form, Input, message, Modal } from 'antd';
import { useMutation } from 'react-apollo';

import { ArtistFetch, ArtistFetchVariables } from '../../artist/__generated__/ArtistFetch';
import { ArtistUpdate, ArtistUpdateVariables } from '../../artist/__generated__/ArtistUpdate';
import { artistUpdate } from '../../artist/artist.mutations';
import { artistFetch } from '../../artist/artist.queries';

interface Props {
  visible: boolean;
  onClose: () => void;
  vendorId: string;
  address: {
    line1: string | null;
    line2?: string | null;
    city: string | null;
    country: string | null;
    postalCode: string | null;
    state?: string | null;
  };
}

export const VendorAddressModal: React.FC<Props> = (props) => {
  const { visible, onClose, vendorId, address } = props;

  const [mutate, { loading }] = useMutation<ArtistUpdate, ArtistUpdateVariables>(artistUpdate);

  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      mutate({
        variables: {
          input: {
            artistId: vendorId,
            shippingAddress: values,
          },
        },
        update: (cache, { data }) => {
          const options = {
            query: artistFetch,
            variables: {
              id: vendorId,
            },
          };

          const cachedData = cache.readQuery<ArtistFetch, ArtistFetchVariables>(options);

          if (!cachedData || !data) return;

          cache.writeQuery<ArtistFetch, ArtistFetchVariables>({
            ...options,
            data: {
              artist: {
                ...cachedData.artist,
                shippingAddress: values,
              },
            },
          });
        },
      })
        .then(() => {
          message.success('Address updated.');
          onClose();
        })
        .catch(() => {
          message.error('Could not update address.');
        });
    });
  };

  return (
    <Modal
      title={'Update address'}
      onCancel={onClose}
      onOk={handleSubmit}
      confirmLoading={loading}
      visible={visible}>
      <Form form={form}>
        <Form.Item
          label={'Line 1'}
          name="line1"
          initialValue={address.line1}
          rules={[
            {
              required: true,
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item label={'Line 2'} name="line2" initialValue={address.line2}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'Postal Code'}
          name={'postalCode'}
          initialValue={address.postalCode}
          rules={[
            {
              required: true,
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'City'}
          name="city"
          initialValue={address.city}
          rules={[
            {
              required: true,
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'State'}
          name="state"
          initialValue={address.state}
          rules={[
            {
              required: true,
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'Country'}
          name="country"
          initialValue={address.country}
          rules={[
            {
              required: true,
            },
          ]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
