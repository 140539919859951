import { Card, message, Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ArtworkTable } from '../../artwork/components/ArtworkTable';
import { CardTitle } from './Card';
import { GalleryFetch_gallery_artworks } from '../../gallery/__generated__/GalleryFetch';
import { useMutation } from 'react-apollo';
import { ArtworkDelete } from '../../artwork/__generated__/ArtworkDelete';
import { artworkDelete } from '../../artwork/artwork.mutations';

interface ArtworkListCardProps {
  artworks: GalleryFetch_gallery_artworks[];
}

export const ArtworkListCard: React.FunctionComponent<ArtworkListCardProps> = ({ artworks }) => {
  const [mutate] = useMutation<ArtworkDelete>(artworkDelete);

  const handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Are you sure delete this artwork?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        mutate({
          variables: {
            input: {
              artworkId: id,
            },
          },
        })
          .then(() => {
            message.success('Artwork successfully deleted.');
          })
          .catch(() => {
            message.error('Unable to delete artwork.');
          });
      },
      onCancel() {},
    });
  };

  return (
    <Card style={{ margin: '1.6rem' }}>
      <CardTitle>Artworks</CardTitle>

      <Content>
        <ArtworkTable artworks={artworks} onDelete={handleDelete} />
      </Content>
    </Card>
  );
};

const Content = styled.div`
  margin-top: 1.6rem;
`;
