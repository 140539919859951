import React, {useState} from 'react';
import {Button, Card, Modal, Tabs} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {useArtist} from '../hooks/use-artist';

interface ArtistBioCardProps {
  artistId: string;
  bio: string;
  translations: {
    language: string,
    bio: string,
  }[]
}

export const ArtistBioCard: React.FC<ArtistBioCardProps> = ({ artistId, bio, translations}) => {
  const [updateModal, setUpdateModal] = useState(false);
  const [currentBio, setCurrentBio] = useState('');
  const [currentLocale, setCurrentLocale] = useState('');

  const { updateTranslation } = useArtist();

  const openModal = (lg: string) => {
    setUpdateModal(true);

    const translation = translations.find(el => el.language === lg);
    setCurrentBio(translation?.bio || '');
    setCurrentLocale(lg);
  };

  const closeModal = () => {
    setUpdateModal(false);
    setCurrentBio('');
    setCurrentLocale('');
  };

  const handleUpdate = () => {
    updateTranslation(
      artistId,
      currentLocale,
      currentBio
    )
      .then(() => {
        closeModal();
      });
  }

  return (
    <Card title='Bio'>
      <Tabs>
        <Tabs.TabPane tab={'English'} key={'en'}>
          <p dangerouslySetInnerHTML={{__html: bio}}/>
        </Tabs.TabPane>

        <Tabs.TabPane tab={'French'} key={'fr'}>
          <p dangerouslySetInnerHTML={{__html: translations.find(el => el.language === 'fr')?.bio || ''}}/>

          <Button onClick={() => openModal('fr')}>Update</Button>
        </Tabs.TabPane>
      </Tabs>

      <Modal visible={updateModal} onCancel={closeModal} title={"Update bio"} onOk={handleUpdate}>
        <TextArea
          value={currentBio}
          onChange={({target}) => setCurrentBio(target.value)}
        />
      </Modal>
    </Card>
  );
};
