import React from 'react';
import styled from 'styled-components';

const Container = styled.button`
position: relative;
background-color: #626ed4;
border: 1px solid #626ed4;
color: white;
border-radius: .3rem;
font-size: 1.4rem;
text-align: center;
vertical-align: middle;
padding: .6rem 1.2rem;
line-height: 1.5;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
cursor: pointer;
min-width: 11rem;

&:hover {
  background-color: #4e5ccf;
  border: 1px solid #4e5ccf
}
`;

interface ButtonProps {
  title: string;
}

export const Button: React.FunctionComponent<ButtonProps> = props => (
  <Container>
    {props.title}
  </Container>
);
