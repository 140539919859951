import gql from 'graphql-tag';

export const orderFetchOneById = gql`
    query OrderFetchOneById ($id: String!){
        order (
            id: $id
        ) {
            _id
            itemTotal
            shippingTotal
            amountTotal
            currency
            paymentStatus
            hasInvoice
            invoiceUploadedAt
            invoiceUrl
            
            hasCertificates
            
            number
            
            lineItems {
                quantity
                
                product {
                    name
                    miniature
                    price {
                        currency
                        value
                    }
                }
            }

            billingAddress {
                line1
                line2
                postalCode
                city
                state
                country
            }

            shippingAddress {
                line1
                line2
                postalCode
                city
                state
                country
            }
            
            customer {
                _id
                firstName
                lastName
                email
                phone {
                    prefix
                    number
                }
            }
            
            createdAt
        }
    }
`;

export const orderDownloadInvoice = gql`
    query OrderDownloadInvoice($input: OrderDownloadInvoiceInput!) {
        orderDownloadInvoice(input: $input) {
            downloadUrl
        }
    }
`;
