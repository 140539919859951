import gql from 'graphql-tag';

export const galleryFetch = gql`
    query GalleryFetch($id: String!) {
        gallery (
            id: $id
        ) {
            _id
            name
            contactName
            featured
            city
            country
            description
            profilePicture
            email
            logo
            openingHours
            status
            vendorId
            score
            
            preferences {
                language
                currency
            }
            
            artworks {
                _id
                featured
                name
                description
                category
                style
                subject
                technique
                status
                year
                format

                artist {
                    _id
                    nickName
                }

                price {
                    value
                    currency
                }

                dimension {
                    width
                    height
                }
            }
            
            identifier {
                type
                value
            }
            
            address {
                line1
                line2
                postalCode
                state
                city
                country
            }

            phone {
                prefix
                number
            }
            
            instagram
            facebook
            twitter
            
            artists {
                artist {
                    _id
                    nickName
                }
            }
            
            translation {
                language
                description
            }
        }
    }
`;

export const galleryFetchAll = gql`
    query GalleryFetchAll {
        galleries {
            _id
            name
            country
            city
            status
        }
    }
`;
