import {ColumnProps} from 'antd/lib/table';
import React from 'react';
import {Avatar, Badge, Card, Table} from 'antd';
import {FormattedNumber} from 'react-intl';


interface ItemListCardProps {
  items: any[]
}

export const ItemListCard: React.FC<ItemListCardProps> = ({
  items,
}) => {

  const columns: ColumnProps<any>[] = [{
    key: 'avatar',
    render: ({miniature}) => (
      <Avatar src={miniature}/>
    )
  }, {
    title: 'Name',
    key: 'name',
    render: ({ name }) => (
      <span>{name}</span>
    )
  }, {
    title: 'Quantity',
    key: 'quantity',
    dataIndex: 'quantity'
  }, {
    title: 'Price',
    key: 'price',
    render: (product) => (
      <FormattedNumber value={product.price.value} currency={product.price.currency} style={'currency'}/>
    )
  }, {
    title: 'Total',
    key: 'total',
    render: (product, record) => (
      <FormattedNumber
        value={product.price.value * record.quantity}
        currency={product.price.currency}
        style={'currency'}
      />
    )
  }];

  return (
    <Card
      title={<span><Badge color={'red'}/> Unfulfilled</span>}
    >
      <Table
        dataSource={items}
        columns={columns}
        pagination={false}
      />
    </Card>
  )
};
