import React, { useState } from 'react';
import { Button, Card, Divider, message, Table } from 'antd';
import { useQuery, useMutation } from 'react-apollo';
import { EventDelete, EventDeleteVariables } from '../__generated__/EventDelete';
import {
  EventFetchAll,
  EventFetchAll_events,
  EventFetchAllVariables,
} from '../__generated__/EventFetchAll';
import { eventDelete } from '../event.mutation';
import { eventFetchAll } from '../event.queries';
import { useModal } from '../../components/hooks/useModal';
import { EventCreateModal } from './EventCreateModal';
import { EventUpdateModal } from './EventUpdateModal';

interface Props {
  ownerId: string;
}

export const EventList: React.FunctionComponent<Props> = ({ ownerId }) => {
  const { visible, openModal, closeModal } = useModal();
  const [currentEvent, setCurrentEvent] = useState<EventFetchAll_events | null>(null);
  const {
    visible: updateVisible,
    openModal: openUpdateModal,
    closeModal: closeUpdateModal,
  } = useModal();
  const [mutate] = useMutation<EventDelete, EventDeleteVariables>(eventDelete);

  const { loading, error, data } = useQuery<EventFetchAll, EventFetchAllVariables>(eventFetchAll, {
    variables: {
      filter: {
        ownerId: {
          eq: ownerId,
        },
      },
    },
  });

  if (error || !data) {
    return null;
  }

  const { events } = data;

  const handleUpdateOpen = (id: string) => {
    if (!data || !data.events) {
      return;
    }

    setCurrentEvent(data.events.find((el) => el._id === id) || null);
    openUpdateModal();
  };

  const handleUpdateClose = () => {
    closeUpdateModal();
    setCurrentEvent(null);
  };

  const handleDelete = (eventId: string) => {
    mutate({
      variables: {
        input: {
          eventId,
        },
      },
      update: (cache) => {
        const options = {
          query: eventFetchAll,
          variables: {
            filter: {
              ownerId: {
                eq: ownerId,
              },
            },
          },
        };

        const data = cache.readQuery<EventFetchAll, EventFetchAllVariables>(options);

        if (!data) {
          return;
        }

        cache.writeQuery<EventFetchAll, EventFetchAllVariables>({
          ...options,
          data: {
            events: data.events.filter((el) => el._id !== eventId),
          },
        });
      },
    })
      .then(() => {
        message.success('Event deleted.');
      })
      .catch(() => {
        message.error('Could not delete event.');
      });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: EventFetchAll_events) => (
        <span>
          <a onClick={() => handleUpdateOpen(record._id)}>Update</a>
          <Divider type="vertical" />
          <a onClick={() => handleDelete(record._id)}>Delete</a>
        </span>
      ),
    },
  ];

  return (
    <Card
      title="Events"
      style={{ marginTop: '1.6rem' }}
      extra={<Button onClick={openModal}>new</Button>}>
      <Table loading={loading} dataSource={events} columns={columns} />

      <EventCreateModal
        ownerId={ownerId}
        visible={visible}
        onCancel={closeModal}
        onOk={closeModal}
      />

      {currentEvent && (
        <EventUpdateModal
          visible={updateVisible}
          event={currentEvent}
          onCancel={handleUpdateClose}
          onOk={handleUpdateClose}
        />
      )}
    </Card>
  );
};
