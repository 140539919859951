import {ColumnProps} from 'antd/lib/table';
import React, {useEffect, useState} from 'react';
import {Button, Layout, Table} from 'antd';
import axios from 'axios';
import {useModal} from '../../components/hooks/useModal';
import {PageTitle} from '../../components/layouts/page/PageTitle';
import {restBaseUri} from '../../config';
import {Author} from '../../models/author';
import {CreateAuthorDialog} from '../components/CreateAuthorDialog';

const columns: ColumnProps<Author>[] = [{
  title: 'Display name',
  dataIndex: 'displayName',
  key: 'displayName',
}];

export const AuthorListPage = () => {
  const [authors, setAuthors] = useState<Author[]>([]);
  const [loading, setLoading] = useState(true);
  const {visible, closeModal, openModal} = useModal();

  useEffect(() => {
    axios
      .get(`${restBaseUri}/authors`)
      .then(({ data }) => {
        setAuthors(data);
        setLoading(false);
      });
  }, []);

  const onFinish = (author: Author) => {
    setAuthors(authors.concat(author));
  };

  return (
    <Layout.Content>
      <PageTitle
        title="Authors"
        actions={(
          <Button
            onClick={openModal}
            title={'new author'}
            type={'primary'}
          >
            new author
          </Button>
        )}
      />

      <Table
        loading={loading}
        columns={columns}
        dataSource={authors}
      />

      <CreateAuthorDialog
        onClose={closeModal}
        onFinish={onFinish}
        visible={visible}
      />
    </Layout.Content>
  );
};
