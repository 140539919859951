import React, { FormEvent, useState } from 'react';
import { Form, Input, message, Modal, Upload, Button, Select } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import { StoryCreate, StoryCreateVariables } from '../__generated__/StoryCreate';
import { storyCoverUpdate, storyCreate } from '../story.mutations';
import { StoryCoverUpdate, StoryCoverUpdateVariables } from '../__generated__/StoryCoverUpdate';
import { StoryFetchAll, StoryFetchAllVariables } from '../__generated__/StoryFetchAll';
import { storyFetchAll } from '../story.queries';

interface Props {
  ownerId: string;
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const StoryCreateModal: React.FunctionComponent<Props> = (props) => {
  const { ownerId, onOk, ...modalProps } = props;
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('quote');
  const [mutate] = useMutation<StoryCreate, StoryCreateVariables>(storyCreate);
  const [updateCover] = useMutation<StoryCoverUpdate, StoryCoverUpdateVariables>(storyCoverUpdate);

  const [form] = Form.useForm();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        const { cover, ...data } = values;

        return mutate({
          variables: {
            input: {
              ownerId: ownerId,
              ...data,
            },
          },
          update: (cache, { data }) => {
            const cacheData = cache.readQuery<StoryFetchAll, StoryFetchAllVariables>({
              query: storyFetchAll,
              variables: {
                filter: {
                  ownerId: {
                    eq: ownerId,
                  },
                },
              },
            });

            if (!cacheData || !data) {
              return;
            }

            const { stories } = cacheData;

            const { storyCreate } = data;

            cache.writeQuery({
              query: storyFetchAll,
              variables: {
                filter: {
                  ownerId: {
                    eq: ownerId,
                  },
                },
              },
              data: {
                stories: stories.concat(storyCreate),
              },
            });
          },
        })
          .then((res) => {
            if (values.type !== 'image' || !res) {
              return;
            }

            const { data: resData } = res;

            if (!resData) {
              return;
            }

            return updateCover({
              variables: {
                input: {
                  storyId: resData.storyCreate._id,
                  type: cover.file.type,
                },
              },
            }).then((coverRes: any) => {
              if (!coverRes) {
                return;
              }

              const { data: coverResData } = coverRes;

              if (!coverResData) {
                return;
              }

              const { storyCoverUpdate } = coverResData;

              return axios.put(storyCoverUpdate.url, cover.file.originFileObj);
            });
          })
          .then(() => {
            message.success('New event created!');
            onOk();
          })
          .catch((e) => {
            console.log(e);
            message.error('Unable to create event!');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
        console.log('Received values of form: ');
      });
  };

  return (
    <Modal onOk={handleSubmit} {...modalProps} destroyOnClose={true} confirmLoading={loading}>
      <Form layout="vertical" form={form}>
        <Form.Item
          label={'Type'}
          name={'type'}
          rules={[
            {
              required: true,
              message: 'Please input an story type',
            },
          ]}>
          <Select onChange={setType} defaultActiveFirstOption>
            <Select.Option value={'quote'}>Quote</Select.Option>
            <Select.Option value={'image'}>Image</Select.Option>
            <Select.Option value={'video'}>Video</Select.Option>
          </Select>
        </Form.Item>

        {type === 'quote' && (
          <>
            <Form.Item label={'Content'} name={'content'}>
              <Input />
            </Form.Item>

            <Form.Item label={'Author'} name={'author'}>
              <Input />
            </Form.Item>

            <Form.Item label={'Date'} name={'date'}>
              <Input />
            </Form.Item>
          </>
        )}

        {type === 'video' && (
          <>
            <Form.Item label={'Caption'} name={'caption'}>
              <Input />
            </Form.Item>

            <Form.Item label={'Source'} name={'src'}>
              <Input />
            </Form.Item>
          </>
        )}

        {type === 'image' && (
          <>
            <Form.Item label={'Caption'} name={'caption'}>
              <Input />
            </Form.Item>

            <Form.Item
              label={'Cover'}
              name={'cover'}
              rules={[
                {
                  required: true,
                  message: 'Please input an image',
                },
              ]}>
              <Upload name="logo" action="/upload.do" listType="picture" customRequest={() => {}}>
                <Button>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};
