import React, { FormEvent, useState } from 'react';
import { DatePicker, Form, Input, message, Modal, Upload, Button } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import axios from 'axios';
import { EventCreate, EventCreateVariables } from '../__generated__/EventCreate';
import { eventCoverUpdate, eventCreate } from '../event.mutation';
import { eventFetchAll } from '../event.queries';
import { EventFetchAll } from '../__generated__/EventFetchAll';
import { EventCoverUpdate, EventCoverUpdateVariables } from '../__generated__/EventCoverUpdate';
import { UploadOutlined } from '@ant-design/icons';

interface Props {
  ownerId: string;
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const EventCreateModal: React.FC<Props> = (props) => {
  const { ownerId, onOk, ...modalProps } = props;
  const [loading, setLoading] = useState(false);
  const [mutate] = useMutation<EventCreate, EventCreateVariables>(eventCreate);
  const [updateCover] = useMutation<EventCoverUpdate, EventCoverUpdateVariables>(eventCoverUpdate);

  const [form] = Form.useForm();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        const { cover, ...data } = values;

        return mutate({
          variables: {
            input: {
              ownerId: ownerId,
              ...data,
            },
          },
          update: (cache, { data }) => {
            const cacheData = cache.readQuery<EventFetchAll>({
              query: eventFetchAll,
              variables: {
                filter: {
                  ownerId: {
                    eq: ownerId,
                  },
                },
              },
            });

            if (!cacheData || !data) {
              return;
            }

            const { events } = cacheData;

            const { eventCreate } = data;

            cache.writeQuery({
              query: eventFetchAll,
              variables: {
                filter: {
                  ownerId: {
                    eq: ownerId,
                  },
                },
              },
              data: {
                events: [...events, eventCreate],
              },
            });
          },
        })
          .then(({ data }) => {
            if (!data) {
              return;
            }

            const { eventCreate } = data;

            return updateCover({
              variables: {
                input: {
                  eventId: eventCreate._id,
                  type: cover.file.type,
                },
              },
            });
          })
          .then((res) => {
            if (!res) {
              return;
            }

            const { data } = res;

            if (!data) {
              return;
            }

            const { eventCoverUpdate } = data;

            return axios.put(eventCoverUpdate.url, cover.file.originFileObj);
          })
          .then(() => {
            message.success('New event created!');
            onOk();
          })
          .catch(() => {
            message.error('Unable to create event!');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log('Received values of form: ', err);
      });
  };

  return (
    <Modal onOk={handleSubmit} {...modalProps} destroyOnClose={true} confirmLoading={loading}>
      <Form layout="vertical" form={form}>
        <Form.Item
          label={'Title'}
          name="title"
          rules={[
            {
              required: true,
              message: 'Please input an event title',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'Location'}
          name="location"
          rules={[
            {
              required: true,
              message: 'Please input a location',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'Country'}
          name="country"
          rules={[
            {
              required: true,
              message: 'Please input a country',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'City'}
          name="city"
          rules={[
            {
              required: true,
              message: 'Please input a city',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item label={'Link'} name={'link'}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'Start date'}
          name={'startAt'}
          rules={[
            {
              required: true,
              message: 'Please input an end date',
            },
          ]}>
          <DatePicker />
        </Form.Item>

        <Form.Item
          label={'End Date'}
          name={'endAt'}
          rules={[
            {
              required: true,
              message: 'Please input an end date',
            },
          ]}>
          <DatePicker />
        </Form.Item>

        <Form.Item
          label={'Cover'}
          name="cover"
          rules={[
            {
              required: true,
              message: 'Please input an end date',
            },
          ]}>
          <Upload name="logo" action="/upload.do" listType="picture" customRequest={() => {}}>
            <Button>
              <UploadOutlined /> Click to upload
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};
