import React, { FormEvent, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CitySelect } from '../../components/select/CitySelect';
import { CountrySelect } from '../../components/select/CountrySelect';

interface Props {
  handleSubmit: (values: any) => void;
}

export const GalleryCreateForm: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState('');

  const [form] = Form.useForm();

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then(async (values: any) => {
        const { contactDateOfBirth, contactFirstName, contactLastName, contactEmail, ...data } =
          values;

        const contact = {
          firstName: contactFirstName,
          lastName: contactLastName,
          email: contactEmail,
        };

        try {
          await props.handleSubmit({
            ...data,
            contact: {
              ...contact,
              dateOfBirth: {
                day: contactDateOfBirth.daysInMonth(),
                month: contactDateOfBirth.month() + 1,
                year: contactDateOfBirth.year(),
              },
            },
          });
        } catch (e) {
          console.log(e);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const prefixSelector = (
    <Form.Item name="prefix" initialValue="41">
      <Select style={{ width: 70 }}>
        <Select.Option value="1">+1</Select.Option>
        <Select.Option value="31">+31</Select.Option>
        <Select.Option value="32">+32</Select.Option>
        <Select.Option value="33">+33</Select.Option>
        <Select.Option value="34">+34</Select.Option>
        <Select.Option value="39">+39</Select.Option>
        <Select.Option value="41">+41</Select.Option>
        <Select.Option value="43">+43</Select.Option>
        <Select.Option value="44">+44</Select.Option>
        <Select.Option value="49">+49</Select.Option>
      </Select>
    </Form.Item>
  );

  const identifierTypeSelector = (
    <Form.Item name="identifierType" initialValue="ide">
      <Select style={{ width: 70 }}>
        <Select.Option value="ide">IDE</Select.Option>
        <Select.Option value="nif">NIF</Select.Option>
        <Select.Option value="siret">SIRET</Select.Option>
      </Select>
    </Form.Item>
  );

  /**
   * TODO: Handle error state
   */
  return (
    <Form onFinish={handleSubmit} form={form}>
      <Row gutter={32}>
        <Col span={12}>
          <h3>Information</h3>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input a gallery name',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item label="Description" name="description">
            <TextArea />
          </Form.Item>

          <Form.Item label="Country" name="country">
            <CountrySelect onChange={setCountry} />
          </Form.Item>

          <Form.Item label="City" name="city">
            <CitySelect country={country} />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input an email!',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              {
                required: true,
                message: 'Please input a phone number',
              },
            ]}>
            <Input addonBefore={prefixSelector} />
          </Form.Item>

          <Form.Item label="Unique identifier (NIF/IDE)" name="identifier">
            <Input addonBefore={identifierTypeSelector} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <h3>Main Contact</h3>

          <Form.Item
            label="First name"
            name="contactFirstName"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Last name"
            name="contactLastName"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="contactEmail"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Date of birth"
            name="contactDateOfBirth"
            rules={[
              {
                required: true,
              },
            ]}>
            <DatePicker />
          </Form.Item>

          <h3>Address</h3>

          <Form.Item
            label="Line 1"
            name="line1"
            rules={[
              {
                required: true,
                message: 'Please input an address',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item label="Line 2" name="line2">
            <Input />
          </Form.Item>

          <Form.Item
            label="Postal Code"
            name="postalCode"
            rules={[
              {
                required: true,
                message: 'Please input a postal code',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="City"
            name="cityAddress"
            rules={[
              {
                required: true,
                message: 'Please input a city',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item label="State" name="state">
            <Input />
          </Form.Item>

          <Form.Item label="Country" name="countryAddress">
            <CountrySelect />
          </Form.Item>
        </Col>
      </Row>

      <Row justify={'end'}>
        <Button type={'primary'} htmlType="submit" loading={loading}>
          Save
        </Button>
      </Row>
    </Form>
  );
};
