import React, { useState } from 'react';
import { message, Modal, Upload } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

import { useOrder } from '../hooks/useOrder';

interface UploadInvoiceModalProps {
  orderId: string;
  onClose: () => void;
  onSuccess: () => void;
}

export const UploadCertificateModal: React.FC<UploadInvoiceModalProps> = ({
  orderId,
  onClose,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const { addCertificates } = useOrder();

  const handleUpload = (options: any) => {
    if (loading) {
      return;
    }

    setLoading(true);

    // Request upload
    addCertificates(orderId)
      .then(({ data }) => {
        const { orderAddCertificate } = data;

        return axios.put(orderAddCertificate.signedUrl, options.file);
      })
      .then(() => {
        message.success('Certificate uploaded!');
        onClose();
      })
      .catch(() => {
        message.error('Could not upload certificate!');
        setLoading(false);
      });
    // Upload to aws
  };

  return (
    <Modal title={'Upload certificate'} visible={true} onCancel={onClose}>
      <Upload.Dragger
        showUploadList={false}
        customRequest={handleUpload}
        accept={'application/pdf'}
        disabled={loading}>
        {!loading ? (
          <>
            <UploadOutlined />
            <br />
            Upload a certificate
          </>
        ) : (
          <LoadingOutlined />
        )}
      </Upload.Dragger>
    </Modal>
  );
};
