import React from 'react';
import styled from 'styled-components';
import { Label as DefaultLabel } from './Label';

const Container = styled(DefaultLabel)`
  position: relative;
  margin-left: 2rem;
  margin-bottom: 0;
  user-select: none;
`;

const Label = styled.p`
  &:before {
    position: absolute;
    top: 0.4rem;
    left: -2rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: '';
    background-color: #fff;
    border: #adb5bd solid 1px;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    border-radius: 0.25rem;
  }

  &:after {
    position: absolute;
    top: 0.4rem;
    left: -2rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
`;

const Input = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked + p {
    &:before {
      border-color: #626ed4;
      background-color: #626ed4;
    }
  }
`;

interface CheckBoxProps {
  label?: string;
}

export const CheckBox: React.FunctionComponent<CheckBoxProps> = (props) => (
  <Container>
    <Input type="checkbox" />
    <Label>{props.label}</Label>
  </Container>
);
