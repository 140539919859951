import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { encode } from 'blurhash';
import {api} from '../../api/api';

interface CoverPictureProps {
  src: string;
  onSuccess?: (asset: any) => void;
}

const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (...args) => reject(args);
    img.src = src;
  });
};

const getImageData = (image: HTMLImageElement) => {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.drawImage(image, 0, 0);
  return context.getImageData(0, 0, image.width, image.height);
};

const encodeImage = ({ data, width, height }:  ImageData) => {
  return encode(data, width, height, 4, 4)
};

export const CoverPicture: React.FC<CoverPictureProps> = ({
  src,
  onSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const openFilePicker = () => {
    if(!inputRef || !inputRef.current) {
      return;
    }

    inputRef.current.click();
  };

  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isLoading || !event || !event.target || !event.target.files) {
      return;
    }

    setIsLoading(true);

    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const url = URL.createObjectURL(file);
    const image = await loadImage(url);
    const imageData = getImageData(image);
    const blurHash = encodeImage(imageData);

    api
      .post('/assets', {
        name: file.name,
        size: file.size,
        fileType: file.type,
        type: 'file',
        blurHash,
        meta: {
          height: image.height,
          width: image.width,
        }
      })
      .then(async ({ data }) => {
        await axios.put(data.links.upload, file);
        return data;
      })
      .then(asset => {
        if (onSuccess) {
          onSuccess(asset);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <Cover src={src}/>
      <UpdateIcon onClick={openFilePicker}/>

      <FileInput
        ref={inputRef}
        type="file"
        onChange={handleFile}
      />

      {isLoading && (
        <LoadingOverlay/>
      )}
    </Container>
  );
};

const Container = styled.div`
position: relative;
height: 24rem;
margin-bottom: 2.4rem;
`;

const Cover = styled.img`
height: 100%;
width: 100%;
object-fit: cover;
border-radius: .8rem;
`;

const UpdateIcon = styled.div`
position: absolute;
height: 2.4rem;
width: 2.4rem;
right: 1.6rem;
top: 1.6rem;
background-color: grey;
border-radius: .4rem;

&:hover {
  cursor: pointer;
}
`;

const FileInput = styled.input`
opacity: 0;
height: 0;
width: 0;
`;

const LoadingOverlay = styled.div`
position: absolute;
top: 0;
bottom: 0;
right: 0;
left: 0;
background: rgba(0, 0, 0, .75);
`;
