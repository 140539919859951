import React, { FormEvent, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import { api } from '../../api/api';


interface Props {
    visible: boolean;
    onClose: any;
    onOk: any;
    collectionId: string | undefined;
    onSuccess?: () => void;
}

export const ArtworkAppendForm: React.FC<Props> = (props) => {

    //TODO: handle the case collectionId: undefined

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onOk = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        
        form.validateFields()
          .then(async (values: any) => {
            const { artworkId } = values;
            const res = await api.post(`/collections/${props.collectionId}/artworks`, {artworkId});
            
            form.resetFields();
            setLoading(false);
            if(props.onSuccess)
              props.onSuccess();
            props.onClose();
          })
          .catch((err) => {
            
            let errDesc: string = err.response?.data?.message ?? err.message;
            setErrorMessage("An error occurred: " + errDesc);
            console.log(err);
            setLoading(false);
          });
      };

    return (
        <Modal
          title={`Add artwork`}
          open={props.visible}
          onOk={onOk}
          onCancel={props.onClose}
          confirmLoading={loading}>
            
            <Form form={form}>
              
                <Form.Item
                    label="Artwork Id"
                    name={'artworkId'}
                    rules={[
                      {
                        required: true,
                        message: 'Please input the artwork id',
                      },
                    ]}>
                    <Input />
                </Form.Item>
            </Form>

            <p style={{color: '#FFBF00'}}>{errorMessage}</p>
        </Modal>
    )
}