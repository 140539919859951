import gql from 'graphql-tag';

export const artistFetch = gql`
    query ArtistFetch($id: String!) {
        artist (
            id: $id
        ) {
            _id
            firstName
            lastName
            nickName
            email
            country
            city
            gender
            bio
            acceptCommission
            status
            score
            isIndependent
            
            dateOfBirth {
                year
                day
                month
            }
            
            phone {
                prefix
                number
            }
            
            identifier {
                type
                value
            }
            
            profilePicture
            coverPicture
            featured
            
            preferences {
                language
                currency
            }
            
            stories {
                _id
                type
                author
                caption
                order
                content
            }
            instagram
            facebook
            twitter
            
            galleries {
                _id
                
                gallery {
                    _id
                    name
                    logo
                    city
                    country
                }
            }
            
            shippingAddress {
                line1
                line2
                postalCode
                city
                country
                state
            }
            
            translation {
                language
                bio
            }
            
            vendorId

            vendor {
                _id
                preferences {
                    language
                    currency
                }
                email
                name
            }

            artworks {
                _id
                featured
                name
                description
                category
                style
                subject
                technique
                status
                year
                format

                artist {
                    _id
                    nickName
                }

                price {
                    value
                    currency
                }

                dimension {
                    width
                    height
                }
            }
        }
    }
`;

export const artistFetchAll = gql`
    query ArtistFetchAll {
        artists {
            _id
            firstName
            lastName
            nickName
            email
            city
            country
            phone {
                prefix
                number
            }
            featured
            status
            
            artworks {
                _id
            }
        }
    }
`;
