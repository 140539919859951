import React, { FormEvent, useState } from 'react';
import { Form, Input, message, Modal, Select } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { StoryUpdate, StoryUpdateVariables } from '../__generated__/StoryUpdate';
import { storyUpdate } from '../story.mutations';
import {
  StoryFetchAll,
  StoryFetchAll_stories,
  StoryFetchAllVariables,
} from '../__generated__/StoryFetchAll';
import { storyFetchAll } from '../story.queries';

interface Props {
  story: StoryFetchAll_stories;
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const StoryUpdateModal: React.FunctionComponent<Props> = (props) => {
  const { story, onOk, ...modalProps } = props;
  const [type, setType] = useState(story.type);
  const [loading, setLoading] = useState(false);
  const [mutate] = useMutation<StoryUpdate, StoryUpdateVariables>(storyUpdate);

  const [form] = Form.useForm();

  const handleSubmit = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        const { cover, ...data } = values;

        return mutate({
          variables: {
            input: {
              storyId: story._id,
              ...data,
            },
          },
          update: (cache, { data }) => {
            const cacheData = cache.readQuery<StoryFetchAll, StoryFetchAllVariables>({
              query: storyFetchAll,
              variables: {
                filter: {
                  ownerId: {
                    eq: story.ownerId,
                  },
                },
              },
            });

            if (!cacheData || !data) {
              return;
            }

            const { stories } = cacheData;

            const { storyUpdate } = data;

            cache.writeQuery({
              query: storyFetchAll,
              variables: {
                filter: {
                  ownerId: {
                    eq: story.ownerId,
                  },
                },
              },
              data: {
                stories: stories.map((el) => {
                  if (el._id !== story._id) {
                    return el;
                  }

                  return {
                    ...el,
                    ...storyUpdate,
                  };
                }),
              },
            });
          },
        })
          .then(() => {
            message.success('Story updated!');
            onOk();
          })
          .catch((e) => {
            console.log(e);
            message.error('Unable to update story!');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
        return console.log('Received values of form: ', err);
      });
  };

  return (
    <Modal onOk={handleSubmit} {...modalProps} destroyOnClose={true} confirmLoading={loading}>
      <Form layout="vertical" form={form}>
        <Form.Item
          label={'Type'}
          name="type"
          initialValue={story.type}
          rules={[
            {
              required: true,
              message: 'Please input an story type',
            },
          ]}>
          <Select onChange={setType} defaultActiveFirstOption>
            <Select.Option value={'quote'}>Quote</Select.Option>
            <Select.Option value={'image'}>Image</Select.Option>
            <Select.Option value={'video'}>Video</Select.Option>
          </Select>
        </Form.Item>

        {type === 'quote' && (
          <>
            <Form.Item label={'Content'} name="content" initialValue={story.content}>
              <Input />
            </Form.Item>

            <Form.Item label={'Author'} name="author" initialValue={story.author}>
              <Input />
            </Form.Item>

            <Form.Item label={'Date'} name="date" initialValue={story.date}>
              <Input />
            </Form.Item>
          </>
        )}

        {type === 'video' && (
          <>
            <Form.Item label={'Caption'} name="caption" initialValue={story.caption}>
              <Input />
            </Form.Item>

            <Form.Item label={'Source'} name="src" initialValue={story.src}>
              <Input />
            </Form.Item>
          </>
        )}

        {type === 'image' && (
          <>
            <Form.Item label="Caption" name="caption" initialValue={story.caption}>
              <Input />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};
