import React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { Select } from 'antd';
import { Artist } from '../../artist/artist';
import { artistFetchAll } from '../../artist/artist.queries';

const { Option } = Select;

interface Data {
  artists: Artist[];
}

interface Props {
  value?: string;
  onChange?: (data: string) => void;
}

export const ArtistSelect: React.FunctionComponent<Props> = (props) => (
  <Query query={artistFetchAll}>
    {({loading, error, data}: QueryResult<Data>) => {
      if (error) return null;

      return (
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select a person"
          optionFilterProp="children"
          loading={loading}
          disabled={!loading && !data}
          {...props}
        >
          {data && data.artists.map(artist => (
            <Option key={artist._id} value={artist._id}>{artist.nickName}</Option>
          ))}
        </Select>
      )
    }}
  </Query>
);
