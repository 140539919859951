import gql from 'graphql-tag';

export const exchangeRatesUpdate = gql`
  mutation ExchangeRatesUpdate($input: ExchangeRateUpdateInput!) {
    exchangeRateUpdate(input: $input) {
      _id
      value
      sourceCurrency
      targetCurrency
      validFrom
      validTo
    }
  }
`;
