import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { CreateOrderForm } from '../components/CreateOrderForm';
import { Back } from './OrderDetailPage';
import styled from 'styled-components';

import { useAppDispatch } from '../../app/hooks';
import { createOrder } from '../../features/order/store/order.slice';
import { ArrowLeftOutlined } from '@ant-design/icons';

export const OrderCreatePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: any) => {
    if (!values.billingAddress) {
      values.billingAddress = values.shippingAddress;
    }

    try {
      const order = await dispatch(createOrder(values)).unwrap();
      navigate(`/orders/detail/${order._id}`);
    } catch (e) {}
  };

  return (
    <Layout.Content>
      <Back>
        <ArrowLeftOutlined onClick={() => navigate(-1)} style={{ marginRight: '.8rem' }} /> Orders
      </Back>

      <PageTitle>Create order</PageTitle>

      <CreateOrderForm onSubmit={handleSubmit} />
    </Layout.Content>
  );
};

const PageTitle = styled.h1`
  font-size: 2.4rem;
  font-family: Quicksand, 'sans-serif';
  color: #8198aa;
  font-weight: 700;
  margin-bottom: 0;

  strong {
    color: #3e475e;
  }
`;
