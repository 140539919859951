import React from 'react';
import styled from 'styled-components';
import { InputType } from './input-type';

export const FormGroup = styled.div`
  margin-bottom: 1.6rem;
`;

const Label = styled.label`
  display: inline-block;
  font-weight: 500;
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
  color: #5b626b;
`;

const Input = styled.input`
  display: block;
  font-size: 1.4rem;
  width: 100%;
  padding: 0.6rem 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;

  &:focus {
    border-color: #626ed4;
    box-shadow: none;
  }
`;

interface TextInputProps {
  label?: string;
  placeholder?: string;
  type?: InputType;
  value: string;
  onChange: ({ target }: { target: any }) => void;
}

export const TextInput: React.FunctionComponent<TextInputProps> = (props) => (
  <FormGroup>
    {props.label && <Label>{props.label}</Label>}
    <Input
      type={props.type || 'text'}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
    />
  </FormGroup>
);
