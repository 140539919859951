import {useMutation} from '@apollo/react-hooks';
import {Card, Select} from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import {CardTitle} from '../../components/cards/Card';
import {ArtistPreferenceUpdate, ArtistPreferenceUpdateVariables} from '../__generated__/ArtistPreferenceUpdate';
import {artistPreferencesUpdate} from '../artist.mutations';
import { ArtistFetch_artist } from '../__generated__/ArtistFetch';
import { Artist } from '../artist';

type ArtistPreferencesProps = {
  artist: ArtistFetch_artist;
  preferences?: {
    language: string | null;
    currency: string | null;
  } | null;
}


const ArtistPreferenceWarning: React.FC<{ artist: ArtistFetch_artist }> = (props) => {
  
  var artist = props.artist;
  if(!(artist.preferences && artist.preferences.language)){
    return <p><WarningOutlined style={{color: '#FFBF00'}}></WarningOutlined> Artist language preferences not set. Update to solve the issue.</p>
  }

  return null;
}

const VendorPreferenceWarning: React.FC<{ artist: ArtistFetch_artist }> = (props) => {
  
  const artistFetch = props.artist;

  if(!artistFetch.isIndependent)
    return null;

  const artist = (artistFetch as any);

  if(!artist?.vendor?.preferences?.language){
    return <p><WarningOutlined style={{color: '#FFBF00'}}></WarningOutlined> Vendor language preferences not set. Update to solve the issue.</p>
  }

  return null;
}

export const ArtistPreferences: React.FC<ArtistPreferencesProps> = ({
  artist,
  preferences = {
    language: 'en',
    currency: 'chf',
  },
}) => {
  const [mutate] = useMutation<ArtistPreferenceUpdate, ArtistPreferenceUpdateVariables>(artistPreferencesUpdate);

  const onUpdateLanguage = async (value: string) => {
    await mutate({
      variables: {
        input: {
          artistId: artist._id,
          language: value,
        }
      }
    });
  };

  const onUpdateCurrency = async (value: string) => {
    await mutate({
      variables: {
        input: {
          artistId: artist._id,
          currency: value,
        }
      }
    });
  };

  return (
    <Card style={{marginTop: '1.6rem'}}>
      <CardTitle>Preferences</CardTitle>

      <ArtistPreferenceWarning artist={artist}></ArtistPreferenceWarning>
      
      <VendorPreferenceWarning artist={artist}></VendorPreferenceWarning>

      <p style={{ paddingTop: '1.6rem' }}>
        Which language should be used in communication with this vendor.
      </p>

      <ToggleContainer>
        Language
        <Select
          defaultValue={preferences?.language || 'en'}
          onChange={onUpdateLanguage}
        >
          <Select.Option value={'en'}>English</Select.Option>
          <Select.Option value={'fr'}>French</Select.Option>
        </Select>
      </ToggleContainer>

      <p style={{ paddingTop: '1.6rem' }}>
        Which currency should be used by default for this vendor.
      </p>

      <ToggleContainer>
        Currency
        <Select
          defaultValue={preferences?.currency || 'chf'}
          onChange={onUpdateCurrency}
        >
          <Select.Option value={'chf'}>CHF</Select.Option>
          <Select.Option value={'eur'}>EUR</Select.Option>
          <Select.Option value={'gbp'}>GBP</Select.Option>
          <Select.Option value={'usd'}>USD</Select.Option>
        </Select>
      </ToggleContainer>
    </Card>
  );
};

// TODO Refactor
const ToggleContainer = styled.div`
width: 100%;
padding: 1.6rem;
color: #1890ff;
display: flex;
flex-direction: row;
font-family: "Quicksand", 'sans-serif';
font-weight: 700;
font-size: 1.6rem;
justify-content: space-between;
background-color: rgba(24, 144, 255, .05);
border-radius: .4rem;
align-items: center;
`;
