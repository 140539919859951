import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: 0 0 2.3rem 0 rgba(236, 236, 241, 0.44);
  background-color: #fff;
  background-clip: border-box;
`;

export const Card: React.FunctionComponent = (props) => <Container>{props.children}</Container>;

export const CardTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  font-family: Quicksand, 'sans-serif';
  color: #3e475e;
`;
