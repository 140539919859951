import React from 'react';
import { useMutation } from 'react-apollo';
import {FeaturedSwitch} from '../../components/FeaturedSwitch';
import {ArtistFeaturedStatus, ArtistFeaturedStatusVariables} from '../__generated__/ArtistFeaturedStatus';
import {artistFeaturedStatus} from '../artist.mutations';
import {ArtistFetch} from '../__generated__/ArtistFetch';
import {artistFetch} from '../artist.queries';

interface Props {
  artistId: string;
  featured: boolean;
}

export const ArtistFeaturedSwitch: React.FunctionComponent<Props> = ({ artistId, featured}) => {
  const [mutate] = useMutation<ArtistFeaturedStatus, ArtistFeaturedStatusVariables>(artistFeaturedStatus);

  const handleChange = (status: boolean) => {
    return mutate({
      variables: {
        input: {
          artistId,
          status,
        },
      },
      update: (cache) => {
        const data = cache.readQuery<ArtistFetch>({
          query: artistFetch,
          variables: {
            id: artistId
          }
        });

        if (!data) {
          return;
        }

        const { artist } = data;

        cache.writeQuery({
          query: artistFetch,
          variables: {
            id: artistId
          },
          data: {
            artist: {
              ...artist,
              featured: status,
            }
          }
        })
      },
    });
  };

  return (
    <FeaturedSwitch
      featured={featured}
      handleChange={handleChange}
    />
  )
};