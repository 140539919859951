import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Button, Card, Col, Descriptions, Empty, Layout, message, PageHeader, Row } from 'antd';

import styled from 'styled-components';
import { VendorDescription } from '../../vendor/components/VendorDescription';
import { VendorPreferences } from '../../vendor/components/VendorPreferences';
import { GalleryArtists } from '../components/GalleryArtists';
import { ArtworkListCard } from '../../components/cards/ArtworkListCard';
import { GalleryAvatar } from '../components/GalleryAvatar';
import { GalleryScore } from '../components/GalleryScore';
import { GalleryUpdateForm } from '../components/GalleryUpdateForm';
import { galleryFetch } from '../gallery.queries';
import { SocialLinks } from '../../components/SocialLinks';
import { GalleryFetch } from '../__generated__/GalleryFetch';
import { GalleryFeaturedSwitch } from '../components/GalleryFeaturedSwitch';
import { EventList } from '../../event/components/EventList';
import { useModal } from '../../components/hooks/useModal';
import { StoryList } from '../../story/components/StoryList';
import { GalleryLogo } from '../components/GalleryLogo';
import { useGallery } from '../hooks/use-gallery';
import { CardTitle } from '../../components/cards/Card';

export const GalleryDetailPage = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const { visible, openModal, closeModal } = useModal();
  const { accept, reject, activate, deactivate } = useGallery();

  const { loading, error, data } = useQuery<GalleryFetch>(galleryFetch, {
    variables: {
      id,
    },
  });

  if (loading) return null;
  if (error || !data) return <Empty />;

  const { gallery } = data;

  if (!gallery) {
    return <Empty />;
  }

  const handleAccept = () => {
    accept(id)
      .then(() => {
        message.success('Gallery has been accepted.');
      })
      .catch(() => {
        message.error('We encountered an error trying to accept this gallery.');
      });
  };

  const handleReject = () => {
    reject(id)
      .then(() => {
        message.success('Gallery has been rejected.');
      })
      .catch(() => {
        message.error('We encountered an error trying to reject this gallery.');
      });
  };

  const handleActivate = () => {
    activate(id)
      .then(() => {
        message.success('Gallery has been activated.');
      })
      .catch(() => {
        message.error('We encountered an error trying to activate this gallery.');
      });
  };

  const handleDeactivate = () => {
    deactivate(id)
      .then(() => {
        message.success('Gallery has been deactivated.');
      })
      .catch(() => {
        message.error('We encountered an error trying to deactivate this gallery.');
      });
  };

  return (
    <Layout.Content>
      <PageHeader
        onBack={() => navigate(-1)}
        title={'Galleries'}
        subTitle={gallery.name}
        extra={[
          gallery.status === 'pending' ? (
            <Button type={'primary'} onClick={handleAccept}>
              accept
            </Button>
          ) : null,
          gallery.status === 'pending' ? (
            <Button type={'default'} danger onClick={handleReject}>
              reject
            </Button>
          ) : null,
          gallery.status === 'active' ? (
            <Button type={'default'} danger onClick={handleDeactivate}>
              DEACTIVATE
            </Button>
          ) : null,
          gallery.status === 'inactive' ? (
            <Button
              type={'primary'}
              onClick={handleActivate}
              style={{
                backgroundColor: '#4BCA81',
                border: 'none',
                fontSize: '1.4rem',
              }}>
              ACTIVATE
            </Button>
          ) : null,
          <Button onClick={openModal}>update</Button>,
        ]}>
        <Descriptions>
          <Descriptions.Item>
            <GalleryAvatar galleryId={gallery._id} src={gallery.profilePicture || ''} />
          </Descriptions.Item>

          <Descriptions.Item>
            <GalleryLogo galleryId={gallery._id} src={gallery.logo || ''} />
          </Descriptions.Item>

          <Descriptions.Item label={'Address'}>
            {gallery.address !== null && (
              <address>
                {gallery.address.line1} <br />
                {gallery.address.line2 && (
                  <span>
                    {gallery.address.line2} <br />
                  </span>
                )}
                {gallery.address.postalCode} {gallery.address.city}
                <br />
              </address>
            )}
          </Descriptions.Item>

          <Descriptions.Item label={'Country'}>{gallery.country}</Descriptions.Item>
          <Descriptions.Item label={'City'}>{gallery.city}</Descriptions.Item>
        </Descriptions>
      </PageHeader>

      <Row>
        <Col lg={16}>
          <VendorDescription
            vendorId={gallery._id}
            description={gallery.description}
            translations={gallery.translation}
          />

          <StoryList ownerId={gallery._id} />

          <EventList ownerId={gallery._id} />
        </Col>

        <Col lg={8}>
          <Card>
            <CardTitle>Featured</CardTitle>
            <p style={{ paddingTop: '1.6rem' }}>Set gallery as featured on Urbaneez home page</p>

            <ToggleContainer>
              Featured
              <GalleryFeaturedSwitch galleryId={gallery._id} featured={gallery.featured} />
            </ToggleContainer>
          </Card>

          <Card style={{ marginTop: '1.6rem' }}>
            <CardTitle>Stripe</CardTitle>
            <p style={{ paddingTop: '1.6rem' }}>
              Vendor has to accept stripe conditions to start selling on the platform
            </p>

            <ToggleContainer>
              Conditions link
              <Button
                style={{ fontFamily: 'Quicksand' }}
                size={'small'}
                type={'primary'}
                onClick={() =>
                  navigator.clipboard.writeText(
                    `https://urbaneez.art/join/validation?vendor=${gallery.vendorId}`,
                  )
                }>
                Copy Link
              </Button>
            </ToggleContainer>
          </Card>

          <GalleryScore galleryId={gallery._id} score={gallery.score || 0} />

          <VendorPreferences galleryId={gallery._id} preferences={gallery.preferences} />

          <Card style={{ marginTop: '1.6rem' }}>
            <CardTitle>Contact</CardTitle>

            <Descriptions column={{ md: 1 }} style={{ marginTop: '1.6rem' }}>
              <Descriptions.Item label={'email'}>{gallery.email}</Descriptions.Item>
              <Descriptions.Item label={'phone'}>
                {gallery.phone ? `${gallery.phone.prefix}/${gallery.phone.number}` : '-'}
              </Descriptions.Item>
              <Descriptions.Item label={'uid'}>
                {gallery.identifier
                  ? `${gallery.identifier.type || '-'}/${gallery.identifier.value || '-'}`
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label={'opening hours'}>
                {gallery.openingHours
                  ? gallery.openingHours.split('\n').map((el) => (
                      <span>
                        {el}
                        <br />
                      </span>
                    ))
                  : '-'}
              </Descriptions.Item>
            </Descriptions>
          </Card>

          <SocialLinks gallery={gallery} />
        </Col>
      </Row>

      <GalleryArtists galleryId={gallery._id} artists={gallery.artists} />

      <ArtworkListCard artworks={gallery.artworks} />

      <GalleryUpdateForm
        gallery={gallery}
        visible={visible}
        onOk={closeModal}
        onClose={closeModal}
      />
    </Layout.Content>
  );
};

// TODO Refactor
const ToggleContainer = styled.div`
  width: 100%;
  padding: 1.6rem;
  color: #1890ff;
  display: flex;
  flex-direction: row;
  font-family: 'Quicksand', 'sans-serif';
  font-weight: 700;
  font-size: 1.6rem;
  justify-content: space-between;
  background-color: rgba(24, 144, 255, 0.05);
  border-radius: 0.4rem;
`;
