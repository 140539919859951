import { Badge, Divider, Table, Tag } from 'antd';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  ArtworkFetchAll_artworks_artworks_artist,
  ArtworkFetchAll_artworks_artworks,
  ArtworkFetchAll_artworks_artworks_price,
} from '../__generated__/ArtworkFetchAll';
import { PaginationProps } from 'antd/lib/pagination';

interface Props {
  loading?: boolean;
  artworks: ArtworkFetchAll_artworks_artworks[];
  onDelete: (artworkId: string) => void;
  pagination?: PaginationProps;
}

export const ArtworkTable: React.FunctionComponent<Props> = ({
  loading,
  artworks,
  onDelete,
  pagination,
}) => {
  const columns = [
    {
      dataIndex: 'featured',
      key: 'featured',
      render: (featured: boolean) => (featured ? <Badge status="processing" /> : null),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: boolean, { dimension }: ArtworkFetchAll_artworks_artworks) => (
        <span>
          {text} - {dimension.width}x{dimension.height}
        </span>
      ),
    },
    {
      title: 'Artist',
      dataIndex: 'artist',
      key: 'blaze',
      render: (artist: ArtworkFetchAll_artworks_artworks_artist) => (
        <Link to={`/artists/detail/${artist._id}`}>{artist.nickName}</Link>
      ),
    },
    {
      title: 'Format',
      dataIndex: 'format',
      key: 'format',
      filters: [
        {
          text: 'Small',
          value: 'small',
        },
        {
          text: 'Large',
          value: 'large',
        },
        {
          text: 'Extra large',
          value: 'extra_large',
        },
      ],
      filterMultiple: true,
      onFilter: (value: string, record: ArtworkFetchAll_artworks_artworks) =>
        record.format === value,
      render: (text: string) => <Tag>{text}</Tag>,
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      sorter: (a: ArtworkFetchAll_artworks_artworks, b: ArtworkFetchAll_artworks_artworks) =>
        parseInt(a.year) - parseInt(b.year),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text: string) => <Tag>{text}</Tag>,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price: ArtworkFetchAll_artworks_artworks_price) => (
        <FormattedNumber value={price.value} currency={price.currency} style={'currency'} />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Pending',
          value: 'pending',
        },
        {
          text: 'Available',
          value: 'available',
        },
      ],
      filterMultiple: false,
      onFilter: (value: string, record: ArtworkFetchAll_artworks_artworks) =>
        record.status === value,
      render: (status: string) => (
        <Tag color={status === 'available' ? 'green' : status === 'rejected' ? 'red' : 'blue'}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: ArtworkFetchAll_artworks_artworks) => (
        <span>
          <Link to={`/artworks/detail/${record._id}`}>
            <a>Detail</a>
          </Link>

          <Divider type="vertical" />

          <a onClick={() => onDelete(record._id)} color="magenta">
            Delete
          </a>
        </span>
      ),
    },
  ];

  return (
    <Table
      columns={columns as any}
      loading={loading}
      dataSource={artworks}
      pagination={pagination}
    />
  );
};
