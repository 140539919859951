import { Form, Input, message, Modal, Select } from 'antd';
import React, { FormEvent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { CountrySelect } from '../../components/select/CountrySelect';
import { galleryUpdate } from '../gallery.mutations';
import { galleryFetch } from '../gallery.queries';
import { GalleryFetch_gallery } from '../__generated__/GalleryFetch';

interface Props {
  visible: boolean;
  onClose: any;
  onOk: any;
  gallery: GalleryFetch_gallery;
}

export const GalleryUpdateForm: React.FC<Props> = ({ visible, onClose, gallery, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState('');
  const [mutate] = useMutation(galleryUpdate);

  const [form] = Form.useForm();

  const prefixSelector = (
    <Form.Item name="prefix" initialValue={gallery.phone ? gallery.phone.prefix : '41'}>
      <Select style={{ width: 70 }}>
        <Select.Option value="1">+1</Select.Option>
        <Select.Option value="31">+31</Select.Option>
        <Select.Option value="32">+32</Select.Option>
        <Select.Option value="33">+33</Select.Option>
        <Select.Option value="34">+34</Select.Option>
        <Select.Option value="39">+39</Select.Option>
        <Select.Option value="41">+41</Select.Option>
        <Select.Option value="43">+43</Select.Option>
        <Select.Option value="44">+44</Select.Option>
        <Select.Option value="49">+49</Select.Option>
      </Select>
    </Form.Item>
  );

  const identifierTypeSelector = (
    <Form.Item
      name="identifierType"
      initialValue={gallery.identifier ? gallery.identifier.type : 'ide'}>
      <Select style={{ width: 70 }}>
        <Select.Option value="ide">IDE</Select.Option>
        <Select.Option value="nif">NIF</Select.Option>
      </Select>
    </Form.Item>
  );

  const onOk = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    form
      .validateFields()
      .then(async (values: any) => {
        const {
          line1,
          line2,
          postalCode,
          cityAddress,
          state,
          countryAddress,
          prefix,
          phone,
          identifier,
          identifierType,
          ...input
        } = values;

        const address = {
          line1,
          line2,
          postalCode,
          city: cityAddress,
          state,
          country: countryAddress,
        };

        try {
          await mutate({
            variables: {
              input: {
                galleryId: gallery._id,
                address,
                phone: {
                  prefix,
                  number: phone,
                },
                identifier: {
                  type: identifierType,
                  value: identifier,
                },
                ...input,
              },
            },
            update: (cache, { data }) => {
              const options = {
                query: galleryFetch,
                variables: {
                  id: gallery._id,
                },
              };

              cache.writeQuery({
                ...options,
                data: {
                  gallery: data.galleryUpdate,
                },
              });
            },
          });

          message.success('Gallery updated');
          props.onOk();
        } catch (e) {
          console.log(e);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Basic Modal"
      visible={visible}
      onOk={onOk}
      onCancel={onClose}
      confirmLoading={loading}>
      <Form form={form}>
        <Form.Item
          label="Name"
          name="name"
          initialValue={gallery.name}
          rules={[
            {
              required: true,
              message: 'Please input a gallery name',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Contact Name"
          name="contactName"
          initialValue={gallery.contactName}
          rules={[
            {
              required: true,
              message: 'Please input a contact name',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item label="Country" name="country" initialValue={gallery.country}>
          <CountrySelect onChange={setCountry} />
        </Form.Item>

        <Form.Item label="City" name="city" initialValue={gallery.city}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          initialValue={gallery.email}
          rules={[
            {
              required: true,
              message: 'Please input an email!',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
          initialValue={gallery.phone ? gallery.phone.number : ''}
          rules={[
            {
              required: true,
              message: 'Please input a phone number',
            },
          ]}>
          <Input addonBefore={prefixSelector} />
        </Form.Item>

        <h3>Address</h3>

        <Form.Item
          label="Line 1"
          name="line1"
          initialValue={gallery.address ? gallery.address.line1 : ''}
          rules={[
            {
              required: true,
              message: 'Please input an address',
            },
          ]}
          style={{ marginBottom: '.8rem' }}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Line 2"
          name="line2"
          initialValue={gallery.address ? gallery.address.line2 : ''}
          style={{ marginBottom: '.8rem' }}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Postal Code"
          name="postalCode"
          initialValue={gallery.address ? gallery.address.postalCode : ''}
          rules={[
            {
              required: true,
              message: 'Please input a postal code',
            },
          ]}
          style={{ marginBottom: '.8rem' }}>
          <Input />
        </Form.Item>

        <Form.Item
          label="City"
          name="cityAddress"
          initialValue={gallery.address ? gallery.address.city : ''}
          rules={[
            {
              required: true,
              message: 'Please input a city',
            },
          ]}
          style={{ marginBottom: '.8rem' }}>
          <Input />
        </Form.Item>

        <Form.Item
          label="State"
          name="state"
          initialValue={gallery.address ? gallery.address.state : ''}
          style={{ marginBottom: '.8rem' }}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Country"
          name="countryAddress"
          initialValue={gallery.address ? gallery.address.country : ''}
          style={{ marginBottom: '.8rem' }}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Unique identifier (NIF/IDE)"
          name="identifier"
          initialValue={gallery.identifier ? gallery.identifier.value : ''}>
          <Input addonBefore={identifierTypeSelector} />
        </Form.Item>

        <Form.Item
          label="Opening hours"
          name="openingHours"
          initialValue={gallery.openingHours ? gallery.openingHours : ''}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
