import React, { useState } from 'react';
import { Button, Card, Checkbox, Col, Form, Input, Row, Select, Table } from 'antd';
import { CustomerSelect } from '../../features/customer/components/customer-select';
import { ArtworkSearchModal } from '../../features/artworks/components/artwork-search-modal';
import { useModal } from '../../components/hooks/useModal';
import { Artwork } from '../../features/artworks/models/artwork';
import { ColumnProps } from 'antd/lib/table';
import { CountrySelect } from '../../components/select/CountrySelect';

interface Props {
  onSubmit: (values: any) => Promise<any>;
}

const columns: ColumnProps<Artwork>[] = [
  {
    key: 'name',
    dataIndex: 'name',
  },
];

export const CreateOrderForm: React.FunctionComponent<Props> = ({ onSubmit }) => {
  const [sameAddress, setSameAddress] = useState(true);
  const { visible, openModal, closeModal } = useModal();
  const [artworks, setArtworks] = useState<Artwork[]>([]);
  const [currency, setCurrency] = useState('EUR');

  const [form] = Form.useForm();

  const handleSubmit = ({ shipping, ...values }: any) => {
    const {
      shippingAddressLine1,
      shippingAddressLine2,
      shippingAddressPostalCode,
      shippingAddressCity,
      shippingAddressState,
      shippingAddressCountry,
      ...data
    } = values;

    onSubmit({
      lineItems: artworks.map((artwork) => artwork._id),
      shippingAddress: {
        line1: shippingAddressLine1,
        line2: shippingAddressLine2,
        postalCode: shippingAddressPostalCode,
        city: shippingAddressCity,
        state: shippingAddressState,
        country: shippingAddressCountry,
      },
      shippingLines: [
        {
          price: parseFloat(shipping),
        },
      ],
      currency,
      ...data,
    });
  };

  const handleAddArtwork = (artwork: Artwork) => {
    const isAlreadyAdded = !!artworks.find((el) => el._id === artwork._id);

    if (isAlreadyAdded) {
      return;
    }

    setArtworks([...artworks, artwork]);
    closeModal();
  };

  return (
    <Form layout={'vertical'} form={form} onFinish={handleSubmit}>
      <Row gutter={32}>
        <Col lg={12} xxl={16}>
          <Card title={'Customer'}>
            <Form.Item name={'customerId'}>
              <CustomerSelect />
            </Form.Item>
          </Card>

          <Card title={'Artworks'} extra={[<Button onClick={openModal}>Add item</Button>]}>
            <Table columns={columns} dataSource={artworks} />
          </Card>
        </Col>

        <Col lg={12} xxl={8}>
          <Card title={'Currency'}>
            <Select value={currency} onChange={setCurrency}>
              <Select.Option value="EUR">EUR</Select.Option>
              <Select.Option value="CHF">CHF</Select.Option>
              <Select.Option value="GBP">GBP</Select.Option>
              <Select.Option value="USD">USD</Select.Option>
            </Select>
          </Card>

          <Card title={'Shipping'}>
            <Form.Item label="Shipping Price" name={'shipping'}>
              <Input />
            </Form.Item>
          </Card>

          <Card title={'Shipping address'}>
            <Form.Item
              label="Line 1"
              name={'shippingAddressLine1'}
              rules={[
                {
                  required: true,
                  message: 'Shipping address is required',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item label="Line 2" name="shippingAddressLine2">
              <Input />
            </Form.Item>

            <Form.Item label="Postal Code" name="shippingAddressPostalCode">
              <Input />
            </Form.Item>

            <Form.Item label="City" name="shippingAddressCity">
              <Input />
            </Form.Item>

            <Form.Item label="State" name="shippingAddressState">
              <Input />
            </Form.Item>

            <Form.Item label="Country" name="shippingAddressCountry">
              <CountrySelect />
            </Form.Item>
          </Card>

          <Card
            style={{ marginTop: '3.2rem' }}
            title={'Billing address'}
            extra={
              <Checkbox
                onChange={(e) => setSameAddress(e.target.checked)}
                checked={sameAddress}
                defaultChecked>
                Use shipping address
              </Checkbox>
            }>
            {!sameAddress && (
              <>
                <Form.Item label="Line 1" name="billingAddress.line1">
                  <Input />
                </Form.Item>

                <Form.Item label="Line 2" name="billingAddress.line2">
                  <Input />
                </Form.Item>

                <Form.Item label="Postal Code" name="billingAddress.postalCode">
                  <Input />
                </Form.Item>

                <Form.Item label="City" name="billingAddress.city">
                  <Input />
                </Form.Item>

                <Form.Item label="State" name="billingAddress.state">
                  <Input />
                </Form.Item>

                <Form.Item label="Country" name="billingAddress.country">
                  <CountrySelect />
                </Form.Item>
              </>
            )}
          </Card>
        </Col>
      </Row>

      <Button htmlType="submit">Save</Button>

      <ArtworkSearchModal onSubmit={handleAddArtwork} onCancel={closeModal} visible={visible} />
    </Form>
  );
};
