import React from 'react';
import { Card } from 'antd';
import { ArrowRightOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Address } from '../../components/Address';
import { InfoLine } from '../../order/components/InfoLine';

interface CustomerDataProps {
  customer: any;
  shippingAddress: any;
  billingAddress: any;
}

export const CustomerData: React.FC<CustomerDataProps> = ({
  customer,
  shippingAddress,
  billingAddress,
}) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardTitle>Customer</CardTitle>

      <div
        style={{
          margin: '1.6rem 0 0',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <CustomerName>
          {customer.firstName} {customer.lastName}
        </CustomerName>

        {customer._id && (
          <ArrowRightOutlined onClick={() => navigate(`/customers/detail/${customer._id}`)} />
        )}
      </div>

      <Divider />

      <CardSubtitle>Contact information</CardSubtitle>
      <InfoLine icon={<MailOutlined />} title={customer.email || ''} />
      <InfoLine icon={<PhoneOutlined />} title={customer?.phone?.number || ''} />

      {shippingAddress && (
        <>
          <Divider />

          <CardSubtitle>Shipping address</CardSubtitle>
          <Address address={shippingAddress} />
        </>
      )}
      {billingAddress && (
        <>
          <Divider />

          <CardSubtitle>Billing address</CardSubtitle>
          <Address address={billingAddress} />
        </>
      )}
    </Card>
  );
};

const CardTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  font-family: Quicksand, 'sans-serif';
  color: #3e475e;
`;

const CardSubtitle = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  font-family: Quicksand, 'sans-serif';
  color: #3e475e;
  padding-bottom: 1.6rem;
`;

const CustomerName = styled.div`
  font-size: 1.6rem;
  font-family: Quicksand, 'sans-serif';
  font-weight: 500;
  color: #3e475e;
`;

const Divider = styled.div`
  height: 1px;
  margin: 1.6rem 0;
  background-color: #f0f5f9;
`;
