import React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Empty, Layout, Table, Tag, Input } from 'antd';
import { Query } from 'react-apollo';

import { Artist } from '../artist';
import { artistFetchAll } from '../artist.queries';
import { PageTitle } from '../../components/layouts/page/PageTitle';
import { ArtistFetchAll_artists_artworks } from '../__generated__/ArtistFetchAll';

const columns = [
  {
    title: 'Blaze',
    dataIndex: 'nickName',
    key: 'nickName',
  },
  {
    title: 'Full Name',
    dataIndex: 'fullName',
    render: (text: any, record: Artist) => (
      <span>
        {record.firstName} {record.lastName}
      </span>
    ),
  },
  {
    title: '# of artworks',
    dataIndex: 'artworks',
    key: 'artworks',
    render: (text: ArtistFetchAll_artists_artworks[]) => <span>{text.length}</span>,
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => (
      <Tag color={status === 'active' ? 'green' : status === 'rejected' ? 'red' : 'blue'}>
        {status}
      </Tag>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: any, record: Artist) => (
      <span>
        <Link to={`/artists/detail/${record._id}`}>
          Detail
        </Link>
      </span>
    ),
  },
];

const rowSelection = {
  onChange: (selectedRowKeys: any, selectedRows: any) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record: any) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
};


export const ArtistListPage = () => {
  const navigate = useNavigate();
  //Search bar variable
  const [searchText, setSearchText] = useState('');

  const filterArtists = (artists: any[]) => {
    return artists.filter(artist =>
      artist.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
      artist.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
      artist.nickName.toLowerCase().includes(searchText.toLowerCase()) ||
      artist.email.toLowerCase().includes(searchText.toLocaleLowerCase())
    );
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <Layout.Content>
      <PageTitle
        title="Artists"
        actions={
          <Button onClick={() => navigate('/artists/create')} title={'new artist'} type={'primary'}>
            new artist
          </Button>
        }
      />

      <Input
        placeholder="Search artists"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 4, width: '50%', minWidth: 200 }}
      />
      <p style={{ marginBottom: 20}}><small>Search in firstName, lastName, nickName, email</small></p>

      <Query query={artistFetchAll}>
        {({ loading, error, data }: any) => {
          if (loading) return null;
          if (error) return error;

          const { artists } = data;
          const filteredArtists = filterArtists(artists);

          if (!artists || artists.length === 0) {
            return <Empty />;
          }

          return <Table columns={columns} dataSource={filteredArtists} rowSelection={rowSelection} />;
        }}
      </Query>
    </Layout.Content>
  );
};
