import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { IntlProvider } from 'react-intl';
import { appLocale } from '../lang/lang.provider';
import { createClient } from '../lib/apollo';
import { LoginPage } from '../auth/pages/LoginPage';
import { Dashboard } from '../components/dashboard/Dashboard';
import { Provider } from 'react-redux';
import { store } from './store';

const App: React.FunctionComponent = () => {
  const currentAppLocale = appLocale['fr'];

  return (
    <React.StrictMode>
      <Provider store={store}>
        <IntlProvider locale={'fr'} messages={currentAppLocale.messages}>
          <ApolloProvider client={createClient()}>
            <Router>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/*" element={<Dashboard />} />
              </Routes>
            </Router>
          </ApolloProvider>
        </IntlProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default App;
