import React from 'react';
import styled from 'styled-components';

interface InfoLineProps {
  title: string;
  icon: any;
}

export const InfoLine: React.FC<InfoLineProps> = ({ title, icon }) => (
  <Container>
    <div style={{ fontSize: '2rem', color: '#96a9c8' }}>{icon}</div>
    <Title>{title}</Title>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

const Title = styled.span`
  font-family: Quicksand, 'sans-serif';
  color: #3e475e;
  font-weight: 500;
  margin-left: 1.6rem;
  font-size: 1.6rem;
`;
