import gql from 'graphql-tag';

export const eventFetchOneById = gql`  
  query EventFetchOneById ($id: String!){
      event(
          id: $id 
      ) {
          _id
          title
          link
          startAt
          endAt
      }
  }
`;

export const eventFetchAll = gql`
    query EventFetchAll ($filter: EventFilterInput) {
        events (
            filter: $filter
        ) {
            _id
            title
            ownerId
            location
            country
            city
            startAt
            endAt
            link
            featured
        }
    }
`;
