import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { CartDetailPage } from './pages/CartDetailPage';
import { CartListPage } from './pages/CartListPage';

export const CartModule = () => (
  <Routes>
    <Route path={`/detail/:id`} element={<CartDetailPage />} />
    <Route path={'/'} element={<CartListPage />} />
  </Routes>
);
