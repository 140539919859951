import React from 'react';
import { Card } from '../../components/cards/Card';
import { Page } from '../../components/layouts/page/Page';
import { PageTitle } from '../../components/layouts/page/PageTitle';
import { CreateCustomerForm } from '../components/CreateCustomerForm';

export const CustomerCreatePage = () => (
  <Page>
    <PageTitle title={"New Customer"} />

    <Card>
      <CreateCustomerForm />
    </Card>
  </Page>
);
