import {useEffect, useState} from 'react';
import {api} from '../../api/api';
import {Article} from '../../models/article';

export const useArticle = (articleId: string) => {
  const [article, setArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/articles/${articleId}`)
      .then(({ data }) => {
        setArticle(data);
        setLoading(false);
      });
  }, [articleId]);

  return {
    article,
    loading,
  }
};
