import gql from 'graphql-tag';

export const artworkFetch = gql`
    query ArtworkFetch($id: String!) {
        artwork (
            id: $id
        ) {
            _id
            name
            description
            category
            style
            subject
            technique
            status
            featured
            orientation
            year
            framed
            score

            dimension {
                width
                height
                depth
            }

            weight

            assets {
                _id
                type
                url
            }

            price {
                currency
                value
            }

            priceList {
                currency
                value
            }
            
            translation {
                _id
                language
                description
            }
        }
    }
`;

export const artworkFetchAll = gql`
    query ArtworkFetchAll {
        artworks {
            count

            artworks {
                _id
                featured
                name
                description
                category
                style
                subject
                technique
                status
                year
                format

                artist {
                    _id
                    nickName
                }

                price {
                    value
                    currency
                }


                dimension {
                    width
                    height
                }
            }
        }
    }
`;

export const artworkExport = gql`    
    query ArtworkExport {
        artworkExport {
            csv
        }
    }
`;
