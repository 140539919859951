import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import {apiBaseUri} from '../config';
import { CognitoUser } from './cognito/CognitoUser';

const uri = apiBaseUri;

export const createClient = () => {
  const httpLink = createHttpLink({
    uri,
  });

  const authLink = setContext((_, { headers }) => {
    let session;

    try {
      session = new CognitoUser().getSession();
    } catch (e) {}

    return {
      headers: {
        ...headers,
        Authorization: session ? `Bearer ${session.accessToken.getJwtToken()}` : ''
      }
    };
  });

  const link = authLink.concat(httpLink);

  return new ApolloClient({
    link,
    cache: new InMemoryCache()
  })
};
