import React from 'react';
import { useQuery } from 'react-apollo';
import {Select} from 'antd';
import gql from 'graphql-tag';
import {GallerySelectQuery} from './__generated__/GallerySelectQuery';

const {Option} = Select;

const GALLERY_QUERY = gql`
    query GallerySelectQuery {
        galleries {
            _id
            name
        }
    }
`;

interface Props {
  value?: string;
  onChange?: (data: string) => void;
}

export const GallerySelect: React.FunctionComponent<Props> = ({
  value,
  onChange,
}) => {
  const { loading, error, data } = useQuery<GallerySelectQuery>(GALLERY_QUERY);

  const galleries = data ? data.galleries : [];

  return (
    <Select
      loading={loading}
      disabled={!!error}
      showSearch
      style={{width: 200}}
      placeholder="Select a gallery"
      value={value}
      onChange={onChange}
    >
      {galleries && galleries.map(gallery => (
        <Option key={gallery._id} value={gallery._id}>{gallery.name}</Option>
      ))}
    </Select>
  );
};
