import React, {useEffect, useState} from 'react';
import {Card, Table} from 'antd';
import {CardTitle} from '../../components/Card';
import {api} from '../../api/api';

const zones = [
  'europe',
  'europe-belt',
  'eastern-europe',
  'efta',
  'north-america',
  'china',
  'russia',
  'asia',
  'pacific',
  'middle-east-africa',
  'south-america',
  'world',
];

type ArtworkShipping = {
  artworkId: string;
  artworkPrice: any;
}

const calculatePrice = (rule?: any, price?: any): string => {
  if (!rule) {
    return 'Could not find any rule';
  }

  const shippingPrice = rule.rules.reduce((currentPrice: number, rule: any) => {
    switch (rule.type) {
      case 'percentage':
        return rule.value * price.value / 100;
      case 'max':
        if (currentPrice > rule.value) {
          return rule.value;
        } else {
          return currentPrice;
        }
      case 'min':
        if (currentPrice < rule.value) {
          return rule.value;
        } else {
          return currentPrice;
        }
      default:
        return currentPrice;
    }
  }, 0);

  const priceToFixed = parseFloat((Math.ceil(shippingPrice * 20) / 20).toFixed(2));

  return `${price.currency} ${priceToFixed}`;
}

export const ArtworkShipping: React.FC<ArtworkShipping> = ({
  artworkId,
  artworkPrice,
}) => {
  const [loading, setLoading] = useState(true);
  const [rules, setRules] = useState([]);

  useEffect(() => {
    api
      .get(`/artworks/${artworkId}/shipping-price-rules`)
      .then(({data}) => {
        setRules(data);
        setLoading(false);
      });
  }, [artworkId]);

  const columns = [{
    title: 'Destination',
    dataIndex: 'zone',
    key: 'zone'
  }, {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  }];

  if (loading) {
    return (
      <Card>
        <CardTitle>Shipping</CardTitle>
      </Card>
    );
  }

  const dataSource = zones.map(zone => {
    const rule = rules.filter((rule: any) => rule.target.to === zone)[0] as any;
    const price = calculatePrice(rule, artworkPrice);

    return {
      zone,
      price,
    }
  });

  return (
    <Card>
      <CardTitle>Shipping</CardTitle>

      <Table
        columns={columns}
        dataSource={dataSource}
      />
    </Card>
  );
};
