import React, {useState} from 'react';
import axios from 'axios';
import {FeaturedSwitch} from '../../components/FeaturedSwitch';
import {restBaseUri} from '../../config';
import {CognitoUser} from '../../lib/cognito/CognitoUser';

interface Props {
  artistId: string;
  isIndependent: boolean | null;
}

export const ArtistIndependentSwitch: React.FunctionComponent<Props> = ({
  artistId,
  isIndependent,
}) => {
  const [value, setValue] = useState(isIndependent || false);

  const handleChange = (status: boolean) => {
    let session;

    try {
      session = new CognitoUser().getSession();
    } catch (e) {
    }

    const headers = {
      Authorization: session ? `Bearer ${session.accessToken.getJwtToken()}` : '',
    };

    return axios
      .patch(`${restBaseUri}/artists/${artistId}`, {
        isIndependent: status,
      }, {
        headers
      })
      .then(() => {
        setValue(status);
      });
  };

  return (
    <FeaturedSwitch
      featured={value}
      handleChange={handleChange}
    />
  );
};
