import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Page } from '../components/layouts/page/Page';
import { PageTitle } from '../components/layouts/page/PageTitle';

export const PostListPage = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <PageTitle
        title={'Posts'}
        actions={
          <Button onClick={() => navigate('/posts/create')} title={'new post'} type={'primary'}>
            new post
          </Button>
        }
      />
    </Page>
  );
};
