import { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { Layout, PageHeader, Table } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { api } from '../../api/api';
import { Cart } from '../../models/cart';

export const CartListPage = () => {
  const [loading, setLoading] = useState(true);
  const [carts, setCarts] = useState<Cart[]>([]);

  useEffect(() => {
    api.get('/carts').then(({ data }) => {
      setCarts(data);
      setLoading(false);
    });
  }, []);

  const columns: ColumnProps<Cart>[] = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => (
        <FormattedDate value={text} day={'numeric'} month={'long'} year={'numeric'} />
      ),
    },
    {
      title: 'Price',
      key: 'price',
      render: (_, record) => (
        <FormattedNumber value={record.amountTotal} currency={record.currency} style={'currency'} />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record) => (
        <span>
          <Link to={`/carts/detail/${record._id}`}>
            <a>Detail</a>
          </Link>
        </span>
      ),
    },
  ];

  return (
    <Layout.Content>
      <PageHeader title={'Orders'} />

      <Table loading={loading} columns={columns} dataSource={carts} />
    </Layout.Content>
  );
};
