import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { fetchCustomers, selectAllCustomers } from '../store/customer.slice';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks';

type CustomerSelectProps = {
  onChange?: () => void;
  value?: string;
}

export const CustomerSelect: React.FC<CustomerSelectProps> = ({
  onChange,
  value,
}) => {
  const dispatch = useAppDispatch();
  const customers = useSelector(selectAllCustomers);

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  return (
    <Select
      showSearch
      placeholder="Choose a customer"
      optionFilterProp="children"
      onChange={onChange}
      value={value}
    >
      {customers.map(customer => (
        <Select.Option
          key={customer._id}
          value={customer._id}
        >
          {customer.firstName} {customer.lastName}
        </Select.Option>
      ))}
    </Select>
  );
}
