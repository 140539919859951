import gql from 'graphql-tag';

export const galleryCreate = gql`  
  mutation GalleryCreate($input: GalleryCreateInput!) {
      galleryCreate(
          input: $input
      ) {
          _id
          name
      }
  }
`;

export const galleryUpdate = gql`  
  mutation GalleryUpdate($input: GalleryUpdateInput!) {
      galleryUpdate(
          input: $input
      ) {
          _id
          name
          city
          country
          description
          profilePicture
          email
          openingHours
          score

          address {
              line1
              line2
              postalCode
              state
              city
              country
          }
          
          phone {
              prefix
              number
          }
          
          identifier {
              type
              value
          }
          
          preferences {
              language
          }
      }
  }
`;

export const galleryDelete = gql`  
  mutation GalleryDelete($input: GalleryDeleteInput!) {
      galleryDelete (
          input: $input,
      ) {
          _id
      }
  }
`;

export const galleryProfilePictureUpdate = gql`  
  mutation GalleryProfilePictureUpdate($input: GalleryAssetUpdate!) {
      galleryProfilePictureUpdate(
          input: $input
      ) {
          url
      }
  }
`;

export const galleryLogoUpdate = gql`
    mutation GalleryLogoUpdate($input: GalleryAssetUpdate!) {
        galleryLogoUpdate(
            input: $input
        ) {
            url
        }
    }
`;

export const galleryFeaturedStatus = gql`
    mutation GalleryFeaturedStatus($input: GalleryFeaturedStatusInput!) {
        galleryFeaturedStatus (
            input: $input,
        ) {
            _id
        }
    }
`;

export const galleryAccept = gql`
    mutation GalleryAccept($input: VendorAcceptInput!) {
        galleryAccept (
            input: $input,
        ) {
            _id
            status
        }
    }
`;

export const galleryReject = gql`
    mutation GalleryReject($input: VendorRejectInput!) {
        galleryReject (
            input: $input,
        ) {
            _id
            status
        }
    }
`;

export const galleryDeactivate = gql`
    mutation GalleryDeactivate($input: GalleryActivateInput!) {
        galleryDeactivate (
            input: $input,
        ) {
            _id
            status
        }
    }
`;

export const galleryActivate = gql`
    mutation GalleryActivate($input: GalleryActivateInput!) {
        galleryActivate (
            input: $input,
        ) {
            _id
            status
        }
    }
`;

export const galleryTranslationUpdate = gql`
    mutation GalleryTranslationUpdate($input: GalleryTranslationUpdateInput!) {
        galleryTranslationUpdate (
            input: $input,
        ) {
            _id
        }
    }
`;

export const galleryPreferenceUpdate = gql`    
    mutation GalleryPreferenceUpdate($input: GalleryUpdatePreferencesInput!) {
        galleryPreferencesUpdate(
            input: $input,
        ) {
            _id
        }
    }
`;
