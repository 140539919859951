import {Button, Card, Input, message, Table} from 'antd';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import {CurrencyAddOn} from '../../components/addons/CurrencyAddOn';
import {ArtworkFetch_artwork_price, ArtworkFetch_artwork_priceList} from '../__generated__/ArtworkFetch';
import { artworkPriceUpdate} from '../artwork.mutations';
import { artworkFetch } from '../artwork.queries';
import {ArtworkPriceUpdate, ArtworkPriceUpdateVariables} from '../__generated__/ArtworkPriceUpdate';
import {Price} from '../models/artwork';

const rowSelection = {
  onChange: (selectedRowKeys: any, selectedRows: any) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record: any) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
};

interface Props {
  artworkId: string;
  price: Price;
  priceList: Price[];
}

export const ArtworkPriceList: React.FunctionComponent<Props> = (props) => {
  const [price, setPrice] = useState(props.price.value);
  const [currency, setCurrency] = useState(props.price.currency);
  const [loading, setLoading] = useState(false);
  const [mutate] = useMutation<ArtworkPriceUpdate, ArtworkPriceUpdateVariables>(artworkPriceUpdate);

  const updatePrice = () => {
    if(loading) {
      return;
    }

    setLoading(true);

    mutate({
      variables: {
        input:{
          artworkId: props.artworkId,
          price: {
            value: price,
            currency,
          }
        }
      },
      update: (cache, { data }) => {
        const { artwork }: any = cache.readQuery({ query: artworkFetch, variables: { id: props.artworkId }});

        if (!data) {
          return;
        }

        const { artworkPriceUpdate } = data;

        cache.writeQuery({
          query: artworkFetch,
          variables: {
            id: props.artworkId,
          },
          data: {
            artwork: {
              ...artwork,
              product: artworkPriceUpdate,
            }
          }
        });
      }
    }).then(() => {
      message.success('Price updated!');
    }).catch(() => {
      message.error('Price not updated!');
    }).finally(() => {
      setLoading(false);
    });
  };

  const columns = [{
    title: 'currency',
    dataIndex: 'currency',
    key: 'currency'
  }, {
    title: 'value',
    dataIndex: 'value',
    key: 'value',
  }];

  const dataSource: {value: number, currency: string;}[] = [props.price].concat(props.priceList);

  return (
    <Card
      title={'Price list'}
      extra={(
        <Input.Search
          value={price}
          onChange={({target}) => setPrice(parseInt(target.value) || 0)}
          addonBefore={CurrencyAddOn({ value: currency, onChange: setCurrency})}
          onSearch={updatePrice}
          enterButton={<Button loading={loading}>save</Button>}
        />
      )}
    >
      <Table
        columns={columns}
        dataSource={dataSource}
        rowSelection={rowSelection}
      />
    </Card>
  );
};
