import { DataProxy } from 'apollo-cache';
import { useMutation} from 'react-apollo';
import {ArtworkAccept, ArtworkAcceptVariables} from '../__generated__/ArtworkAccept';
import {ArtworkFetch, ArtworkFetchVariables} from '../__generated__/ArtworkFetch';
import {ArtworkReject, ArtworkRejectVariables} from '../__generated__/ArtworkReject';
import {ArtworkTranslationUpdate, ArtworkTranslationUpdateVariables} from '../__generated__/ArtworkTranslationUpdate';
import {ArtworkUpdate, ArtworkUpdateVariables} from '../__generated__/ArtworkUpdate';
import {artworkAccept, artworkReject, artworkTranslationUpdate, artworkUpdate} from '../artwork.mutations';
import {artworkFetch} from '../artwork.queries';

export const useArtwork = () => {
  const [updateMutation] = useMutation<ArtworkUpdate, ArtworkUpdateVariables>(artworkUpdate);
  const [acceptMutation] = useMutation<ArtworkAccept, ArtworkAcceptVariables>(artworkAccept);
  const [rejectMutation] = useMutation<ArtworkReject, ArtworkRejectVariables>(artworkReject);
  const [translationMutation] = useMutation<ArtworkTranslationUpdate, ArtworkTranslationUpdateVariables>(artworkTranslationUpdate);

  const update = (id: string, input: any) => {
    return updateMutation({
      variables: {
        input: {
          artworkId: id,
          score: input.score,
        }
      }
    })
  };

  const accept = (id: string) => {
    return acceptMutation({
      variables: {
          artworkId: id
      },
      update: (cache: DataProxy, { data }) => {
        if (!data) return;

        const options = {
          query: artworkFetch,
          variables: {
            id: data.artworkAccept._id,
          }
        };

        const cacheData = cache.readQuery<ArtworkFetch, ArtworkFetchVariables>(options);

        if (!cacheData) return;

        cache.writeQuery<ArtworkFetch, ArtworkFetchVariables>({
          ...options,
          data: {
            artwork: {
              ...cacheData.artwork,
              status: data.artworkAccept.status,
            }
          }
        });
      }
    })
  };

  const reject = (id: string) => {
    return rejectMutation({
      variables: {
          artworkId: id
      },
      update: (cache: DataProxy, { data }) => {
        if (!data) return;

        const options = {
          query: artworkFetch,
          variables: {
            id: data.artworkReject._id,
          }
        };

        const cacheData = cache.readQuery<ArtworkFetch, ArtworkFetchVariables>(options);

        if (!cacheData) return;

        cache.writeQuery<ArtworkFetch, ArtworkFetchVariables>({
          ...options,
          data: {
            artwork: {
              ...cacheData.artwork,
              status: data.artworkReject.status,
            }
          }
        });
      }
    })
  };

  const updateTranslation = (artworkId: string, language: string, description: string) => {
    return translationMutation({
      variables: {
        input: {
          artworkId,
          language,
          description,
        }
      }
    })
  };

  return {
    update,
    accept,
    reject,
    updateTranslation,
  }
};
