import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { restBaseUri } from '../../config';
import { Back } from '../../order/pages/OrderDetailPage';
import { ArrowLeftOutlined } from '@ant-design/icons';

export const AuthorDetailPage = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    axios
      .get(`${restBaseUri}/authors/${id}`)
      .then(({ data }) => {
        setAuthor(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <Layout.Content>
      <Back>
        <ArrowLeftOutlined onClick={() => navigate(-1)} style={{ marginRight: '.8rem' }} /> Authors
      </Back>
      Author found
    </Layout.Content>
  );
};
