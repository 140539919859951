import React from 'react';
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import { useDrag, useDrop } from 'react-dnd';

type ImageGridItemProps = {
  image: any;
  index: number;
  onDelete: (id: string) => void;
  onDrop: (imageId: string, index: number) => void;
};

export const ImageGridItem: React.FC<ImageGridItemProps> = ({
  image,
  index,
  onDelete,
  onDrop,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'IMAGE',
    item: image,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<any>();

      if (item && dropResult) {
        onDrop(item._id, dropResult.index);
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'IMAGE',
      drop: () => ({ index }),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [image],
  );

  return (
    <ImageElement
      ref={drop}
      style={{
        opacity: isDragging ? 0.5 : 1,
        border: isOver ? '2px solid  blue' : '2px solid #d7d7d7',
      }}
    >
      <RemoveIcon onClick={() => onDelete(image._id)}>
        <DeleteOutlined />
      </RemoveIcon>

      <Image
        ref={drag}
        src={image.url || image.src}
      />
    </ImageElement>
  );
};

const RemoveIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  width: 2.4rem;
  opacity: 0;
  top: 0.8rem;
  right: 0;
  background-color: #f0f2f5;
  border-radius: 50%;
  transition: all ease-out 0.1s;
  z-index: 1;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  &:active {
    cursor: pointer;
    transform: scale(0.95);
  }
`;

const ImageElement = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 0.6rem;
  overflow: hidden;
  border: 2px solid #d7d7d7;
  background-color: #d7d7d7cc;

  &:first-child {
    row-span: 2;
    grid-column: span 2;
    grid-row: span 2;
  }

  &:hover {
    ${RemoveIcon} {
      opacity: 1;
      right: 0.8rem;
    }
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  background-color: white;
`;
