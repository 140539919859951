import React, {useEffect, useState} from 'react';
import {Card, Select} from 'antd';
import {api} from '../../api/api';
import {CardTitle} from '../../components/cards/Card';
import {Author} from '../../models/author';

interface Option {
  value: string;
  label: string;
}

const parseOption = (author: Author): Option => ({
  value: author._id,
  label: author.displayName,
});

interface ArticleAuthorCardProps {
  defaultValue?: string;
  onChange?: (author: Author) => void;
}

export const ArticleAuthorCard: React.FC<ArticleAuthorCardProps> = ({
  defaultValue,
  onChange,
}) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [authors, setAuthors] = useState<Author[]>([]);

  useEffect(() => {
    api
      .get('/authors')
      .then(({ data }) => {
        setOptions(data.map(parseOption));
        setAuthors(data);
        setLoading(false);
      });
  }, []);

  const handleChange = (option: any) => {
    const author = authors.find(author => author._id === option)!;

    if (onChange) {
      onChange(author);
    }
  };

  return (
    <Card style={{ marginBottom: '1.6rem' }}>
      <CardTitle>Author</CardTitle>
      <p style={{ paddingTop: '1.6rem' }}>Find or create the author of this article.</p>

      <Select style={{ width: '100%' }} loading={loading} onChange={handleChange} defaultValue={defaultValue}>
        {options.map(({ value, label}) => (
          <Select.Option key={value} value={value}>{label}</Select.Option>
        ))}
      </Select>
    </Card>
  );
};
