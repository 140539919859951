import { Badge, Divider, Table, Tag } from 'antd';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';


interface Props {
  loading?: boolean;
  collections: Array<object>;
}

export const CollectionTable: React.FunctionComponent<Props> = ({
  loading,
  collections,
}) => {
  const columns = [

    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => (<strong>{text}</strong>)
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => (<span>{date.substring(0, 10)}</span>),
    },
    {
      title: 'Link',
      dataIndex: 'slug',
      key: 'slug',
      render: (slug: string) => (<Link to={'https://urbaneez.art/collections/'+slug} target='_blank'>link</Link>),
    },
    {
      title: '# of artworks',
      dataIndex: 'artworks',
      key: 'artworks',
      render: (artworks: any) => (<span>{artworks.length}</span>),
    },

    {
      title: 'Action',
      key: 'action',
      render: (collection: any) => (
        <span>
          <Link to={`/collections/detail/${collection._id}?name=${collection.name}`}>
            Detail
          </Link>
        </span>
      ),
    },
    
  ];

  return (
    <Table
      rowKey='slug'
      columns={columns as any}
      loading={loading}
      dataSource={collections}
    />
  );
};
