import React from 'react';

interface Props {
  isOver: any,
  moveRow: any,
  style: any,
  index: number;
  draggingIndex: number;
  className: string;
}

export const BodyRow: React.FunctionComponent<Props> = props => {
  const {isOver, moveRow, draggingIndex, ...restProps} = props;
  const style = { ...restProps.style, cursor: 'move' };
  let { className } = restProps;

  if (isOver) {
    if (restProps.index > draggingIndex) {
      className += ' drop-over-downward';
    }

    if (restProps.index < draggingIndex) {
      className += ' drop-over-upward';
    }
  }

  return (
    <tr
      {...restProps}
      className={className}
      style={style}
    />
  );
};