import {Card, Input} from 'antd';
import React, {useRef, useState} from 'react';
import {CardTitle} from '../../components/Card';
import {useArtist} from '../hooks/use-artist';

interface ArtistScoreProps {
  artistId: string;
  score?: number;
}

export const ArtistScore: React.FC<ArtistScoreProps> = ({
  artistId,
  score,
}) => {
  const ref = useRef<any>();
  const [scoreEdit, setScoreEdit] = useState(score);
  const { update } = useArtist();

  const handleUpdate = () => {
    if (scoreEdit !== score) {
      update(artistId, { score: scoreEdit });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleUpdate();
      ref!.current.blur();
    }

    if (e.key === 'Escape') {
      setScoreEdit(score);

      Promise.resolve().then(() => ref!.current.blur());
    }
  };

  const handleBlur = (e: any) => {
    handleUpdate();
  };

  return (
    <Card style={{ marginBottom: '1.6rem'}}>
      <CardTitle>Score</CardTitle>
      <p style={{paddingTop: '1.6rem'}}>The higher the score, the higher the artist will be in the artists page</p>

      <Input
        ref={ref}
        value={scoreEdit}
        onChange={({ target }) => setScoreEdit(parseInt(target.value))}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        type='number'
      />
    </Card>
  );
};
