import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { Customer } from '../models/customer';
import { api } from '../../../api/api';
import { RootState } from '../../../app/store';

type CreateCustomerInput = {
  firstName: string;
  lastName: string;
  email: string;
}

export const createCustomer = createAsyncThunk('customers/create', async (
  input: CreateCustomerInput
) => {
  const { data } = await api.post('/customers', input);
  return data;
});

export const fetchCustomers = createAsyncThunk('customers/fetch-list', async () => {
  const { data } = await api.get('/customers');
  return data;
});

export const fetchCustomer = createAsyncThunk('customers/fetch-one', async (
  customerId: string,
) => {
  const { data } = await api.get(`/customers/${customerId}`);
  return data;
});

type CustomerState = EntityState<Customer>;

const customerAdapter = createEntityAdapter<Customer>({
  selectId: (entity) => entity._id,
})

const initialState = customerAdapter.getInitialState();

const customerSlice = createSlice<CustomerState, any, 'customer'>({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createCustomer.fulfilled, (state, action) => {
        customerAdapter.addOne(state, action.payload);
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        customerAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        customerAdapter.upsertOne(state, action.payload)
      });
  }
});

export const  {
  selectAll: selectAllCustomers,
} = customerAdapter.getSelectors<RootState>(state => state.customer);

export const customerReducer = customerSlice.reducer;
