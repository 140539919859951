import gql from 'graphql-tag';

export const artistCreate = gql`  
  mutation ArtistCreate($input: ArtistCreateInput!) {
      artistCreate(
          input: $input
      ) {
          _id
          firstName
          lastName
          nickName
          email
          gender
          phone {
              prefix
              number
          }
      }
  }
`;

export const artistUpdate = gql`
    mutation ArtistUpdate($input: UpdateArtistInput!) {
        artistUpdate(
            input: $input
        ) {
            _id
            firstName
            lastName
            nickName
            email
            gender
            score
            
            identifier {
                type
                value
            }
            phone {
                prefix
                number
            }
        }
    }
`;

export const artistProfilePictureUpdate = gql`
  mutation ArtistProfilePictureUpdate($input: ArtistProfilePictureUpdateInput!) {
    artistProfilePictureUpdate (
      input: $input
    ) {
      url
    }
  }
`;

export const artistCoverPictureUpdate = gql`
    mutation ArtistCoverPictureUpdate($input: ArtistProfilePictureUpdateInput!) {
        artistCoverPictureUpdate (
            input: $input
        ) {
            url
        }
    }
`;

export const artistFeaturedStatus = gql`
    mutation ArtistFeaturedStatus($input: ArtistFeaturedStatusInput!) {
        artistFeaturedStatus (
            input: $input,
        ) {
            _id
        }
    }
`;

export const artistStoryAdd = gql`
    mutation ArtistStoryAdd($input: ArtistStoryAddInput!) {
        artistStoryAdd (
            input: $input,
        ) {
            stories {
                _id
                type
                author
                caption
                order
                content
            }
        }
    }
`;

export const artistStoryDelete = gql`
    mutation ArtistStoryDelete($input: ArtistStoryDeleteInput!) {
        artistStoryDelete (
            input: $input,
        ) {
            stories {
                _id
                type
                author
                caption
                order
                content
            }
        }
    }
`;

export const artistStoryOrderUpdate = gql`
    mutation ArtistStoryOrderUpdate($input: ArtistStoryUpdateOrderInput!) {
        artistStoryOrderUpdate (
            input: $input,
        ) {
            stories {
                _id
                type
                author
                caption
                order
                content
            }
        }
    }
`;

export const artistStoryCoverUpdate = gql`
    mutation ArtistStoryCoverUpdate ($input: ArtistStoryCoverUpdateInput!){
        artistStoryCoverUpdate (
            input: $input
        ) {
            url
        }
    }
`;

export const artistAccept = gql`
    mutation ArtistAccept($input: VendorAcceptInput!) {
        artistAccept (
            input: $input,
        ) {
            _id
            status
        }
    }
`;

export const artistReject = gql`
    mutation ArtistReject($input: VendorRejectInput!) {
        artistReject (
            input: $input,
        ) {
            _id
            status
        }
    }
`;

export const artistDeactivate = gql`
    mutation ArtistDeactivate($input: ArtistDeactivateInput!) {
        artistDeactivate (
            input: $input,
        ) {
            _id
            status
        }
    }
`;

export const artistActivate = gql`
    mutation ArtistActivate($input: ArtistDeactivateInput!) {
        artistActivate (
            input: $input,
        ) {
            _id
            status
        }
    }
`;

export const artistTranslationUpdate = gql`
    mutation ArtistTranslationUpdate($input: ArtistTranslationUpdateInput!) {
        artistTranslationUpdate (
            input: $input,
        ) {
            _id
        }
    }
`;

export const artistPreferencesUpdate = gql`    
    mutation ArtistPreferenceUpdate($input: ArtistUpdatePreferenceInput!) {
        artistPreferencesUpdate(
            input: $input,
        ) {
            _id
        }
    }
`;
