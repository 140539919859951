export default class CognitoJwtToken {
  jwtToken: string;

  /**
   * Constructs a new CognitoJwtToken object
   * @param {string=} token The JWT token.
   */
  constructor(token: string) {
    this.jwtToken = token || '';
  }

  /**
   * @returns {string} the record's token.
   */
  getJwtToken() {
    return this.jwtToken;
  }
}
