import React, { useState } from 'react';
import useSWR from 'swr';
import { useMutation } from 'react-apollo';
import { Button, Layout, message, Modal, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';

import { ArtworkDelete } from '../__generated__/ArtworkDelete';
import { artworkDelete } from '../artwork.mutations';
import { ArtworkTable } from '../components/ArtworkTable';
import { CognitoUser } from '../../lib/cognito/CognitoUser';
import { apiBaseUri } from '../../config';
import { api } from '../../api/api';

const LIMIT = 10;

const fetcher = (url: string) => {
  return api.get(url).then(({ data, headers }) => {
    let count;
    let cursor;
    try {
      cursor = parseInt(headers['content-range']!.split(' ')[1].split('/')[0], 10);
      count = parseInt(headers['content-range']!.split('/')[1], 10);
    } catch (e) {
      count = data.length;
    }

    return {
      count,
      cursor,
      artworks: data,
    };
  });
};

export const ArtworkListPage = () => {
  const navigate = useNavigate();
  const search = qs.parse(window.location.search.replace('?', ''));
  const [mutate] = useMutation<ArtworkDelete>(artworkDelete);

  const [page, setPage] = useState(search.page ? parseInt(search.page as string, 10) : 1);
  const [perPage, setPerPage] = useState(search.limit ? parseInt(search.limit as string, 10) : 10);

  const getKey = () => {
    let skip = (page - 1) * perPage;

    if (skip < 0) {
      skip = 0;
    }

    const query = {
      limit: perPage,
      skip,
    };

    const querystring = qs.stringify(query);

    return `/artworks?${querystring}`;
  };

  const { isLoading, data } = useSWR(getKey, fetcher);

  const handlePageChange = (page: number, pageSize: number) => {
    console.log(pageSize);
    window.history.pushState({}, 'URL Rewrite Example', `/artworks?page=${page}&limit=${pageSize}`);
    setPage(page);
    setPerPage(pageSize);
  };

  const handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Are you sure delete this artwork?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        mutate({
          variables: {
            input: {
              artworkId: id,
            },
          },
        })
          .then(() => {
            message.success('Artwork successfully deleted.');
          })
          .catch(() => {
            message.error('Unable to delete artwork.');
          });
      },
      onCancel() {},
    });
  };

  const handleExport = () => {
    let token;

    try {
      const session = new CognitoUser().getSession();
      token = session.accessToken.getJwtToken();
    } catch (e) {}

    if (!token) return;

    axios
      .post(
        apiBaseUri,
        {
          query: `
        query {
          artworkExport {
            csv
          }
        }
      `,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(({ data }) => {
        const { csv } = data.data.artworkExport;
        const url = window.URL.createObjectURL(new Blob([csv], { type: 'application/csv' }));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', 'artworks.csv');
        link.click();
        window.URL.revokeObjectURL(url);
      });
  };

  return (
    <Layout.Content>
      <PageHeader
        title="Artworks"
        extra={[
          <Button onClick={handleExport}>export</Button>,
          <Button
            onClick={() => navigate('/artworks/create')}
            title={'new artwork'}
            type={'primary'}>
            new artwork
          </Button>,
        ]}
      />

      <ArtworkTable
        loading={isLoading}
        artworks={data?.artworks || []}
        onDelete={handleDelete}
        pagination={{
          current: page,
          total: data?.count || 0,
          pageSize: perPage,
          onChange: handlePageChange,
        }}
      />
    </Layout.Content>
  );
};
