import {Card, Input} from 'antd';
import React, {useRef, useState} from 'react';
import {CardTitle} from '../../components/Card';
import {useArtwork} from '../hooks/use-artwork';

interface ArtworkScoreProps {
  artworkId: string;
  score?: number;
}

export const ArtworkScore: React.FC<ArtworkScoreProps> = ({
  artworkId,
  score,
}) => {
  const ref = useRef<any>();
  const [scoreEdit, setScoreEdit] = useState(score);
  const { update } = useArtwork();

  const handleUpdate = () => {
    if (scoreEdit !== score) {
      update(artworkId, { score: scoreEdit });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleUpdate();
      ref!.current.blur();
    }

    if (e.key === 'Escape') {
      setScoreEdit(score);

      Promise.resolve().then(() => ref!.current.blur());
    }
  };

  const handleBlur = (e: any) => {
    handleUpdate();
  };

  return (
    <Card>
      <CardTitle>Score</CardTitle>
      <p style={{paddingTop: '1.6rem'}}>The higher the score, the higher the artwork will be in the artworks page</p>

      <Input
        ref={ref}
        value={scoreEdit}
        onChange={({ target }) => setScoreEdit(parseInt(target.value))}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        type='number'
      />
    </Card>
  );
};
