import moment from 'moment';
import React, { FormEvent, useState } from 'react';
import { Checkbox, DatePicker, Form, Input, message, Modal } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { EventUpdate, EventUpdateVariables } from '../__generated__/EventUpdate';
import { eventUpdate } from '../event.mutation';
import { eventFetchAll } from '../event.queries';
import { EventFetchAll, EventFetchAll_events } from '../__generated__/EventFetchAll';

interface Props {
  visible: boolean;
  event: EventFetchAll_events;
  onOk: () => void;
  onCancel: () => void;
}

export const EventUpdateModal: React.FC<Props> = (props) => {
  const { event, onOk, ...modalProps } = props;
  const [loading, setLoading] = useState(false);
  const [mutate] = useMutation<EventUpdate, EventUpdateVariables>(eventUpdate);

  const [form] = Form.useForm();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const { ownerId } = event;

    form
      .validateFields()
      .then((values) => {
        return mutate({
          variables: {
            input: {
              eventId: event._id,
              ...values,
            },
          },
          update: (cache, { data }) => {
            const cacheData = cache.readQuery<EventFetchAll>({
              query: eventFetchAll,
              variables: {
                filter: {
                  ownerId: {
                    eq: ownerId,
                  },
                },
              },
            });

            if (!cacheData || !data) {
              return;
            }

            const { events } = cacheData;

            const { eventUpdate } = data;

            cache.writeQuery({
              query: eventFetchAll,
              variables: {
                filter: {
                  ownerId: {
                    eq: ownerId,
                  },
                },
              },
              data: {
                events: events.map((el) => {
                  if (el._id !== event._id) {
                    return el;
                  }

                  return Object.assign({}, el, eventUpdate);
                }),
              },
            });
          },
        })
          .then(() => {
            message.success('Event updated!');
            onOk();
          })
          .catch(() => {
            message.error('Unable to update event!');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
        return console.log('Received values of form: ', err);
      });
  };

  return (
    <Modal onOk={handleSubmit} {...modalProps} destroyOnClose={true} confirmLoading={loading}>
      <Form layout="vertical" form={form}>
        <Form.Item
          label={'Title'}
          name={'title'}
          initialValue={event.title}
          rules={[
            {
              required: true,
              message: 'Please input an event title',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'Location'}
          name="location"
          initialValue={event.location}
          rules={[
            {
              required: true,
              message: 'Please input a location',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'Country'}
          name="country"
          initialValue={event.country}
          rules={[
            {
              required: true,
              message: 'Please input a country',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'City'}
          name="city"
          initialValue={event.city}
          rules={[
            {
              required: true,
              message: 'Please input a city',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item label={'Link'} name="link" initialValue={event.link}>
          <Input />
        </Form.Item>

        <Form.Item
          label={'Start date'}
          name="startAt"
          initialValue={moment(parseInt(event.startAt, 10))}
          rules={[
            {
              required: true,
              message: 'Please input start date',
            },
          ]}>
          <DatePicker />
        </Form.Item>

        <Form.Item
          label={'End Date'}
          name="endAt"
          initialValue={moment(parseInt(event.endAt, 10))}
          rules={[
            {
              required: true,
              message: 'Please input an end date',
            },
          ]}>
          <DatePicker />
        </Form.Item>

        <Form.Item
          label={'Featured'}
          name="featured"
          initialValue={event.featured}
          valuePropName="checked">
          <Checkbox>Featured</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
