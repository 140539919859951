import React, { useState } from 'react';
import { message, Modal, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import { encode } from 'blurhash';

import { api } from '../../api/api';

const { Dragger } = Upload;

interface Props {
  artworkId: string;
  visible: boolean;
  onCancel: any;
  onOk: any;
}

const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (...args) => reject(args);
    img.src = src;
  });
};

const getImageData = (image: HTMLImageElement) => {
  const width = 500;
  const height = width * (image.height / image.width);
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.drawImage(image, 0, 0, width, height);
  return context.getImageData(0, 0, width, height);
};

const encodeImage = ({ data, width, height }: ImageData) => {
  return encode(data, width, height, 4, 4);
};

export const ArtworkAssetUploadModal: React.FunctionComponent<Props> = ({
  artworkId,
  visible,
  onCancel,
  onOk,
}) => {
  const [loading, setLoading] = useState(false);

  const customRequest = async (info: any /*{ file: File }*/) => {
    setLoading(true);
    const { file } = info;

    const url = URL.createObjectURL(file);
    const image = await loadImage(url);
    const imageData = getImageData(image);
    const blurHash = encodeImage(imageData);

    api
      .post(`/artworks/${artworkId}/images`, {
        type: file.type,
        height: image.height,
        width: image.width,
        blurHash,
      })
      .then(async ({ data }) => {
        const { links } = data;

        // now do a PUT request to the pre-signed URL
        await axios.put(links.upload, file);
        return data;
      })
      .then((data) => {
        message.success('Asset uploaded successfully!');
        onOk(data);
      })
      .catch(() => {
        message.error('Could not upload asset!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      confirmLoading={loading}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      destroyOnClose={true}>
      <Dragger customRequest={customRequest} showUploadList={false}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>

        <p className="ant-upload-text">Click or drag file to this area to upload</p>

        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or
          other band files
        </p>
      </Dragger>
    </Modal>
  );
};
