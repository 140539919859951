import React, { useEffect, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

import { api } from '../../api/api';
import { Cart } from '../../models/cart';
import { Back } from '../../order/pages/OrderDetailPage';
import { CustomerData } from '../components/CustomerData';
import { ItemListCard } from '../components/ItemListCard';
import { PriceCard } from '../components/PriceCard';

export const CartDetailPage = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState<Cart | null>(null);

  useEffect(() => {
    api.get(`/carts/${params.id}`).then(({ data }) => {
      setCart(data);
      setLoading(false);
    });
  }, []);

  return (
    <Layout.Content>
      <Back>
        <ArrowLeftOutlined onClick={() => navigate(-1)} style={{ marginRight: '.8rem' }} /> Carts
      </Back>

      {loading || !cart ? null : (
        <Row gutter={32}>
          <Col lg={16} xxl={16}>
            <ItemListCard items={cart.items} />

            <PriceCard
              amountTotal={cart.amountTotal}
              shippingTotal={cart.shippingTotal}
              itemTotal={cart.itemTotal}
              currency={cart.currency}
            />
          </Col>

          <Col lg={8} xxl={8}>
            <CustomerData
              customer={{
                firstName: cart.firstName,
                lastName: cart.lastName,
                email: cart.email,
                phone: cart.phone,
              }}
              shippingAddress={cart.shippingAddress}
              billingAddress={cart.billingAddress}
            />
          </Col>
        </Row>
      )}
    </Layout.Content>
  );
};
