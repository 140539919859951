import {Button, Card} from 'antd';
import React from 'react';
import {FormattedNumber} from 'react-intl';
import axios from 'axios';
import {useModal} from '../../components/hooks/useModal';
import {PriceLine, PriceLineTotal} from '../../components/price-line/PriceLine';
import {OrderFetchOneById_order} from '../__generated__/OrderFetchOneById';
import {useOrder} from '../hooks/useOrder';
import {PaymentStatus} from './PaymentStatus';
import {UploadInvoiceModal} from './UploadInvoiceModal';

interface OrderPriceCardProps {
  order: OrderFetchOneById_order
}

export const OrderPriceCard: React.FC<OrderPriceCardProps> = ({order}) => {
  const {visible, openModal, closeModal} = useModal();
  const { downloadInvoice } = useOrder();

  const handleDownload = () => {
    try {
      return downloadInvoice(order._id);
    } catch (e) {
      // TODO Handle notification + sentry
    }
  };

  const renderInvoiceButton = () => {
    if(order.hasInvoice) {
      return <Button onClick={handleDownload}>Download invoice</Button>;
    } else {
      return <Button onClick={openModal}>Upload invoice</Button>;
    }
  };

  return (
    <Card
      title={<PaymentStatus status={order.paymentStatus}/>}
      style={{marginTop: '1.6rem'}}
      extra={[
        renderInvoiceButton(),
      ]}
    >
      <PriceLine>
        <div>Subtotal</div>
        <FormattedNumber value={order.itemTotal} currency={order.currency} style={'currency'}/>
      </PriceLine>

      <PriceLine>
        <div>Shipping</div>
        <FormattedNumber value={order.shippingTotal} currency={order.currency} style={'currency'}/>
      </PriceLine>

      <PriceLineTotal>
        <div>Total</div>
        <FormattedNumber value={order.amountTotal} currency={order.currency} style={'currency'}/>
      </PriceLineTotal>

      {visible && (
        <UploadInvoiceModal
          orderId={order._id}
          onClose={closeModal}
          onSuccess={closeModal}
        />
      )}
    </Card>
  );
};
