import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Button, Card, Divider, Table } from 'antd';
import { useModal } from '../../components/hooks/useModal';
import { BodyRow } from '../../components/table/BodyRow';
import { storyFetchAll } from '../story.queries';
import {
  StoryFetchAll,
  StoryFetchAll_stories,
  StoryFetchAllVariables,
} from '../__generated__/StoryFetchAll';
import { storyDelete, storyOrderUpdate } from '../story.mutations';
import { StoryCreateModal } from './StoryCreateModal';
import { StoryDelete, StoryDeleteVariables } from '../__generated__/StoryDelete';
import { StoryOrderUpdate, StoryOrderUpdateVariables } from '../__generated__/StoryOrderUpdate';
import { StoryUpdateModal } from './StoryUpdateModal';

let draggingIndex = -1;

interface Props {
  ownerId: string;
}

export const StoryList: React.FunctionComponent<Props> = ({ ownerId }) => {
  const [mutateDelete] = useMutation<StoryDelete, StoryDeleteVariables>(storyDelete);
  const [mutateOrder] = useMutation<StoryOrderUpdate, StoryOrderUpdateVariables>(storyOrderUpdate);
  const [currentStory, setCurrentStory] = useState<StoryFetchAll_stories | null>(null);

  const { loading, error, data } = useQuery<StoryFetchAll, StoryFetchAllVariables>(storyFetchAll, {
    variables: {
      filter: {
        ownerId: {
          eq: ownerId,
        },
      },
    },
  });

  const { visible, openModal, closeModal } = useModal();

  const {
    visible: updateVisible,
    openModal: openUpdateModal,
    closeModal: closeUpdateModal,
  } = useModal();

  const handleUpdateOpen = (id: string) => {
    if (!data || !data.stories) {
      return;
    }

    setCurrentStory(data.stories.find((el) => el._id === id) || null);
    openUpdateModal();
  };

  const handleUpdateClose = () => {
    closeUpdateModal();
    setCurrentStory(null);
  };

  if (error) {
    return null;
  }

  const handleDelete = (storyId: string) => {
    return mutateDelete({
      variables: {
        input: {
          storyId,
        },
      },
      update: (cache, { data: serverData }) => {
        const cacheData = cache.readQuery<StoryFetchAll, StoryFetchAllVariables>({
          query: storyFetchAll,
          variables: {
            filter: {
              ownerId: {
                eq: ownerId,
              },
            },
          },
        });

        if (!cacheData || !data) {
          return;
        }

        const { stories } = cacheData;

        cache.writeQuery({
          query: storyFetchAll,
          variables: {
            filter: {
              ownerId: {
                eq: ownerId,
              },
            },
          },
          data: {
            stories: stories.filter((el) => el._id !== storyId),
          },
        });
      },
    });
  };

  const handleUpdateOrder = async (storyId: string, order: number) => {
    return mutateOrder({
      variables: {
        input: {
          storyId,
          order,
        },
      },
      update: (cache, { data: serverData }) => {
        const cacheData = cache.readQuery<StoryFetchAll, StoryFetchAllVariables>({
          query: storyFetchAll,
          variables: {
            filter: {
              ownerId: {
                eq: ownerId,
              },
            },
          },
        });

        if (!cacheData || !data || !serverData) {
          return;
        }

        const { stories } = cacheData;

        console.log(serverData);

        cache.writeQuery({
          query: storyFetchAll,
          variables: {
            filter: {
              ownerId: {
                eq: ownerId,
              },
            },
          },
          data: {
            stories: stories.filter((el) => el._id !== storyId).concat(serverData.storyOrderUpdate),
          },
        });
      },
    });
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Caption',
      dataIndex: 'caption',
      key: 'caption',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: StoryFetchAll_stories) => (
        <span>
          <a onClick={() => handleUpdateOpen(record._id)}>Update</a>
          <Divider type="vertical" />
          <a onClick={() => handleDelete(record._id)}>Delete</a>
        </span>
      ),
    },
  ];

  if (!data) {
    return null;
  }

  const sortedStories = data.stories.sort((a, b) => (a.order > b.order ? 1 : -1));

  const components = {
    body: {
      row: BodyRow,
    },
  };

  const moveRow = async (dragIndex: number, hoverIndex: number) => {
    await handleUpdateOrder(sortedStories[dragIndex]._id, hoverIndex + 1);
  };

  return (
    <Card
      title="Stories"
      style={{ marginTop: '1.6rem' }}
      extra={<Button onClick={openModal}>new</Button>}>
      <Table
        loading={loading}
        dataSource={sortedStories}
        columns={columns}
        components={components}
        /* onRow={(record, index) => ({
          index,
          draggingIndex,
          moveRow: moveRow,
        })} */
      />

      <StoryCreateModal
        ownerId={ownerId}
        visible={visible}
        onCancel={closeModal}
        onOk={closeModal}
      />

      {currentStory && (
        <StoryUpdateModal
          visible={updateVisible}
          story={currentStory}
          onCancel={handleUpdateClose}
          onOk={handleUpdateClose}
        />
      )}
    </Card>
  );
};
