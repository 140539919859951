import React from 'react';
import { Button, Col, Layout, message, Row } from 'antd';
import { ArrowLeftOutlined, CalendarFilled } from '@ant-design/icons';
import moment from 'moment';
import { useQuery } from 'react-apollo';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CustomerData } from '../../commerce/components/CustomerData';
import { addLeadingChar } from '../../lib/addLeadingChar';
import { OrderFetchOneById, OrderFetchOneByIdVariables } from '../__generated__/OrderFetchOneById';
import { ItemListCard } from '../components/ItemListCard';
import { OrderPriceCard } from '../components/OrderPriceCard';
import { useOrder } from '../hooks/useOrder';
import { orderFetchOneById } from '../order.queries';

export const OrderDetailPage = () => {
  const navigate = useNavigate();
  const params = useParams() as { id: string };

  const { confirmOrder } = useOrder();

  const { loading, error, data } = useQuery<OrderFetchOneById, OrderFetchOneByIdVariables>(
    orderFetchOneById,
    {
      variables: {
        id: params.id,
      },
    },
  );

  if (loading || error || !data) {
    return <div />;
  }

  const { order } = data;

  const handleConfirm = () => {
    confirmOrder(order._id).catch(() => {
      message.error('Could not confirm order.');
    });
  };

  const handleReject = () => {};

  const orderNumber = order.number ? addLeadingChar(order.number.toString(), '0', 4) : '';
  const date = new Date(parseInt(order.createdAt, 10));
  const formattedDate = moment(date).format('DD.MM.yy [at] hh:mm');

  return (
    <Layout.Content>
      <Back>
        <ArrowLeftOutlined onClick={() => navigate(-1)} style={{ marginRight: '.8rem' }} /> Orders
      </Back>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '1.6rem',
          }}>
          <PageTitle>
            Order <strong>#{orderNumber}</strong>
          </PageTitle>
          <VerticalDivider />
          <CalendarFilled style={{ fontSize: '2rem', color: '#96a9c8' }} />
          <OrderDate>{formattedDate}</OrderDate>
        </div>

        <div>
          <Button type={'primary'} onClick={handleReject}>
            Reject
          </Button>
          <Button type={'primary'} onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      </div>

      <Row gutter={32}>
        <Col lg={16} xxl={16}>
          <ItemListCard order={order} />

          <OrderPriceCard order={order} />
        </Col>

        <Col lg={8} xxl={8}>
          <CustomerData
            customer={order.customer}
            shippingAddress={order.shippingAddress}
            billingAddress={order.billingAddress}
          />
        </Col>
      </Row>
    </Layout.Content>
  );
};

export const Back = styled.div`
  margin-top: 3.2rem;
  font-family: Quicksand, 'sans-serif';
  font-weight: 700;
  color: #a7cde3;
  //color: #95a2b3;
  font-size: 1.6rem;
`;

const PageTitle = styled.h1`
  font-size: 2.4rem;
  font-family: Quicksand, 'sans-serif';
  color: #8198aa;
  font-weight: 700;
  margin-bottom: 0;

  strong {
    color: #3e475e;
  }
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 2.4rem;
  color: #555555;
  margin: 0 1.6rem;
  background-color: #dce2ec;
`;

const OrderDate = styled.span`
  font-family: Quicksand, 'sans-serif';
  color: #3e475e;
  font-weight: 500;
  margin-left: 0.8rem;
  font-size: 1.6rem;
`;
