import {Button, Card, Modal, Tabs} from 'antd';
import React, {useState} from 'react';
import {CardTitle} from '../../components/cards/Card';
import {useModal} from '../../components/hooks/useModal';
import {VendorDescriptionModal} from './VendorDescriptionModal';
import TextArea from 'antd/es/input/TextArea';
import {useGallery} from '../../gallery/hooks/use-gallery';

interface Props {
  vendorId: string;
  description?: string | null;
  translations: {
    language: string,
    description: string | null,
  }[] | null;
}

export const VendorDescription: React.FC<Props> = ({
  description,
  vendorId,
  translations
}) => {
  const { visible, closeModal: closeMainModal, openModal: openMainModal} = useModal();
  const [updateModal, setUpdateModal] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');
  const [currentLocale, setCurrentLocale] = useState('');

  const { updateTranslation } = useGallery();

  const openModal = (lg: string) => {
    setUpdateModal(true);

    const translation = translations?.find(el => el.language === lg);
    setCurrentDescription(translation?.description || '');
    setCurrentLocale(lg);
  };

  const closeModal = () => {
    setUpdateModal(false);
    setCurrentDescription('');
    setCurrentLocale('');
  };

  const handleUpdate = () => {
    updateTranslation(
      vendorId,
      currentLocale,
      currentDescription
    )
      .then(() => {
        closeModal();
      });
  };

  return (
    <Card style={{ marginBottom: '1.6rem' }} extra={<Button onClick={openMainModal}>update</Button>}>
      <CardTitle>Description</CardTitle>

      <Tabs>
        <Tabs.TabPane tab={'English'} key={'en'}>
          <p dangerouslySetInnerHTML={{__html: description || ''}}/>
        </Tabs.TabPane>

        <Tabs.TabPane tab={'French'} key={'fr'}>
          <p dangerouslySetInnerHTML={{__html: translations?.find(el => el.language === 'fr')?.description || ''}}/>

          <Button onClick={() => openModal('fr')}>Update</Button>
        </Tabs.TabPane>
      </Tabs>

      <VendorDescriptionModal
        visible={visible}
        onClose={closeMainModal}
        vendorId={vendorId}
        description={description}
      />

      <Modal visible={updateModal} onCancel={closeModal} title={"Update description"} onOk={handleUpdate}>
        <TextArea
          value={currentDescription}
          onChange={({target}) => setCurrentDescription(target.value)}
        />
      </Modal>
    </Card>
  )
};
