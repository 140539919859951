import React, { useState } from 'react';
import { message, Switch } from 'antd';

interface Props {
  featured: boolean;
  handleChange: (status: boolean) => Promise<any>;
}

export const FeaturedSwitch: React.FunctionComponent<Props> = ({ featured, handleChange }) => {
  const [loading, setLoading] = useState(false);

  const handleFeatured = (status: boolean) => {
    setLoading(true);

    handleChange(status)
      .then(() => {
        message.success(status ? 'Gallery is now featured!' : 'Gallery is not featured anymore!');
      })
      .catch(() => {
        message.error('Unable to update featured status!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <label>
      <Switch onClick={handleFeatured} title="featured" checked={featured} loading={loading} />
    </label>
  );
};
