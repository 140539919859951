import StorageHelper from './StorageHelper';
import CognitoUserSession from './CognitoUserSession';
import CognitoJwtToken from "./CognitoJwtToken";

export class CognitoUser {

    /**
     * Constructs a new CognitoUser object
     */
    constructor() {
        this.storage = new StorageHelper().getStorage();
    }

    /**
     * This is used to authenticate a use based on the server response
     * @param {object} authResult Successful auth response from server.
     * @param {string} authResult.accessToken Successful auth response from server.
     * @returns {CognitoUserSession} The new user session.
     */
    authenticateUser(authResult) {
        this.signInUserSession = this.getCognitoUserSession(authResult);
        this.cacheTokens();
    }

    /**
     * This is used to build a user session from tokens retrieved in the authentication result
     * @param {object} authResult Successful auth response from server.
     * @param {string} authResult.accessToken Successful auth response from server.
     * @returns {CognitoUserSession} The new user session.
     * @private
     */
    getCognitoUserSession(authResult) {
        const accessToken = new CognitoJwtToken(authResult.accessToken);

        const sessionData = {
            AccessToken: accessToken
        };

        return new CognitoUserSession(sessionData);
    }

    /**
     *
     * @returns {CognitoUserSession}
     */
    getSession() {
        if(this.signInUserSession !== null && this.signInUserSession !== undefined && this.signInUserSession.isValid()) {
            return this.signInUserSession;
        }

        const keyPrefix = 'EagleCognitoProvicer';
        const accessTokenKey = `${keyPrefix}.accessToken`;

        const accessToken = new CognitoJwtToken(this.storage.getItem(accessTokenKey));

        if(accessToken) {
            const sessionData = {
                AccessToken: accessToken
            };
            const cachedSession = new CognitoUserSession(sessionData);
            if(cachedSession.isValid()) {
                this.signInUserSession = cachedSession;
                return this.signInUserSession;
            } else {
                this.clearCachedTokens();
                throw new Error('Cannot retrieve a new session. Please authenticate.');
            }

            // Try to refresh the session
        }

        throw new Error('Cannot retrieve a new session. Please authenticate.');
    }

    /**
     * This uses the refreshToken to retrieve a new session
     * @returns {void}
     */
    refreshSession() {

    }

    /**
     * This is used to save the session tokens to local storage
     * @returns {void}
     */
    cacheTokens() {
        const keyPrefix = `EagleCognitoProvicer`;
        const accessToken = `${keyPrefix}.accessToken`;

        this.storage.setItem(accessToken, this.signInUserSession.getAccessToken().getJwtToken());
    }

    /**
     * This is used to clear the session tokens from local storage
     * @returns {void}
     */
    clearCachedTokens() {
        const keyPrefix = 'EagleCognitoProvicer';
        const accessToken = `${keyPrefix}.accessToken`;

        this.storage.removeItem(accessToken);
    }

    /**
     * This is used for the user to signOut of the application and clear the cached tokens.
     * @returns {void}
     */
    signOut() {
        this.signInUserSession = null;
        this.clearCachedTokens();
    }
}
