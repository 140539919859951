import {Card, Input} from 'antd';
import React, {useRef, useState} from 'react';
import {CardTitle} from '../../components/Card';
import {useGallery} from '../hooks/use-gallery';

interface GalleryScoreProps {
  galleryId: string;
  score?: number;
}

export const GalleryScore: React.FC<GalleryScoreProps> = ({
  galleryId,
  score,
}) => {
  const ref = useRef<any>();
  const [scoreEdit, setScoreEdit] = useState(score);
  const { update } = useGallery();

  const handleUpdate = () => {
    if (scoreEdit !== score) {
      update(galleryId, { score: scoreEdit });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleUpdate();
      ref!.current.blur();
    }

    if (e.key === 'Escape') {
      setScoreEdit(score);

      Promise.resolve().then(() => ref!.current.blur());
    }
  };

  const handleBlur = (e: any) => {
    handleUpdate();
  };

  return (
    <Card style={{ marginTop: '1.6rem' }}>
      <CardTitle>Score</CardTitle>
      <p style={{paddingTop: '1.6rem'}}>The higher the score, the higher the gallery will be in the galleries page.</p>

      <Input
        ref={ref}
        value={scoreEdit}
        onChange={({ target }) => setScoreEdit(parseInt(target.value))}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        type='number'
      />
    </Card>
  );
};
