import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { OrderListPage } from './pages/OrderListPage';
import { OrderDetailPage } from './pages/OrderDetailPage';
import { OrderCreatePage } from './pages/OrderCreatePage';

export const OrderModule = () => (
  <Routes>
    <Route path={`/detail/:id`} element={<OrderDetailPage />} />
    <Route path={`/create`} element={<OrderCreatePage />} />
    <Route path={'/'} element={<OrderListPage />} />
  </Routes>
);
