import { Query } from '@apollo/react-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, PageHeader, Table, Tag } from 'antd';
import { Artist } from '../../artist/artist';
import { productFetchAll } from '../product.queries';

const statusToColor = (status: string): string => {
  switch (status) {
    case 'unavailable':
      return 'magenta';
    case 'available':
      return 'green';
    case 'sold':
      return 'blue';
    default:
      return 'magenta';
  }
};

const columns = [{
  title: 'Name',
  dataIndex: 'name',
  key: 'name'
}, {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  render: (text: string) => <Tag color={statusToColor(text)}>{text}</Tag>
}, {
  title: 'Action',
  key: 'action',
  render: (text: any, record: Artist) => (
    <span>
      <Link to={`/inventory/detail/${record._id}`}>
        <a>Detail</a>
      </Link>
    </span>
  ),
}];

const rowSelection = {
  onChange: (selectedRowKeys: any, selectedRows: any) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record: any) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
};

export const ProductListPage = () => {
  return (
    <Layout.Content>
      <PageHeader title="Inventory"/>

      <Query query={productFetchAll}>
        {({loading, error, data}: any) => {
          if(loading) return null;
          if(error) return error;

          const { products } = data;

          return (
            <Table
              columns={columns}
              dataSource={products}
              rowSelection={rowSelection}
            />
          )
        }}
      </Query>
    </Layout.Content>
  );
};
