import React, { useState } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UploadOutlined } from '@ant-design/icons';

import { api } from '../../api/api';
import { Card } from '../../components/cards/Card';
import { ImageGridItem } from '../../components/image-grid/image-grid-item';
import { Artwork, ArtworkImage } from '../models/artwork';
import { ArtworkAssetUploadModal } from './ArtworkAssetUploadModal';

interface ArtworkImagesProps {
  artworkId: string;
  images: ArtworkImage[];
  onAdd: (image: any) => void;
  onDelete: (artworkId: string) => void;
  onMove: (artwork: Artwork) => void;
}

export const ArtworkImages: React.FunctionComponent<ArtworkImagesProps> = ({
  artworkId,
  images,
  onDelete,
  onAdd,
  onMove,
}) => {
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);

  const removeImage = (imageId: string) => {
    api
      .delete(`/artworks/${artworkId}/images/${imageId}`)
      .then(() => {
        message.success('Image removed!');
        onDelete(imageId);
      })
      .catch(() => {
        message.error('Could not remove image!');
      });
  };

  const handleDrop = (imageId: string, position: number) => {
    api
      .patch(`/artworks/${artworkId}/images/${imageId}`, {
        position,
      })
      .then(({ data }) => {
        onMove(data);
      })
      .catch(() => {
        message.error('Could not move image!');
      });
  };

  const handleSuccessAssetAdd = (image: any) => {
    onAdd(image);
    closeModal();
  };

  return (
    <Card>
      <DndProvider backend={HTML5Backend}>
        <ImageGrid>
          {images.map((image, index) => (
            <ImageGridItem
              key={image._id}
              index={index}
              image={image}
              onDelete={removeImage}
              onDrop={handleDrop}
            />
          ))}

          <AddImageElement onClick={openModal}>
            <Content>
              <UploadOutlined />
            </Content>
          </AddImageElement>
        </ImageGrid>
      </DndProvider>

      <ArtworkAssetUploadModal
        visible={modal}
        onCancel={closeModal}
        onOk={handleSuccessAssetAdd}
        artworkId={artworkId}
      />
    </Card>
  );
};

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.8rem;
  padding: 1.6rem;
`;

const AddImageElement = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 0.6rem;
  overflow: hidden;
  border: 2px dashed #d7d7d7;
  background-color: rgba(215, 215, 215, 0.2);
  transition: all 0.2s;

  &:hover {
    cursor: pointer;
    background-color: rgba(215, 215, 215, 0.35);
  }
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
