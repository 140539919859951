import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import { useAppDispatch } from '../../../app/hooks';
import { listArtwork, selectAllArtworks } from '../store/artwork.slice';
import { useSelector } from 'react-redux';
import { Artwork } from '../models/artwork';

type ArtworkSearchModalProps = {
  onSubmit: (artwork: Artwork) => void;
  onCancel: () => void;
  visible: boolean;
};

export const ArtworkSearchModal: React.FC<ArtworkSearchModalProps> = ({
  onSubmit,
  onCancel,
  visible,
}) => {
  const dispatch = useAppDispatch();
  const artworks = useSelector(selectAllArtworks);
  const [artworkId, setArtworkId] = useState();

  useEffect(() => {
    dispatch(listArtwork());
  }, [dispatch]);

  const handleSubmit = () => {
    onSubmit(artworks.find(artwork => artwork._id === artworkId)!);
  }

  return (
    <Modal
      visible={visible}
      title={"Search artwork"}
      onCancel={onCancel}
      onOk={handleSubmit}
    >
      <Select
        showSearch
        optionFilterProp="children"
        value={artworkId}
        onChange={setArtworkId}
        style={{ width: "100%" }}
      >
        {artworks.map(artwork => (
          <Select.Option key={artwork._id} value={artwork._id}>{artwork.name}</Select.Option>
        ))}
      </Select>
    </Modal>
  );
};
