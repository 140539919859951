import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Empty, Layout, Table, Tag, PageHeader, Card} from 'antd';
import { PageTitle } from '../../components/layouts/page/PageTitle';


export const HeroEditPage = (props: any) => {

    const data = {
        title: {
            fr: "French"
        }
    }
      
    return (
      <Layout.Content>

        <PageHeader title={'Landing page'} />

        <Card>
          <p>WORK IN PROGRESS</p>
          <p>This section will allow the user to edit the Hero article on the landing page</p>
        </Card>

      </Layout.Content>
    );
  };