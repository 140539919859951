import EditorJS from '@editorjs/editorjs';
import { Button, Card, Col, Layout, Row, Select, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { api } from '../../api/api';
import { CoverPicture } from '../../artist/components/CoverPicture';
import { CardTitle } from '../../components/cards/Card';
import { FormGroup, TextInput } from '../../components/form/inputs/TextInput';
import { PageFooter } from '../../components/layouts/page/PageFooter';
import { PageTitle } from '../../components/layouts/page/PageTitle';
import { Article } from '../../models/article';
import { Asset } from '../../models/asset';
import { Author } from '../../models/author';
import { Back } from '../../order/pages/OrderDetailPage';
import { ArticleAuthorCard } from './ArticleAuthorCard';
import { ArticleVariantsCard } from './ArticleVariantsCard';
import { ContentEditor } from './ContentEditor';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface ArticleDetailProps {
  article: Article;
}

export const ArticleDetail: React.FC<ArticleDetailProps> = ({ article }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState(article.title);
  const [author, setAuthor] = useState<Author>(article.author);
  const [language, setLanguage] = useState<string>(article.language);
  const [cover, setCover] = useState<Asset | undefined>(article.cover);
  const [summary, setSummary] = useState<string>(article.summary);
  const [meta, setMeta] = useState(article.meta);
  const [featured, setFeatured] = useState(article.featured);
  const [tags, setTags] = useState<string[]>(article.tags || []);
  const [editor, setEditor] = useState<EditorJS | null>(null);
  const editorRef = useRef(null);

  useEffect(() => {
    setTitle(article.title);
    setAuthor(article.author);
    setLanguage(article.language);
    setCover(article.cover);
    setSummary(article.summary);
    setMeta(article.meta);
    setTags(article.tags);
    setFeatured(article.featured);
    editor?.render(article.content);
  }, [article]);

  const handleAuthorChange = (author: Author) => {
    setAuthor(author);
  };

  const handleCoverUpdate = (asset: any) => {
    setCover(asset);
  };

  const handleLanguageChange = (value: string) => {
    setLanguage(value);
  };

  const handleTagChange = (value: string[]) => {
    setTags(value);
  };

  const handleFeaturedChange = (value: boolean) => {
    setFeatured(value);
  };

  const handleSubmit = async () => {
    if (!editor || !author) {
      return;
    }

    const { time, version, ...content } = await editor.save();

    api
      .patch(`/articles/${article._id}`, {
        title,
        summary,
        content,
        language,
        authorId: author._id,
        coverId: cover?._id || undefined,
        featured,
        meta,
        tags,
      })
      .then(({ data }) => {
        console.log(data);
      });
  };

  return (
    <Layout.Content>
      <Back>
        <ArrowLeftOutlined
          onClick={() => navigate('/magazine/articles')}
          style={{ marginRight: '.8rem' }}
        />
        Articles
      </Back>

      <PageTitle title={article.title} />

      <CoverPicture src={cover?.src || ''} onSuccess={handleCoverUpdate} />

      <Row gutter={16}>
        <Col lg={16}>
          <Card style={{ marginBottom: '1.6rem' }}>
            <TextInput
              label="Title"
              value={title}
              onChange={({ target }) => setTitle(target.value)}
            />

            <FormGroup>
              <Label>Summary</Label>
              <TextArea value={summary} onChange={({ target }) => setSummary(target.value)} />
            </FormGroup>

            <Label>Content</Label>

            <ContentEditor
              ref={editorRef}
              editor={editor}
              onLoad={setEditor}
              defaultContent={article.content}
            />
          </Card>

          <Card>
            <CardTitle>Meta</CardTitle>
            <p style={{ paddingTop: '1.6rem' }}>Override meta to optimize SEO.</p>

            <TextInput
              label="Title"
              value={meta.title}
              onChange={({ target }) => setMeta({ ...meta, title: target.value })}
            />

            <FormGroup>
              <Label>Description</Label>
              <TextArea
                value={meta.description}
                onChange={({ target }) => setMeta({ ...meta, description: target.value })}
                rows={5}
              />
            </FormGroup>
          </Card>
        </Col>

        <Col lg={8}>
          <ArticleAuthorCard defaultValue={author._id} onChange={handleAuthorChange} />

          <Card style={{ marginBottom: '1.6rem' }}>
            <CardTitle>Language</CardTitle>
            <p style={{ paddingTop: '1.6rem' }}>
              Language of this article. It wil be used when creating translated variants.
            </p>

            <Select style={{ width: '100%' }} onChange={handleLanguageChange} value={language}>
              <Select.Option value={'en'}>English</Select.Option>
              <Select.Option value={'fr'}>French</Select.Option>
            </Select>
          </Card>

          <ArticleVariantsCard articleId={article._id} variants={article.variants} />

          <Card style={{ marginBottom: '1.6rem' }}>
            <CardTitle>Tags</CardTitle>
            <p style={{ paddingTop: '1.6rem' }}></p>

            <Select style={{ width: '100%' }} mode="tags" onChange={handleTagChange} value={tags}>
              {tags.map((tag) => (
                <Select.Option key={tag} value={tag}>
                  {tag}
                </Select.Option>
              ))}
            </Select>
          </Card>

          <Card style={{ marginBottom: '1.6rem' }}>
            <CardTitle>Featured</CardTitle>
            <p style={{ paddingTop: '1.6rem' }}>
              When featured, an article will be present on the home page.
            </p>

            <ToggleContainer>
              Featured
              <label>
                <Switch onClick={handleFeaturedChange} title="featured" checked={featured} />
              </label>
            </ToggleContainer>
          </Card>
        </Col>
      </Row>

      <PageFooter>
        <Action>
          <Button title={'Save'} type={'primary'} onClick={handleSubmit}>
            Save
          </Button>
        </Action>
      </PageFooter>
    </Layout.Content>
  );
};

const ToggleContainer = styled.div`
  width: 100%;
  padding: 1.6rem;
  color: #1890ff;
  display: flex;
  flex-direction: row;
  font-family: 'Quicksand', 'sans-serif';
  font-weight: 700;
  font-size: 1.6rem;
  justify-content: space-between;
  background-color: rgba(24, 144, 255, 0.05);
  border-radius: 0.4rem;
`;

const Label = styled.label`
  display: inline-block;
  font-weight: 500;
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
  color: #5b626b;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
