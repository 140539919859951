import { Button, Card } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { api } from '../../api/api';
import { CardTitle } from '../../components/cards/Card';
import { useModal } from '../../components/hooks/useModal';
import { Article, ArticleVariant } from '../../models/article';
import { ArticleSearchDialog } from './ArticleSearchDialog';

const languages: any = {
  en: 'English',
  fr: 'French',
};

interface ArticleVariantsCardProps {
  articleId: string;
  variants: ArticleVariant[];
}

export const ArticleVariantsCard: React.FC<ArticleVariantsCardProps> = ({
  articleId,
  variants,
}) => {
  const navigate = useNavigate();
  const { visible, openModal, closeModal } = useModal();

  const handleSubmit = async (article: Article) => {
    await api
      .post(`/articles/${articleId}/variants`, {
        articleId: article._id,
      })
      .then(() => {
        closeModal();
      });
  };

  return (
    <>
      <Card style={{ marginBottom: '1.6rem' }}>
        <CardTitle>Translations</CardTitle>
        <Button onClick={openModal}>Add</Button>

        <p style={{ paddingTop: '1.6rem' }}>Translated versions of this article.</p>

        {variants.map((variant) => (
          <ToggleContainer>
            {languages[variant.language]}

            <Button
              style={{ fontFamily: 'Quicksand' }}
              size={'small'}
              type={'primary'}
              onClick={() => navigate(`/magazine/articles/${variant._id}`)}>
              Open
            </Button>
          </ToggleContainer>
        ))}
      </Card>

      {visible && <ArticleSearchDialog onCancel={closeModal} onSubmit={handleSubmit} />}
    </>
  );
};

const ToggleContainer = styled.div`
  width: 100%;
  padding: 1.6rem;
  color: #1890ff;
  display: flex;
  flex-direction: row;
  font-family: 'Quicksand', 'sans-serif';
  font-weight: 700;
  font-size: 1.6rem;
  justify-content: space-between;
  background-color: rgba(24, 144, 255, 0.05);
  border-radius: 0.4rem;

  &:not(:first-child) {
    margin-top: 0.8rem;
  }
`;
