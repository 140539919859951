import React, {ChangeEvent, useState} from 'react';
import {Button, Card, Form, Input, message} from 'antd';
import { useMutation } from 'react-apollo';
import {ArtistUpdate} from '../__generated__/ArtistUpdate';
import {artistUpdate} from '../artist.mutations';
import {ArtistFetch, ArtistFetch_artist} from '../__generated__/ArtistFetch';
import {artistFetch} from '../artist.queries';

const INSTAGRAM = 'instagram';
const FACEBOOK = 'facebook';
const TWITTER = 'twitter';

interface Props {
  artist: ArtistFetch_artist;
}

export const ArtistSocialLinks: React.FunctionComponent<Props> = ({ artist }) => {
  const [mutate] = useMutation<ArtistUpdate>(artistUpdate);
  const [loading, setLoading] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [instagram, setInstagram] = useState(artist.instagram || '');
  const [facebook, setFacebook] = useState(artist.facebook || '');
  const [twitter, setTwitter] = useState(artist.twitter || '');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setHasChange(true);

    switch (name) {
      case INSTAGRAM:
        return setInstagram(value);
      case FACEBOOK:
        return setFacebook(value);
      case TWITTER:
        return setTwitter(value);
    }
  };

  const handleSave = () => {
    setLoading(true);

    mutate({
      variables: {
        input: {
          artistId: artist._id,
          instagram,
          facebook,
          twitter,
        }
      },
      update: (cache) => {
        const data = cache.readQuery<ArtistFetch>({
          query: artistFetch,
          variables: {
            id: artist._id
          }
        });

        if (!data) {
          return;
        }

        const { artist: cachedArtist } = data;

        cache.writeQuery({
          query: artistFetch,
          variables: {
            id: artist._id
          },
          data: {
            artist: {
              ...cachedArtist,
              instagram,
              twitter,
              facebook
            }
          }
        });
      }
    }).then(() => {
      message.success('Artist successfully updated!')
    }).catch(() => {
      message.success('Error while updating artist!')
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Card title='Links' style={{ marginTop: '1.6rem' }} extra={hasChange && <Button onClick={handleSave} loading={loading}>save</Button>}>
      <Form.Item label="Instagram">
        <Input name={INSTAGRAM} value={instagram} onChange={onChange}/>
      </Form.Item>

      <Form.Item label="Facebook">
        <Input name={FACEBOOK} value={facebook} onChange={onChange}/>
      </Form.Item>

      <Form.Item label="Twitter">
        <Input name={TWITTER} value={twitter} onChange={onChange}/>
      </Form.Item>
    </Card>
  )
};