import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Descriptions, Empty, Layout, message, PageHeader, Row, Tag } from 'antd';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { api } from '../../api/api';
import { ArtworkImages } from '../components/ArtworkImages';
import { useModal } from '../../components/hooks/useModal';
import { ArtworkDescription } from '../components/ArtworkDescription';
import { ArtworkFeaturedSwitch } from '../components/ArtworkFeaturedSwitch';
import { ArtworkScore } from '../components/ArtworkScore';
import { ArtworkUpdateForm } from '../components/ArtworkUpdateForm';
import { ArtworkPriceList } from '../components/ArtworkPriceList';
import { useArtwork } from '../hooks/use-artwork';
import { Artwork } from '../models/artwork';
import { ArtworkShipping } from '../components/ArtworkShipping';
import useSWR from 'swr';

const fetcher = (url: string) => api.get(url).then((res) => res.data);

export const ArtworkDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { visible, openModal, closeModal } = useModal();
  const { accept, reject } = useArtwork();
  const { data: artwork, mutate, isLoading } = useSWR<Artwork>(`/artworks/${id}`, fetcher);

  const handleSuccessUpdate = async (data: any) => {
    await mutate({ ...artwork, ...data });
    closeModal();
    message.success('Artwork updated successfully!');
  };

  const handleAccept = () => {
    accept(id)
      .then(() => {
        message.success('Artist has been accepted.');
      })
      .catch(() => {
        message.error('We encountered an error trying to accept this artist.');
      });
  };

  const handleReject = () => {
    reject(id)
      .then(() => {
        message.success('Artist has been rejected.');
      })
      .catch(() => {
        message.error('We encountered an error trying to reject this artist.');
      });
  };

  if (!artwork) {
    return <Empty />;
  }

  return (
    <Layout>
      <Layout.Content>
        <Back>
          <ArrowLeftOutlined onClick={() => navigate(-1)} style={{ marginRight: '.8rem' }} />{' '}
          Artworks
        </Back>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <PageTitle>
            <strong>{artwork.name}</strong>
          </PageTitle>
        </div>

        <PageHeader
          title={''}
          extra={[
            artwork.status === 'pending' ? (
              <Button type={'primary'} onClick={handleAccept}>
                accept
              </Button>
            ) : null,
            artwork.status === 'pending' ? (
              <Button type={'default'} danger onClick={handleReject}>
                reject
              </Button>
            ) : null,
            <ArtworkFeaturedSwitch artworkId={artwork._id} featured={artwork.featured} />,
            <Button onClick={openModal}>update</Button>,
          ]}
        />

        <Descriptions column={4}>
          <Descriptions.Item label="Category">
            <Tag>{artwork.category}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Style">
            <Tag>{artwork.style}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Subject">
            <Tag>{artwork.subject}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Technique">
            {artwork.technique.map((el: string) => (
              <Tag>{el}</Tag>
            ))}
          </Descriptions.Item>

          <Descriptions.Item label="Orientation">
            {<Tag>{artwork.orientation}</Tag> || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Year">{artwork.year || '-'}</Descriptions.Item>

          <Descriptions.Item label="Dimension">
            {artwork.dimension.height}x{artwork.dimension.width}x{artwork.dimension.depth}cm
          </Descriptions.Item>
          <Descriptions.Item label="Weight">{artwork.weight}g</Descriptions.Item>
          <Descriptions.Item label="Framed">
            {artwork.framed ? <Tag>Yes</Tag> : <Tag>No</Tag>}
          </Descriptions.Item>
        </Descriptions>

        <Row gutter={16}>
          <Col lg={16}>
            <ArtworkDescription
              artworkId={artwork._id}
              description={artwork.description}
              translations={artwork.translation || []}
            />

            <ArtworkImages
              artworkId={artwork._id}
              images={artwork.assets || artwork.images || []}
              onAdd={(image: any) =>
                mutate({
                  ...artwork,
                  assets: artwork.assets?.concat(image),
                  images: artwork.images?.concat(image),
                })
              }
              onDelete={(imageId: string) =>
                mutate({
                  ...artwork,
                  assets: artwork.assets?.filter((el) => el._id !== imageId),
                  images: artwork.images?.filter((el) => el._id !== imageId),
                })
              }
              onMove={(artwork) =>
                mutate({
                  ...artwork,
                  assets: artwork.assets,
                  images: artwork.images,
                })
            }
            />

            <ArtworkPriceList
              artworkId={artwork._id}
              price={artwork.price}
              priceList={artwork.priceList}
            />

            <ArtworkShipping artworkId={artwork._id} artworkPrice={artwork.price} />

            <ArtworkUpdateForm
              artwork={artwork}
              visible={visible}
              onOk={handleSuccessUpdate}
              onCancel={closeModal}
            />
          </Col>

          <Col lg={8}>
            <ArtworkScore artworkId={artwork._id} score={artwork.score} />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

// TODO Create Custom page header for detail page
const Back = styled.div`
  margin-top: 3.2rem;
  font-family: Quicksand, 'sans-serif';
  font-weight: 700;
  color: #a7cde3;
  //color: #95a2b3;
  font-size: 1.4rem;
`;

const PageTitle = styled.h1`
  font-size: 2.4rem;
  font-family: Quicksand, 'sans-serif';
  color: #8198aa;
  font-weight: 700;

  strong {
    color: #3e475e;
  }
`;
