import gql from 'graphql-tag';

export const sellerCreate = gql`
  mutation SellerCreate($input: SellerCreateInput!) {
    sellerCreate(input: $input) {
      _id

      artist {
        _id
        nickName
      }

      gallery {
        _id
        name
        logo
        city
        country
      }
    }
  }
`;
