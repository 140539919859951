import gql from 'graphql-tag';

export const storyCreate = gql`  
  mutation StoryCreate ( $input: StoryCreateInput!) {
      storyCreate(input: $input) {
          _id
          ownerId
          src
          author
          caption
          type
          content
          date
          order
          title
      }
  }
`;

export const storyUpdate = gql`
    mutation StoryUpdate ( $input: StoryUpdateInput!) {
        storyUpdate(input: $input) {
            _id
            ownerId
            src
            author
            caption
            type
            content
            date
            order
            title
        }
    }
`;

export const storyCoverUpdate = gql`
    mutation StoryCoverUpdate ($input: StoryCoverUpdateInput!){
        storyCoverUpdate (
            input: $input
        ) {
            url
        }
    }
`;

export const storyDelete = gql`
    mutation StoryDelete ($input: StoryDeleteInput!){
        storyDelete (
            input: $input
        ) {
            _id
        }
    }
`;

export const storyOrderUpdate = gql`
    mutation StoryOrderUpdate ($input: StoryOrderUpdateInput!){
        storyOrderUpdate (
            input: $input
        ) {
            _id
            ownerId
            src
            author
            caption
            type
            content
            date
            order
            title
        }
    }
`;
