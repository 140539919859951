import { Card, InputNumber, Modal, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { ExchangeRatesFetch } from '../__generated__/exchangeRatesFetch';
import { exchangeRatesFetch } from '../exchange-rate.queries';
import { exchangeRatesUpdate } from '../exchange-rate.mutation';
import {
  ExchangeRatesUpdate,
  ExchangeRatesUpdateVariables,
} from '../__generated__/ExchangeRatesUpdate';

export const ExchangeRatesCard = () => {
  const { data, loading, error } = useQuery<ExchangeRatesFetch>(exchangeRatesFetch);
  const [rateMutation] = useMutation<ExchangeRatesUpdate, ExchangeRatesUpdateVariables>(
    exchangeRatesUpdate,
  );
  const [currentCurrency, setCurrentCurrency] = useState<any>(null);
  const [currentValue, setCurrentValue] = useState<number | null | undefined>(null);

  const openModal = (el: any) => {
    setCurrentCurrency(el);
    setCurrentValue(el.value);
  };

  const closeModal = () => {
    setCurrentCurrency(null);
    setCurrentValue(null);
  };

  const handleSubmit = () => {
    if (!currentCurrency || !currentValue) {
      return;
    }

    rateMutation({
      variables: {
        input: {
          rateId: currentCurrency._id,
          value: currentValue,
        },
      },
    }).then(() => {
      setCurrentCurrency(null);
    });
  };

  const columns: ColumnProps<any>[] = [
    {
      title: 'Source Currency',
      dataIndex: 'sourceCurrency',
      key: 'sourceCurrency',
    },
    {
      title: 'Currency',
      dataIndex: 'targetCurrency',
      key: 'targetCurrency',
    },
    {
      title: 'Rate',
      dataIndex: 'value',
      key: 'rate',
    },
    {
      render: (el) => <a onClick={() => openModal(el)}>update</a>,
    },
  ];

  if (!loading && (error || !data)) {
    return <div />;
  }

  return (
    <Card title={'Exchange rates'}>
      <Table loading={loading} columns={columns} dataSource={data?.exchangeRates || []} />

      {currentCurrency && (
        <Modal
          visible={currentCurrency}
          title={`Update ${currentCurrency.sourceCurrency} to ${currentCurrency.targetCurrency}`}
          onCancel={closeModal}
          onOk={handleSubmit}>
          <InputNumber value={currentValue!} onChange={(value) => setCurrentValue(value)} />
        </Modal>
      )}
    </Card>
  );
};
