import React, { FormEvent, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import { useNavigate } from 'react-router-dom';

import logoImg from '../../assets/images/logo-lg.png';
import { login } from '../auth.mutations';
import { Card } from '../../components/cards/Card';
import { CheckBox } from '../../components/form/inputs/CheckBox';
import { InputType } from '../../components/form/inputs/input-type';
import { TextInput } from '../../components/form/inputs/TextInput';
import { Button } from '../../components/ui/Button';
import { CognitoUser } from '../../lib/cognito/CognitoUser';

export const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [mutate] = useMutation(login);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    await mutate({
      variables: {
        input: {
          email,
          password,
        },
      },
    })
      .then(({ data }) => {
        const cognitoUser = new CognitoUser();

        cognitoUser.authenticateUser({ accessToken: data.login.token });
        navigate('/');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container>
      <Card>
        <Header>
          <Title>Welcome Back!</Title>
          <p
            style={{
              marginBottom: '2.5rem',
              fontSize: '1.4rem',
              lineHeight: '1.7rem',
              color: 'rgba(255,255,255,.5)',
            }}>
            Sign in to continue to Urbaneez
          </p>
          <Logo>
            <img style={{ height: '4.5rem' }} src={logoImg} alt="" />
          </Logo>
        </Header>

        <Content>
          <Form onSubmit={handleSubmit}>
            <TextInput
              label="Email"
              placeholder={'Enter email'}
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />

            <TextInput
              label="Password"
              type={InputType.PASSWORD}
              placeholder={'Enter password'}
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />

            <FormAction>
              <CheckBox label="Remember me" />

              <Button title="Log In" />
            </FormAction>
          </Form>

          <div style={{ marginTop: '2rem' }}>
            <A>
              <i className="fas fa-lock" /> Forgot your password?
            </A>
          </div>
        </Content>
      </Card>

      <Copyright>
        <p>
          © 2023 Urbaneez. Crafted with <Love>&#x2764;</Love> by The Exciting Company
        </p>
      </Copyright>
    </Container>
  );
};

const Container = styled.div`
  max-width: 46rem;
  position: relative;
  margin: 5.5% auto;
`;

const Header = styled.div`
  position: relative;
  background-color: #1a1c1e;
  border-radius: 0.5rem 0.5rem 0 0;
  text-align: center;
  padding: 2.5rem;
  color: white;
`;

const Title = styled.h4`
  color: white;
  font-size: 2rem;
  margin: 1rem 0 0.5rem;
`;

const Logo = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  bottom: -4rem;
  margin: 0 auto;
  width: 7.4rem;
  height: 7.4rem;
  line-height: 7.4rem;
  background-color: white;
  border-radius: 50%;
  border-bottom: 2px solid #1a1c1e;
`;

const Content = styled.div`
  padding: 4rem;
`;

const Form = styled.form`
  margin-top: 3rem;
`;

const FormAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
`;

const A = styled.a`
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  color: #6c757d;

  &:hover {
    color: #626ed4;
  }
`;

const Copyright = styled.div`
  margin-top: 4rem;
  text-align: center;

  p {
    font-size: 1.4rem;
  }
`;

const Love = styled.span`
  color: #ec4561;
`;
