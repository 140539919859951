import gql from 'graphql-tag';

export const storyFetchAll = gql`  
  query StoryFetchAll ( $filter: StoryFilterInput!) {
      stories(filter: $filter) {
          _id
          ownerId
          type
          author
          caption
          order
          content
          date
          src
      }
  }
`;
