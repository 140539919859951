import React, {useEffect, useState} from 'react';
import {Select} from 'antd';
import cityList from '../../assets/data/cities.json';

interface Props {
  country?: string | null,
}

export const CitySelect: React.FunctionComponent<Props> = ({country, ...props}) => {
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState<string[]>([]);

  const capitalize = (text: string) => text.split(' ').map(word => `${word[0].toUpperCase()}${word.substring(1)}`).join(' ');

  useEffect(() => {
    if (!country) {
      return;
    }

    setLoading(true);
    // @ts-ignore
    setCities(cityList[capitalize(country)]);
    setLoading(false);
  }, [country]);

  return (
    <Select disabled={!country || !cities} loading={loading} showSearch={true} {...props}>
      {cities && cities.map(country => (
        <Select.Option key={country} value={country.toLowerCase()}>{country}</Select.Option>
      ))}
    </Select>
  );
};