import { Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 2rem 0;
`;

interface PageTitleProps {
  title?: string;
  actions?: any;
}

export const PageTitle: React.FunctionComponent<PageTitleProps> = (props) => (
  <Container>
    <Row justify={'space-between'}>
      <h1>{props.title}</h1>

      {props.actions}
    </Row>
  </Container>
);
