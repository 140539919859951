import {Avatar, message} from 'antd';
import React, { useRef } from 'react';
import { useMutation } from 'react-apollo';
import styled from 'styled-components';
import axios from 'axios';
import {galleryLogoUpdate, galleryProfilePictureUpdate} from '../gallery.mutations';
import {GalleryLogoUpdate, GalleryLogoUpdateVariables} from '../__generated__/GalleryLogoUpdate';

const FileInput = styled.input`
display: none;
`;

interface Props {
  galleryId: string;
  src: string;
}

export const GalleryLogo: React.FunctionComponent<Props> = props => {
  const [mutate] = useMutation<GalleryLogoUpdate, GalleryLogoUpdateVariables>(galleryLogoUpdate);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (!(fileInputRef && fileInputRef.current)) {
      return;
    }

    fileInputRef.current.click();
  };

  const onFileSelected = async () => {
    if (!(fileInputRef && fileInputRef.current && fileInputRef.current.files)) {
      return;
    }

    const { galleryId } = props;
    const file = fileInputRef.current.files[0];

    const { data } = await mutate({
      variables: {
        input: {
          galleryId,
          type: file.type
        }
      }
    });

    if (!data) {
      return null;
    }

    const { url } = data.galleryLogoUpdate;

    // now do a PUT request to the pre-signed URL
    axios
      .put(url, file)
      .then(() =>
        message.success('Logo updated')
      )
      .catch(() =>
        message.error('Could not update logo')
      );
  };

  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      <Avatar size={128} icon="user" src={props.src} shape="square"/>

      <FileInput
        ref={fileInputRef}
        type="file"
        onChange={onFileSelected}
      />
    </div>
  );
};
