import React from 'react';
import { Card, Layout, PageHeader } from 'antd';
import { api } from '../../api/api';
import { ArtistCreateForm } from '../components/ArtistCreateForm';

export const ArtistCreatePage = (props: any) => {
  const handleSubmit = async (values: any) => {
    const { dateOfBirth, prefix, phone, identifier, identifierType, ...data } = values;

    return api
      .post('/artists', {
        ...data,
        phone: {
          prefix,
          number: phone,
        },
        identifier: {
          type: identifierType,
          value: identifier,
        },
        dateOfBirth: {
          month: dateOfBirth.month() + 1,
          day: dateOfBirth.date(),
          year: dateOfBirth.year(),
        },
      })
      .then(({ data: artist }) => {
        props.history.push(`/artists/detail/${artist._id}`);
      });
  };

  return (
    <Layout.Content>
      <PageHeader title={'Create artist'} />

      <Card style={{ margin: '0 1.6rem' }}>
        <ArtistCreateForm handleSubmit={handleSubmit} />
      </Card>
    </Layout.Content>
  );
};
