import {Form, Input, Modal} from 'antd';
import React, {useState} from 'react';
import {api} from '../../api/api';
import {Author} from '../../models/author';

interface CreateAuthorDialogProps {
  visible: boolean;
  onClose: () => void;
  onFinish: (author: Author) => void;
}

export const CreateAuthorDialog: React.FC<CreateAuthorDialogProps> = ({
  visible,
  onClose,
  onFinish,
}) => {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (!name || loading) {
      return;
    }

    setLoading(true);

    api
      .post('/authors', {
        displayName: name,
      })
      .then(({ data }) => {
        onFinish(data);
        onClose();
      });
  };

  return (
    <Modal
      title="Basic Modal"
      visible={visible}
      onCancel={onClose}
      confirmLoading={loading}
      onOk={onSubmit}
    >
      <Form.Item label={'Display name'}>
        <Input
          value={name}
          onChange={({target}) => setName(target.value)}
        />
      </Form.Item>
    </Modal>
  );
};
