import React from 'react';
import useSWR from 'swr';
import { api } from '../../api/api';
import { useNavigate } from 'react-router-dom';
import { Button, Layout } from 'antd';
import { PageTitle } from '../../components/layouts/page/PageTitle';
import { CollectionTable } from '../components/CollectionTable';


export const CollectionListPage = (props: any) => {

    const navigate = useNavigate();
    const getKey = () => {
      //TODO: add possible query, especially search by name
      return `/collections`;
    };
    const fetcher = (url: string) => {
        return api.get(url).then(({ data, headers }) => {
            let count;
            let cursor;
            try {
                cursor = parseInt(headers['content-range']!.split(' ')[1].split('/')[0], 10);
                count = parseInt(headers['content-range']!.split('/')[1], 10);
            } catch (e) {
                count = data.length;
            }
        
            return {
                count,
                cursor,
                collections: data,
            };
        });
    };

    const { isLoading, data } = useSWR(getKey, fetcher);


    return (
      <Layout.Content>
        <PageTitle
          title="Collections"
          actions={
            <Button onClick={() => navigate('/')} title={'new collection'} type={'primary'}>
              new collection
            </Button>
          }
        />

        <CollectionTable
          loading={isLoading}
          collections={data?.collections|| []}
        />
        
      </Layout.Content>
    );
};
    
      
    