import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../../api/api';

type CreateGalleryInput = {
  name: string;
}

export const createGallery = createAsyncThunk('gallery/create', async (
  input: CreateGalleryInput,
) => {
  const { data } = await api.post('/galleries', input);
  return data;
});

const gallerySlice = createSlice({
  name: 'gallery',
  initialState: {},
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createGallery.fulfilled, () => {

      });
  }
});

export const galleryReducer = gallerySlice.reducer;
