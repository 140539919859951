export const addLeadingChar = (str: string, char: string, size: number) => {
  const currentSize = str.length;

  if (currentSize >= size) {
    return str;
  }

  const leadingSize = size - currentSize;
  let leading = '';

  for (let i = 0; i < leadingSize; i++) {
    leading += char;
  }

  return `${leading}${str}`;
};
