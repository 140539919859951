import { Avatar } from 'antd';
import React, { useRef } from 'react';
import { useMutation } from 'react-apollo';
import styled from 'styled-components';
import axios from 'axios';
import {artistCoverPictureUpdate} from '../artist.mutations';
import {ArtistCoverPictureUpdate, ArtistCoverPictureUpdateVariables} from '../__generated__/ArtistCoverPictureUpdate';

const FileInput = styled.input`
display: none;
`;

interface Props {
  artistId: string;
  src: string;
}

export const ArtistCover: React.FunctionComponent<Props> = props => {
  const [mutate] = useMutation<ArtistCoverPictureUpdate, ArtistCoverPictureUpdateVariables>(artistCoverPictureUpdate);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (!(fileInputRef && fileInputRef.current)) {
      return;
    }

    fileInputRef.current.click();
  };

  const onFileSelected = async () => {
    if (!(fileInputRef && fileInputRef.current && fileInputRef.current.files)) {
      return;
    }

    const { artistId } = props;
    const file = fileInputRef.current.files[0];

    const { data } = await mutate({
      variables: {
        input: {
          artistId
        }
      }
    });

    if (!data) {
      return;
    }

    const { url } = data.artistCoverPictureUpdate;

    // now do a PUT request to the pre-signed URL
    axios.put(url, file).then(() => alert('success'));
  };

  return (
    <div onClick={onClick}>
      <Avatar size={64} icon="user" src={props.src} shape={'square'}/>

      <FileInput
        ref={fileInputRef}
        type="file"
        onChange={onFileSelected}
      />
    </div>
  );
};
