import EditorJS from '@editorjs/editorjs';
import ImageTool from '@editorjs/image';
import Quote from '@editorjs/quote';
import Header from '@editorjs/header';
import axios from 'axios';
import {encode} from 'blurhash';
import React, {forwardRef, useEffect} from 'react';
import styled from 'styled-components';
import {api} from '../../api/api';

const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (...args) => reject(args);
    img.src = src;
  });
};

const getImageData = (image: HTMLImageElement) => {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.drawImage(image, 0, 0);
  return context.getImageData(0, 0, image.width, image.height);
};

const encodeImage = ({data, width, height}: ImageData) => {
  return encode(data, width, height, 4, 4);
};

export const ContentEditor = forwardRef<HTMLDivElement, any>(({
  editor,
  onLoad,
  defaultContent,
}, ref) => {
  useEffect(() => {
    // Update the document title using the browser API
    if (ref && !editor) {
      onLoad(
        new EditorJS({
          tools: {
            header: Header,
            quote: {
              class: Quote,
            },
            image: {
              class: ImageTool,
              config: {
                uploader: {
                  async uploadByFile(file: File) {
                    const url = URL.createObjectURL(file);
                    const image = await loadImage(url);
                    const imageData = getImageData(image);
                    const blurHash = encodeImage(imageData);

                    return api
                      .post('/assets', {
                        name: file.name,
                        size: file.size,
                        fileType: file.type,
                        type: 'file',
                        blurHash,
                        meta: {
                          height: image.height,
                          width: image.width,
                        },
                      })
                      .then(async ({data}) => {
                        await axios.put(data.links.upload, file);
                        return data;
                      })
                      .then(asset => {
                        return {
                          success: 1,
                          file: {
                            url: asset.src,
                            blurHash: asset.blurHash,
                            width: asset.meta.width,
                            height: asset.meta.height,
                          },
                        };
                      });
                  },
                },
              },
            },
          },
          data: defaultContent,
        }));
    }
  }, [editor]);

  return (
    <Editor>
      <div ref={ref} id="editorjs"/>
    </Editor>
  );
});

const Editor = styled.div`
min-height: 24rem;
display: block;
font-size: 1.4rem;
width: 100%;
padding: .6rem 1.2rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: white;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: .5rem;
transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
outline: none;

&:focus {
  border-color: #626ed4;
  box-shadow: none;
}
`;
