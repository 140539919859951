import React, { FormEvent, useState } from 'react';
import { Checkbox, DatePicker, Form, Input, message, Modal, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useMutation } from 'react-apollo';

import { artistUpdate } from '../artist.mutations';
import { artistFetch } from '../artist.queries';
import moment from 'moment';
import { ArtistFetch_artist } from '../__generated__/ArtistFetch';
import { ArtistUpdate, ArtistUpdateVariables } from '../__generated__/ArtistUpdate';
import { CountrySelect } from '../../components/select/CountrySelect';

interface Props {
  visible: boolean;
  onClose: any;
  onOk: any;
  artist: ArtistFetch_artist;
}

export const ArtistUpdateForm: React.FC<Props> = (props) => {
  const { artist } = props;

  const [loading, setLoading] = useState(false);
  const [mutate] = useMutation<ArtistUpdate, ArtistUpdateVariables>(artistUpdate);
  const [country, setCountry] = useState(props.artist.country);

  const [form] = Form.useForm();

  const prefixSelector = (
    <Form.Item name="prefix" initialValue={artist.phone ? artist.phone.prefix : '41'}>
      <Select style={{ width: 70 }}>
        <Select.Option value="1">+1</Select.Option>
        <Select.Option value="31">+31</Select.Option>
        <Select.Option value="32">+32</Select.Option>
        <Select.Option value="33">+33</Select.Option>
        <Select.Option value="34">+34</Select.Option>
        <Select.Option value="39">+39</Select.Option>
        <Select.Option value="41">+41</Select.Option>
        <Select.Option value="43">+43</Select.Option>
        <Select.Option value="44">+44</Select.Option>
        <Select.Option value="49">+49</Select.Option>
      </Select>
    </Form.Item>
  );

  const identifierTypeSelector = (
    <Form.Item
      name="identifierType"
      initialValue={artist.identifier ? artist.identifier.type : 'ide'}>
      <Select style={{ width: 70 }}>
        <Select.Option value="ide">IDE</Select.Option>
        <Select.Option value="nif">NIF</Select.Option>
      </Select>
    </Form.Item>
  );

  const onOk = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    form
      .validateFields()
      .then(async (values: any) => {
        const { prefix, phone, dateOfBirth, identifier, identifierType, ...input } = values;

        try {
          await mutate({
            variables: {
              input: {
                artistId: props.artist._id,
                phone: {
                  prefix,
                  number: phone,
                },
                identifier: {
                  type: identifierType,
                  value: identifier,
                },
                dateOfBirth: {
                  month: dateOfBirth.month() + 1,
                  day: dateOfBirth.date(),
                  year: dateOfBirth.year(),
                },
                ...input,
              },
            },
            update: (cache, { data }) => {
              const options = {
                query: artistFetch,
                variables: {
                  id: props.artist._id,
                },
              };

              if (!data) {
                return;
              }

              cache.writeQuery({
                ...options,
                data: {
                  artist: data.artistUpdate,
                },
              });
            },
          });

          message.success('Artist updated');
          props.onOk();
        } catch (e) {
          console.log(e);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const createInitialDate = () => {
    const date = moment();

    if (!artist.dateOfBirth) {
      return date;
    }

    const { year, month, day } = artist.dateOfBirth;

    if (!year || !month || !day) {
      return date;
    }

    date.year(year);
    date.month(month - 1);
    date.date(day);

    return date;
  };

  const initialDateOfBirth = artist.dateOfBirth ? createInitialDate() : moment();

  return (
    <Modal
      title={`Update ${artist.nickName}`}
      visible={props.visible}
      onOk={onOk}
      onCancel={props.onClose}
      confirmLoading={loading}>
      <Form form={form}>
        <Form.Item
          label="First name"
          name="firstName"
          initialValue={props.artist.firstName}
          rules={[
            {
              required: true,
              message: 'Please input a artist name',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Last name"
          name={'lastName'}
          initialValue={props.artist.lastName}
          rules={[
            {
              required: true,
              message: 'Please input a artist name',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Gender"
          name="gender"
          initialValue={props.artist.gender}
          rules={[
            {
              required: true,
            },
          ]}>
          <Select>
            <Select.Option value="male">Man</Select.Option>
            <Select.Option value="female">Woman</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Nick name"
          name="nickName"
          initialValue={props.artist.nickName}
          rules={[
            {
              required: true,
              message: 'Please input nickname',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item label="Bio" name="bio" initialValue={props.artist.bio}>
          <TextArea />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          initialValue={props.artist.email}
          rules={[
            {
              required: true,
              message: 'Please input an email!',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
          initialValue={artist.phone ? artist.phone.number : ''}
          rules={[
            {
              required: true,
              message: 'Please input a phone number',
            },
          ]}>
          <Input addonBefore={prefixSelector} />
        </Form.Item>

        <Form.Item
          label="Date of Birth"
          name="dateOfBirth"
          initialValue={initialDateOfBirth}
          rules={[
            {
              required: true,
              message: 'Please input a date of birth',
            },
          ]}>
          <DatePicker />
        </Form.Item>

        <Form.Item label="Country" name="country" initialValue={props.artist.country}>
          <CountrySelect onChange={setCountry} />
        </Form.Item>

        <Form.Item label="City" name="city" initialValue={props.artist.city}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Unique identifier (NIF/IDE)"
          name="identifier"
          initialValue={artist.identifier ? artist.identifier.value : ''}>
          <Input addonBefore={identifierTypeSelector} />
        </Form.Item>

        <Form.Item
          label="Unique identifier (NIF/IDE)"
          name="acceptCommission"
          initialValue={!!artist.acceptCommission}
          valuePropName="checked">
          <Checkbox>Accept commission</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
